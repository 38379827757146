type SocialIconProps = {
  onClick?: () => void;
};

export const WhatsappIcon = ({ onClick = () => {} }: SocialIconProps) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
    onClick={onClick}
  >
    <g clipPath="url(#clip0_1743_5569)">
      <path
        d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20C0.5 31.0457 9.45431 40 20.5 40Z"
        fill="#27D045"
      />
      <path
        d="M20.6664 29.5013C26.0052 29.5013 30.3331 25.1734 30.3331 19.8346C30.3331 14.4959 26.0052 10.168 20.6664 10.168C15.3277 10.168 10.9998 14.4959 10.9998 19.8346C10.9998 25.1734 15.3277 29.5013 20.6664 29.5013Z"
        stroke="white"
        strokeWidth="1.66667"
      />
      <path
        d="M11.8331 25.3328L10.2998 30.7661L15.9331 29.1995L16.2331 27.4661L12.5664 28.6328L13.5331 25.1328L11.8331 25.3328Z"
        fill="white"
      />
      <path
        d="M12.4998 28.6695C12.4998 28.6362 13.6664 24.7695 13.6664 24.7695L16.4664 27.4029L12.4998 28.6695Z"
        fill="#27D045"
      />
      <path
        d="M16.4894 15.0463C16.149 15.2824 15.8589 15.5837 15.6357 15.9328C15.4126 16.2818 15.2609 16.6716 15.1893 17.0796C15.0227 18.3796 15.8227 19.713 15.8227 19.713C16.6342 20.9203 17.5976 22.0182 18.6893 22.9796C19.6443 23.7353 20.7286 24.3113 21.8893 24.6796C22.5664 24.9499 23.3071 25.0194 24.0227 24.8796C24.6569 24.7055 25.218 24.3314 25.6227 23.8129C25.7175 23.5771 25.7846 23.331 25.8227 23.0796C25.8608 22.859 25.8608 22.6335 25.8227 22.4129C25.7894 22.1796 23.3894 21.0796 23.156 21.1463C22.9227 21.2129 22.7227 21.6463 22.456 22.0129C22.1893 22.3796 22.0894 22.5463 21.8227 22.5463C21.556 22.5463 20.3893 22.0796 19.2893 21.0796C18.661 20.5348 18.17 19.8496 17.856 19.0796C17.856 19.0796 18.056 18.8463 18.3227 18.4796C18.5302 18.2523 18.6789 17.9776 18.756 17.6796C18.756 17.5129 17.8227 15.1463 17.5893 15.0463C17.2245 14.995 16.8542 14.995 16.4894 15.0463Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1743_5569">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
