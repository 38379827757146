import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { ChevronDownIcon } from '@/assets/icons/chevron-down.icon';
import { cn } from '@/core/utils/cn';
import { IGoogleSpreadsheet, ISheet } from '@link-in-bio/domain';

type SheetListProps = {
  spreadsheets: IGoogleSpreadsheet[];
  isFetched: boolean;
  spreadsheetId: string;
  sheetId: number;
  setSheetId: (value: number) => void;
};

const SheetList = ({ spreadsheets, isFetched, spreadsheetId, sheetId, setSheetId }: SheetListProps) => {
  const getSheetName = () => {
    const sheet = spreadsheets.find((sheet: IGoogleSpreadsheet) => sheet.spreadsheetId === spreadsheetId);
    return sheet?.sheets.find((sheet: ISheet) => sheet.sheetId === sheetId)?.sheetName;
  };

  return (
    <>
      {spreadsheetId && isFetched ? (
        <div className="mt-2">
          <Listbox value={sheetId} onChange={setSheetId}>
            <ListboxButton
              className={cn(
                'relative block w-full rounded-lg bg-main border border-secondary py-1.5 pr-8 pl-2 text-left text-sm/6',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
              )}
            >
              {getSheetName()}
              <ChevronDownIcon
                className="group pointer-events-none absolute top-2.5 right-2.5 size-4"
                aria-hidden="true"
              />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={cn(
                'w-[var(--button-width)] rounded-xl border border-dark bg-main p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
              )}
            >
              {spreadsheets
                .find((spreadsheet: IGoogleSpreadsheet) => spreadsheet.spreadsheetId === spreadsheetId)
                ?.sheets.map((sheet: ISheet) => (
                  <ListboxOption
                    key={sheet.sheetName}
                    value={sheet.sheetId}
                    className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-dark"
                  >
                    <div className="text-sm/6 text-lighter">{sheet.sheetName}</div>
                  </ListboxOption>
                ))}
            </ListboxOptions>
          </Listbox>
        </div>
      ) : null}
    </>
  );
};

export default SheetList;
