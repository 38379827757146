import { createBrowserRouter } from 'react-router-dom';

import RegisterRoute from './auth/Register.route';
import SetupAccountRoute from './auth/SetupAccount.route';
import LoginRoute from './auth/Login.route';
import ConfirmationEmailInstructionsRoute from './auth/ConfirmationEmailInstructions.route';
import ResendConfirmationEmailRoute from './auth/ResendConfirmationEmail.route';
import ForgotPasswordRoute from './auth/ForgotPassword.route';
import ResetPasswordRoute from './auth/ResetPassword.route';
import PlaygroundRoute from './playground/Playground.route';
import ShareablePreviewRoute from './link-in-bio/ShareablePreview.route';
import NotFoundRoute from './NotFound.route';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';
import { RootErrorBoundary } from '@/core/error-boundaries/RootErrorBoundary';

export const routes = [
  {
    path: '/auth/register',
    element: <RegisterRoute />,
  },
  {
    path: '/auth/setup-account',
    element: <SetupAccountRoute />,
  },
  {
    path: '/auth/login',
    element: <LoginRoute />,
  },
  {
    path: '/auth/confirmation-email-instructions',
    element: <ConfirmationEmailInstructionsRoute />,
  },
  {
    path: '/auth/resend-email-confirmation',
    element: <ResendConfirmationEmailRoute />,
  },
  {
    path: '/auth/forgot-password',
    element: <ForgotPasswordRoute />,
  },
  {
    path: '/auth/reset-password',
    element: <ResetPasswordRoute />,
  },
  {
    path: '/playground',
    element: <PlaygroundRoute />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: '/:username',
    element: <ShareablePreviewRoute />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: '*',
    element:
      window.location.pathname === '/' ? (
        (() => {
          if (window.location.host !== removeHttpProtocol(process.env.CLIENT_URL!)) {
            return <ShareablePreviewRoute />;
          }

          window.location.href = `${process.env.CLIENT_URL!}/auth/login`;
          return null;
        })()
      ) : (
        <NotFoundRoute />
      ),
    errorElement: <RootErrorBoundary />,
  },
];

const createAppRouter = () => {
  return createBrowserRouter(routes);
};

export default createAppRouter;
