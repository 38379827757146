import { ArrowPathIcon } from '@/assets/icons/arrow-path.icon';
import { DefaultDnsRecords } from '@/core/constants/default-dns-records.constants';
import { copyToClipboard } from '@/core/utils/copy-to-clipboard';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { useState } from 'react';

type DnsRecordsTableProps = {
  isDnsLookupLoading: boolean;
  dnsLookupHandler: () => void;
};

const DnsRecordsTable = ({ isDnsLookupLoading, dnsLookupHandler }: DnsRecordsTableProps) => {
  const { state } = usePlayground();
  const [copyButtonText, setCopyButtonText] = useState<string>('copy');

  const handleCopyClick = (value: string) => {
    copyToClipboard(value, (success) => {
      if (success) {
        setCopyButtonText('copied');
        setTimeout(() => setCopyButtonText('copy'), 1000);
      }
    });
  };

  return (
    <>
      {state.payload.domain && (
        <>
          <div className="my-4 flex items-center justify-between">
            <span className="text-light text-sm">DNS records must be set as the following</span>
            <ArrowPathIcon className={isDnsLookupLoading ? 'animate-spin' : ''} onClick={dnsLookupHandler} />
          </div>

          <div className="flow-root mb-2">
            <div className="-my-2 overflow-x-auto">
              <div className="inline-block w-full py-2 align-middle">
                <div className="overflow-hidden rounded-lg">
                  <table className="w-full divide-y divide-secondary">
                    <thead className="bg-secondary">
                      <tr>
                        <th scope="col" className="w-1/3 py-2 pl-4 pr-3 text-left text-sm font-normal text-light">
                          Type
                        </th>
                        <th scope="col" className="w-1/3 px-3 py-2 text-left text-sm font-normal text-light">
                          Name
                        </th>
                        <th scope="col" className="w-1/3 px-3 py-2 text-left text-sm font-normal text-light">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-secondary bg-main">
                      {DefaultDnsRecords.map((dnsRecord, index: number) => (
                        <tr key={index}>
                          <td className="w-1/3 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-lighter">
                            {dnsRecord.type}
                          </td>
                          <td className="w-1/3 whitespace-nowrap px-3 py-4 text-sm text-lighter">{dnsRecord.name}</td>
                          <td className="w-1/3 relative whitespace-nowrap px-3 py-4 text-sm text-lighter cursor-pointer group">
                            <span className="group-hover:blur-[1px] group-hover:opacity-50 transition duration-200">
                              {dnsRecord.value}
                            </span>
                            <span
                              className="absolute inset-0 flex items-center left-11 opacity-0 group-hover:opacity-100 text-sm text-lighter transition duration-200"
                              onClick={() => {
                                handleCopyClick(dnsRecord.value);
                              }}
                            >
                              {copyButtonText}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <span className="text-light text-sm">📢 DNS changes may take up to 48 hours to propagate</span>
        </>
      )}
    </>
  );
};

export default DnsRecordsTable;
