import AddCardDialog from './AddCardDialog';
import AddCardButton from './AddCardButton';

const AddCard = () => {
  return (
    <div className="w-full">
      <AddCardButton />
      <AddCardDialog />
    </div>
  );
};

export default AddCard;
