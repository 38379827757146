import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useAuth from '@/modules/auth/hooks/useAuth.hook';

export function RootErrorBoundary() {
  const { state: authState } = useAuth();

  let error = useRouteError() as Error;
  let eventId = Sentry.captureException(error);

  const showCrashDialog = () => {
    Sentry.showReportDialog({
      eventId,
      user: authState.user?._id ? { name: authState.user.name, email: authState.user.email } : {},
      subtitle: 'Please tell us what happened below',
      subtitle2: '',
      labelComments: 'A detailed report would help us fix the issue faster',
    });
  };

  if (isRouteErrorResponse(error)) {
    showCrashDialog();

    return (
      <>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </>
    );
  } else if (error instanceof Error) {
    showCrashDialog();

    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
      </div>
    );
  } else {
    showCrashDialog();

    return <h1>Unknown Error</h1>;
  }
}
