import { useContext } from 'react';
import { CardPreviewContext } from '../context/card-preview.context';

export function useCardPreview() {
  const context = useContext(CardPreviewContext);

  if (!context) {
    throw new Error('useCardPreview must be used within a CardPreviewProvider');
  }

  return context;
}
