import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/core/lib/query-client';
import { getLinkie } from '../api/get-linkie.api';
import { GetLinkieDto } from '@link-in-bio/domain';

const getLinkieQueryOptions = (getLinkieDto: GetLinkieDto) => {
  return queryOptions({
    queryKey: ['linkie', getLinkieDto],
    queryFn: () => getLinkie(getLinkieDto),
  });
};

type UseGetLinkieOptions = {
  queryConfig?: QueryConfig<typeof getLinkieQueryOptions>;
};

const useGetLinkie = (getLinkieDto: GetLinkieDto, { queryConfig }: UseGetLinkieOptions = {}) => {
  return useQuery({
    ...getLinkieQueryOptions(getLinkieDto),
    ...queryConfig,
  });
};

export default useGetLinkie;
