import { CardDto } from '@link-in-bio/domain';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useFieldArray, useFormContext } from 'react-hook-form';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import Card from '@/core/design-system/Card';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import { PointerEventVariants } from '@/core/constants/mode-variants/event.variants';
import { TrashIcon } from '@/assets/icons/trash.icon';
import { motion } from 'framer-motion';
import ModeRenderization from '../../../ModeRenderization';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PostWithThumbnail from './components/layouts/PostWithThumbnail';
import PostWithoutThumbnail from './components/layouts/PostWithoutThumbnail';
import PostSkeleton from './components/skeleton/PostSkeleton';
import PostActions from './components/PostActions';
import { cn } from '@/core/utils/cn';

type PostPreviewItemProps = {
  post: CardDto;
};

const PostPreviewItem = ({ post }: PostPreviewItemProps) => {
  const { state, dispatch } = usePlayground();
  const { control } = useFormContext();

  const sortable = useSortable({
    id: post._id || post.client_id || '',
    animateLayoutChanges: (args) => {
      return !args.wasDragging;
    },
  });

  // @ts-ignore
  const { fields } = useFieldArray({
    control,
    name: 'cards',
  });

  const deletePostHandler = () => {
    dispatch({
      type: PlaygroundActionType.DELETE_CARD,
      payload: post._id || post.client_id || '',
    });
  };

  return (
    <div
      ref={sortable.setNodeRef}
      onClick={() => {
        if (state.mode !== PlaygroundMode.PREVIEW) return;

        if (post.post_details.destination_url) {
          window.open(post.post_details.destination_url, '_blank');
          return;
        }

        if (post.post_details.url) {
          window.open(post.post_details.url, '_blank');
          return;
        }
      }}
      style={{
        transform: CSS.Translate.toString(sortable.transform),
        transition: sortable.transition,
      }}
      {...sortable.attributes}
      {...sortable.listeners}
    >
      <ModeRenderization variants={PointerEventVariants} className="group/card">
        <Card
          className={cn(
            'relative text-left bg-dark text-lighter w-full h-auto',
            sortable.active ? 'cursor-grabbing' : '',
          )}
          key={post._id || post.client_id}
        >
          {!post.post_details.url && <PostSkeleton />}
          {post.post_details.url && (
            <motion.div
              className="w-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              {post.link_preview_details.thumbnail_url ? (
                <PostWithThumbnail post={post} />
              ) : (
                <PostWithoutThumbnail post={post} />
              )}
            </motion.div>
          )}

          <PostActions post={post} />

          <button
            type="button"
            className="bg-surface-gradient shadow-surface-shadow absolute -top-3.5 -right-3.5 rounded-full p-2 text-white opacity-0 invisible pointer-events-none group-hover/card:opacity-100 group-hover/card:visible group-hover/card:pointer-events-auto transition-all duration-200 ease-in-out"
            onClick={deletePostHandler}
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        </Card>
      </ModeRenderization>
    </div>
  );
};

export default PostPreviewItem;
