import { Field, Label, Switch, Textarea } from '@headlessui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from '@/core/design-system/Button';
import useIntegrateMailing from '@/modules/link-in-bio/hooks/mailing-integration/useIntegrateMailing.hook';
import usePlayground from '../../hooks/usePlayground.hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMailingIntegration } from '@link-in-bio/domain';
import { CloseIcon } from '@/assets/icons/close.icon';
import Modal from '@/core/design-system/Modal';
import GoogleSpreadsheetIntegration from './integrations/google-spreadsheet';

type MailingIntegrationDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
};

const MailingIntegrationDialog = ({ isOpen, toggleDialog }: MailingIntegrationDialogProps) => {
  const { state } = usePlayground();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mailingIntegration = queryParams.get('mailing');

  const [enabled, setEnabled] = useState<boolean>(false);
  const [invitationText, setInvitationText] = useState<string>('');
  const [spreadsheetId, setSpreadsheetId] = useState<string>('');
  const [sheetId, setSheetId] = useState<number>(0);

  useEffect(() => {
    if (mailingIntegration && mailingIntegration === 'google-spreadsheet') {
      toggleDialog();
      setEnabled(true);
      setInvitationText(localStorage.getItem('invitationText') ?? '');
    }
  }, []);

  useEffect(() => {
    const integration = state.payload.mailing_integrations?.[0] as IMailingIntegration;
    if (!integration || !integration.enabled) return;

    setEnabled(true);
    setInvitationText(integration.invitation_text);
    setSpreadsheetId(integration?.google_spreadsheet?.spreadsheet_id ?? '');
  }, [state.payload._id]);

  const integrateMailing = useIntegrateMailing({
    mutationConfig: {
      onSuccess: () => {
        toggleDialog();
        navigate({
          pathname: location.pathname,
        });
      },
    },
  });

  const integrateMailingHandler = () => {
    integrateMailing.mutate({
      linkInBioId: state.payload._id!,
      enabled,
      invitation_text: invitationText,
      spreadsheet_id: spreadsheetId,
      sheet_id: sheetId,
    });

    localStorage.removeItem('invitationText');
  };

  const invitationTextHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInvitationText(e.target.value);
    localStorage.setItem('invitationText', e.target.value);
  };

  return (
    <Modal isOpen={isOpen} toggleDialog={toggleDialog} dialogPanelClassName="md:w-[500px]">
      <div className="p-6 rounded-2xl bg-main border border-1 border-secondary text-lighter">
        <div onClick={toggleDialog} className="cursor-pointer absolute right-4 top-6">
          <CloseIcon />
        </div>

        <h1 className="font-bold text-lg pb-4">Mailing Integration</h1>

        <div className="block h-[1px] border-0 border-t border-t-secondary" />

        <div className="px-2 flex items-center justify-between py-4">
          <p>Enable email subscribers</p>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1 transition-all duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-primary"
          >
            <span
              aria-hidden="true"
              className="pointer-events-none inline-block size-5 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
            />
          </Switch>
        </div>

        <div className="block h-[1px] border-0 border-t border-t-secondary" />

        <div className="w-full py-4">
          <Field>
            <Label className="text-sm/6">Invitation Text</Label>
            <Textarea
              value={invitationText}
              className="outline-none mt-2 block w-full resize-none rounded-lg border border-solid border-secondary bg-main py-1.5 px-3 text-sm/6 disabled:bg-secondary disabled:text-light focus:bg-dark focus:border-input-focus transition-all duration-200 ease-in-out"
              rows={3}
              disabled={!enabled}
              onChange={invitationTextHandler}
            />
          </Field>
        </div>

        <div className="block h-[1px] border-0 border-t border-t-secondary" />

        <div className="py-4">
          <h1 className="font-bold text-lg">Integration</h1>

          <div className="px-2 py-4">
            <GoogleSpreadsheetIntegration
              enabled={enabled}
              setEnabled={setEnabled}
              spreadsheetId={spreadsheetId}
              setSpreadsheetId={setSpreadsheetId}
              sheetId={sheetId}
              setSheetId={setSheetId}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-2">
          <Button variant="outlined" color="secondary" className="w-max" onClick={toggleDialog}>
            Cancel
          </Button>
          <Button
            variant="filled"
            color="primary"
            className="w-max"
            disabled={!enabled || !invitationText}
            onClick={integrateMailingHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MailingIntegrationDialog;
