export const ProfileInputVariants = {
  EDITOR: {
    pointerEvents: 'none' as 'none',
    color: '#F3F4F6',
    paddingTop: 0,
    paddingBottom: 0,
  },
  PREVIEW: {
    pointerEvents: 'auto' as 'auto',
    color: '#C0C2D1',
    paddingTop: 'auto',
    paddingBottom: 'auto',
  },
};

export const PostFieldVariants = {
  EDITOR: {
    pointerEvents: 'none' as 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
  PREVIEW: {
    pointerEvents: 'auto' as 'auto',
    paddingTop: 'auto',
    paddingBottom: 'auto',
  },
};
