import { ENUM_CARD_TYPE } from '@/modules/playground/enums/card-type.enum';
import { ModifyCardDto } from '@link-in-bio/domain';

export const findEmptyCardIndex = (cards: ModifyCardDto[]) => {
  return cards.findIndex((card: ModifyCardDto) => {
    if (card.type === ENUM_CARD_TYPE.LINK) {
      return !card.link_details?.url;
    }

    if (card.type === ENUM_CARD_TYPE.POST) {
      return !card.post_details?.url;
    }

    if (card.type === ENUM_CARD_TYPE.EMBED) {
      return !card.embed_details?.html;
    }
  });
};

export const updateCardAtIndex = (cards: any[], index: number, payload: any) => {
  return cards.map((card, i) => (i === index ? { ...card, ...payload } : card));
};

export const getNewCardPosition = (cards: any[]) => {
  if (cards.length === 0) return 0;
  return Math.max(...cards.map((card) => card.position ?? 0)) + 1;
};
