import { CardDto } from '@link-in-bio/domain';
import PostPreviewFavicon from './components/Favicon';
import PostPreviewThumbnail from './components/Thumbnail';
import PostPreviewTitle from './components/Title';
import PostPreviewDescription from './components/Description';

type PostWithThumbnailProps = {
  post: CardDto;
};

const PostWithThumbnail = ({ post }: PostWithThumbnailProps) => {
  return (
    <>
      <PostPreviewFavicon favicon_url={post.link_preview_details.favicon_url} className="absolute top-4 left-4" />
      <PostPreviewThumbnail post={post} />
      <PostPreviewTitle />
      <PostPreviewDescription />
    </>
  );
};

export default PostWithThumbnail;
