import { useCards } from '@/modules/playground/hooks/useCards.hook';
import CardActions, { CardActionItem } from '../../components/CardActions';
import { ENUM_CARD_TYPE } from '@/modules/playground/enums/card-type.enum';
import { CardDto } from '@link-in-bio/domain';

type PostActionsProps = {
  post: CardDto;
};

const PostActions = ({ post }: PostActionsProps) => {
  const { toggleAddDialog, setSelectedCardToAdd, setEditDetails, setPostDetails } = useCards();

  const editRedirectLink = () => {
    setEditDetails((prev) => ({
      ...prev,
      isEdit: true,
      cardId: post._id || post.client_id || '',
      cardType: ENUM_CARD_TYPE.POST,
    }));
    setPostDetails({
      url: post.post_details.url,
      destination_url: post.post_details.destination_url,
    });
    setSelectedCardToAdd(ENUM_CARD_TYPE.POST);
    toggleAddDialog();
  };

  return (
    <CardActions className="w-[80%]">
      <CardActionItem onClick={editRedirectLink}>
        <span className="text-sm">Edit redirect link</span>
      </CardActionItem>
    </CardActions>
  );
};

export default PostActions;
