import toastClient from '@/core/lib/toast-client';
import useResendEmailConfirmation from '../hooks/useResendEmailConfirmation.hook';

type ConfirmationEmailInstructionsProps = {
  email: string;
};

const ConfirmationEmailInstructions = ({ email }: ConfirmationEmailInstructionsProps) => {
  const resendConfirmationEmail = useResendEmailConfirmation({
    mutationConfig: {
      onSuccess: () => {
        toastClient.success('Email has been sent successfully!');
      },
    },
  });

  const onResendHandler = () => {
    resendConfirmationEmail.mutate({ email });
  };

  return (
    <div>
      <h1>You've got mail!</h1>
      <p>
        A confirmation link to activate your account has been sent to{' '}
        <span className="text-primary">{email ?? 'your email address'}</span>. Make sure to double check the spam/junk
        folder.
      </p>
      {email ? (
        <p>
          Issues?{' '}
          <a onClick={onResendHandler} className="cursor-pointer text-primary">
            Resend
          </a>
        </p>
      ) : null}
    </div>
  );
};

export default ConfirmationEmailInstructions;
