import Button from '@/core/design-system/Button';
import { GoogleSpreadsheetIcon } from '@/assets/icons/google-spreadsheet.icon';
import useConnectGoogle from '@/modules/link-in-bio/hooks/mailing-integration/useConnectGoogle.hook';
import useDeintegrateMailing from '@/modules/link-in-bio/hooks/mailing-integration/useDeintegrateMailing.hook';
import { useEffect, useState } from 'react';
import { IGoogleSpreadsheet } from '@link-in-bio/domain';
import useGetGoogleSpreadsheets from '@/modules/link-in-bio/hooks/mailing-integration/useGetGoogleSpreadsheet.hook';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import SpreadsheetList from './SpreadsheetList';
import SheetList from './SheetList';
import useIsGoogleConnected from '@/modules/link-in-bio/hooks/mailing-integration/useIsGoogleConnected.hook';
import CreateSpreadsheetDialog from './CreateSpreadsheetDialog';

type GoogleSpreadsheetIntegrationProps = {
  enabled: boolean;
  setEnabled: (value: boolean) => void;
  spreadsheetId: string;
  setSpreadsheetId: (value: string) => void;
  sheetId: number;
  setSheetId: (value: number) => void;
};

const GoogleSpreadsheetIntegration = ({
  enabled,
  setEnabled,
  spreadsheetId,
  setSpreadsheetId,
  sheetId,
  setSheetId,
}: GoogleSpreadsheetIntegrationProps) => {
  const { state } = usePlayground();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [spreadsheets, setSpreadsheets] = useState<IGoogleSpreadsheet[]>([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const isGoogleConnected = useIsGoogleConnected(state.payload._id ?? '', {
    queryConfig: {
      enabled: !!state.payload._id,
    },
  });

  const getGoogleSpreadsheetsQuery = useGetGoogleSpreadsheets(state.payload._id ?? '', {
    queryConfig: {
      enabled: !!state.payload._id && isConnected,
    },
  });

  useEffect(() => {
    const connection = isGoogleConnected?.data?.data ?? false;
    setIsConnected(connection);
  }, [isGoogleConnected?.data?.data]);

  useEffect(() => {
    const response = (getGoogleSpreadsheetsQuery?.data?.data ?? []) as IGoogleSpreadsheet[];
    if (!response.length) return;

    setSpreadsheets(response);
    setSpreadsheetId(response[0].spreadsheetId);
    setSheetId(response[0].sheets[0].sheetId);
  }, [isConnected, getGoogleSpreadsheetsQuery?.data]);

  const connectGoogle = useConnectGoogle({
    mutationConfig: {
      onSuccess: (response) => {
        window.location.href = response.data;
      },
    },
  });

  const deintegrateMailing = useDeintegrateMailing({
    mutationConfig: {
      onSuccess: () => {
        setIsConnected(false);
        setEnabled(false);
        setSpreadsheets([]);
        setSpreadsheetId('');
        setSheetId(0);
      },
    },
  });

  const connectDisconnectGoogleHandler = () => {
    if (isConnected) {
      deintegrateMailing.mutate(state.payload._id!);
      return;
    }

    connectGoogle.mutate(undefined);
  };

  const toggleCreateDialog = () => {
    setIsCreateDialogOpen(!isCreateDialogOpen);
  };

  const onCreateSpreadsheetSuccess = () => {
    getGoogleSpreadsheetsQuery.refetch();
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="rounded-full w-8 h-8 bg-dark flex justify-center items-center mr-2">
            <GoogleSpreadsheetIcon />
          </div>
          Google Spreadsheet
        </div>

        <Button
          variant={isConnected ? 'filled' : 'outlined'}
          color="secondary"
          className="w-max"
          disabled={!enabled}
          onClick={connectDisconnectGoogleHandler}
        >
          {isConnected ? 'Disconnect' : 'Connect'}
        </Button>
      </div>

      {isConnected ? (
        <>
          <div className="bg-dark rounded-lg p-4 my-4">
            <p className="text-sm font-normal">Select Spreadsheet</p>

            {getGoogleSpreadsheetsQuery.isLoading || getGoogleSpreadsheetsQuery.isRefetching ? (
              <>
                <div className="mt-2 bg-main rounded-lg py-1.5 w-full h-[38px] animate-pulse" />
                <div className="mt-2 bg-main rounded-lg py-1.5 w-full h-[38px] animate-pulse" />
              </>
            ) : (
              <>
                <SpreadsheetList
                  spreadsheets={spreadsheets}
                  isFetched={getGoogleSpreadsheetsQuery.isFetched}
                  spreadsheetId={spreadsheetId}
                  setSpreadsheetId={setSpreadsheetId}
                  toggleCreateDialog={toggleCreateDialog}
                />

                <SheetList
                  spreadsheets={spreadsheets}
                  isFetched={getGoogleSpreadsheetsQuery.isFetched}
                  spreadsheetId={spreadsheetId}
                  sheetId={sheetId}
                  setSheetId={setSheetId}
                />
              </>
            )}
          </div>

          <CreateSpreadsheetDialog
            isOpen={isCreateDialogOpen}
            toggleDialog={toggleCreateDialog}
            onCreateSpreadsheetSuccess={onCreateSpreadsheetSuccess}
          />
        </>
      ) : null}
    </>
  );
};

export default GoogleSpreadsheetIntegration;
