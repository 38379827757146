import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { ChevronDownIcon } from '@/assets/icons/chevron-down.icon';
import { IGoogleSpreadsheet } from '@link-in-bio/domain';
import { cn } from '@/core/utils/cn';
import Button from '@/core/design-system/Button';

type SpreadsheetListProps = {
  spreadsheets: IGoogleSpreadsheet[];
  isFetched: boolean;
  spreadsheetId: string;
  setSpreadsheetId: (value: string) => void;
  toggleCreateDialog: () => void;
};

const SpreadsheetList = ({
  spreadsheets,
  isFetched,
  spreadsheetId,
  setSpreadsheetId,
  toggleCreateDialog,
}: SpreadsheetListProps) => {
  const getSpreadsheetName = () => {
    return spreadsheets.find((sheet: IGoogleSpreadsheet) => sheet.spreadsheetId === spreadsheetId)?.spreadsheetName;
  };

  return (
    <>
      {isFetched ? (
        <div className="mt-2">
          <Listbox value={spreadsheetId} onChange={setSpreadsheetId}>
            <ListboxButton
              className={cn(
                'relative block w-full rounded-lg bg-main border border-secondary py-1.5 pr-8 pl-2 text-left text-sm/6',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
              )}
            >
              {getSpreadsheetName() ?? 'Team Spreadsheet'}
              <ChevronDownIcon className="group pointer-events-none absolute top-2.5 right-2.5 size-4" />
            </ListboxButton>
            <ListboxOptions
              anchor="bottom"
              transition
              className={cn(
                'w-[var(--button-width)] rounded-xl border border-dark bg-main p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
              )}
            >
              {spreadsheets.map((sheet: IGoogleSpreadsheet) => (
                <ListboxOption
                  key={sheet.spreadsheetName}
                  value={sheet.spreadsheetId}
                  className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-dark"
                >
                  <div className="text-sm/6 text-lighter">{sheet.spreadsheetName}</div>
                </ListboxOption>
              ))}

              <div className="w-full h-[1px] bg-secondary my-1.5" />

              <ListboxOption
                value={null}
                className="group flex cursor-default items-center gap-2 rounded-lg px-1.5 pb-0.5 select-none"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Button variant="outlined" color="secondary" onClick={toggleCreateDialog}>
                  Create a new spreadsheet
                </Button>
              </ListboxOption>
            </ListboxOptions>
          </Listbox>
        </div>
      ) : null}
    </>
  );
};

export default SpreadsheetList;
