import { CardDto } from '@link-in-bio/domain';
import PostPreviewFavicon from './components/Favicon';
import PostPreviewTitle from './components/Title';
import PostPreviewDescription from './components/Description';

type PostWithoutThumbnailProps = {
  post: CardDto;
};

const PostWithoutThumbnail = ({ post }: PostWithoutThumbnailProps) => {
  return (
    <>
      <div className="flex items-center gap-x-2 mb-2">
        <PostPreviewFavicon favicon_url={post.link_preview_details.favicon_url} className="w-6 h-6" />
        <PostPreviewTitle />
      </div>
      <PostPreviewDescription />
    </>
  );
};

export default PostWithoutThumbnail;
