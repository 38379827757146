import { api } from '@/core/lib/api-client';
import { ResponseType, SubscribeDto } from '@link-in-bio/domain';

export const subscribe = ({
  subscribeDto,
  linkInBioId,
}: {
  subscribeDto: SubscribeDto;
  linkInBioId: string;
}): Promise<ResponseType<void>> => {
  return api.post(
    `/mailing-integration/subscribe/${linkInBioId}`,
    { email: subscribeDto.email },
    // {
    //   headers: {
    //     'x-recaptcha-token': subscribeDto.captcha_token,
    //   },
    // },
  );
};
