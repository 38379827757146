export const EditEmbedIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.56809 2.68555C5.56809 3.2226 5.56809 3.75617 5.56809 4.29322C5.5646 4.92443 5.16355 5.32548 4.52885 5.32897C3.45823 5.32897 2.38761 5.32897 1.32048 5.32897C0.682296 5.32897 0.28125 4.92443 0.28125 4.28276C0.28125 3.21563 0.28125 2.1485 0.28125 1.08136C0.28125 0.436202 0.678809 0.0351562 1.32048 0.0351562C2.39808 0.0351562 3.47916 0.0351562 4.55675 0.0351562C5.06591 0.0351562 5.44603 0.335069 5.55065 0.80935C5.56809 0.896534 5.57157 0.990692 5.57157 1.08136C5.57157 1.61493 5.56809 2.15198 5.56809 2.68555Z"
      fill="#FDDF79"
    />
    <path
      d="M9.60195 5.32855C9.06838 5.32855 8.53133 5.32855 7.99776 5.32855C7.37353 5.32506 6.96899 4.92401 6.96551 4.29978C6.96202 3.2187 6.96202 2.13761 6.96551 1.05653C6.96551 0.439271 7.37004 0.03125 7.98381 0.03125C9.06838 0.03125 10.1495 0.03125 11.234 0.03125C11.8443 0.03125 12.2489 0.435784 12.2523 1.04607C12.2558 2.13413 12.2558 3.22567 12.2523 4.31373C12.2523 4.92053 11.8443 5.32506 11.234 5.32855C10.69 5.33203 10.146 5.32855 9.60195 5.32855Z"
      fill="#FDDF79"
    />
    <path
      d="M5.56767 9.37779C5.56767 9.90787 5.55372 10.4414 5.57115 10.9715C5.59208 11.5818 5.12128 12.0386 4.52146 12.0317C3.45781 12.0142 2.39068 12.0282 1.32355 12.0282C0.681877 12.0282 0.280831 11.6271 0.277344 10.989C0.277344 9.91833 0.277344 8.84423 0.277344 7.77361C0.277344 7.13891 0.67839 6.73438 1.3096 6.73438C2.38371 6.73438 3.45781 6.73438 4.53192 6.73438C5.16313 6.73438 5.56418 7.13891 5.56418 7.77361C5.57115 8.30717 5.56767 8.84074 5.56767 9.37779Z"
      fill="#FDDF79"
    />
    <path
      d="M10.0291 9.7959C10.0291 10.4097 10.0291 11.0025 10.0291 11.5954C10.0291 11.7942 9.91055 11.9581 9.73269 12.0104C9.56181 12.0627 9.37001 12.0034 9.27585 11.8465C9.22702 11.7663 9.19912 11.6582 9.19912 11.564C9.19215 11.0269 9.19564 10.4934 9.19564 9.95632C9.19564 9.9075 9.19564 9.86216 9.19564 9.7959C9.14333 9.7959 9.09799 9.7959 9.05266 9.7959C8.51212 9.7959 7.97506 9.7959 7.43452 9.7959C7.15902 9.7959 6.9707 9.62502 6.9707 9.37393C6.9707 9.12284 7.15553 8.95894 7.43801 8.95894C7.97157 8.95894 8.50863 8.95894 9.04219 8.95894C9.08753 8.95894 9.13635 8.95894 9.19564 8.95894C9.19564 8.89965 9.19564 8.85432 9.19564 8.80898C9.19564 8.26844 9.19564 7.7279 9.19564 7.18736C9.19564 6.91535 9.36303 6.734 9.60715 6.73052C9.85824 6.72703 10.0291 6.91535 10.0291 7.19085C10.0291 7.77324 10.0291 8.35911 10.0291 8.95894C10.0814 8.95894 10.1268 8.95894 10.1721 8.95894C10.7126 8.95894 11.2497 8.95894 11.7902 8.95894C12.0657 8.95894 12.2541 9.12982 12.2541 9.37742C12.2541 9.62502 12.0657 9.79242 11.7867 9.7959C11.2532 9.7959 10.7161 9.7959 10.1826 9.7959C10.1337 9.7959 10.0884 9.7959 10.0291 9.7959Z"
      fill="#FDDF79"
    />
  </svg>
);
