import { PlaygroundMode } from '../../enums/mode.enum';
import usePlayground from '../../hooks/usePlayground.hook';

type MailingIntegrationButtonProps = {
  toggleDialog: () => void;
};

const MailingIntegrationButton = ({ toggleDialog }: MailingIntegrationButtonProps) => {
  const { state } = usePlayground();

  if (state.mode === PlaygroundMode.PREVIEW) return null;

  return (
    <div className="flex gap-2 bg-browser-taskbar rounded-tl-[4px] rounded-tr-[4px] border-b border-b-1 border-surface">
      <button
        type="button"
        className="flex justify-center px-3 py-2 text-sm font-bold transition-all duration-200 ease-in-out w-[140px] absolute top-0 left-0 rounded-full border border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-light"
        onClick={toggleDialog}
      >
        Collect Emails
      </button>
    </div>
  );
};

export default MailingIntegrationButton;
