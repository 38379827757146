import { Checkbox } from '@headlessui/react';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import useSubscribe from '@/modules/link-in-bio/hooks/subscribe/useSubscribe.hook';
import { CheckIcon } from '@/assets/icons/check.icon';
import DefaultAvatarAsset from '@/assets/images/default-avatar.png';
import { cn } from '@/core/utils/cn';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Recaptcha from '@/core/components/recaptcha';

type NotSubscriberViewProps = {
  setShowVerificationStep: (value: boolean) => void;
  email: string;
  setEmail: (email: string) => void;
};

const NotSubscriberView = ({ setShowVerificationStep, email, setEmail }: NotSubscriberViewProps) => {
  const { state } = usePlayground();
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  // const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const subscribe = useSubscribe({
    mutationConfig: {
      onSuccess: () => {
        setShowVerificationStep(true);
      },
    },
  });

  const subscribeHandler = async () => {
    // const captcha_token = await recaptchaRef.current?.executeAsync();

    subscribe.mutate({
      subscribeDto: {
        email,
        captcha_token: '',
        // captcha_token: captcha_token || '',
      },
      linkInBioId: state.payload._id!,
    });
  };

  return (
    <>
      <div className="relative">
        <img
          src={state.payload.profile_image_url ?? DefaultAvatarAsset}
          alt="Profile Image"
          className="mx-auto w-[140px] h-[140px] rounded-full shadow-profile-image-shadow"
        />

        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 trans w-fit text-xs rounded-3xl flex justify-center items-center py-1 px-2 text-lighter bg-profile-gradient shadow-profile-shadow">
          {state.payload.name}
        </div>
      </div>

      <div className="py-4">
        <h1 className="text-lighter text-2xl font-bold mb-2">Subscribe to {state.payload.username}</h1>
        <p className="text-light text-base">{state.payload.mailing_integrations?.[0]?.invitation_text}</p>
      </div>
      <div className="bg-dark border border-solid border-secondary py-2 px-2 rounded-xl">
        <input
          value={email}
          placeholder="Your Email"
          className="placeholder-surface text-sm outline-none w-full rounded-lg border border-solid border-secondary bg-main py-2 px-4 text-lighter"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="flex items-center pt-2 pb-4">
        <Checkbox
          value={agreedToTerms}
          className="group block size-4 rounded border border-secondary bg-surface-dark data-[checked]:bg-emerald"
          onClick={() => {
            setAgreedToTerms(!agreedToTerms);
          }}
        >
          <CheckIcon />
        </Checkbox>
        <p className="text-white text-sm ml-2">I agree to receive emails from {state.payload.username}</p>
      </div>

      <button
        className={cn(
          'mt-2 rounded-lg py-2 px-4 text-lighter text-sm font-bold w-full transition-all duration-200 ease-in-out',
          agreedToTerms ? 'bg-emerald hover:bg-emerald-light' : 'bg-surface-dark',
        )}
        disabled={!agreedToTerms}
        onClick={subscribeHandler}
      >
        Subscribe
      </button>

      {/* <Recaptcha ref={recaptchaRef} /> */}
    </>
  );
};

export default NotSubscriberView;
