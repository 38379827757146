import { cn } from '@/core/utils/cn';
import * as React from 'react';

type CardActionItemProps = {
  children: React.ReactNode;
  onClick: () => void;
};

export const CardActionItem = ({ children, onClick }: CardActionItemProps) => (
  <div
    className="p-1 hover:bg-surface-dark rounded transition-all duration-200 ease-in-out flex justify-center items-center w-full h-6"
    onClick={onClick}
  >
    {children}
  </div>
);

type CardActionsProps = {
  children: React.ReactNode;
  className?: string;
};

const CardActions = ({ children, className = '' }: CardActionsProps) => {
  return (
    <div
      className={cn(
        'bg-surface-gradient shadow-surface-shadow z-50 absolute -bottom-5 left-1/2 transform -translate-x-1/2 md:flex gap-[10px] rounded-[10px] justify-center items-center p-2 hidden md:opacity-0 invisible pointer-events-none group-hover/card:flex md:group-hover/card:opacity-100 group-hover/card:visible group-hover/card:pointer-events-auto transition-all duration-200 ease-in-out',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default CardActions;
