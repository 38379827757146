import { ModifyCardDto, ModifyLinkInBioDto } from '@link-in-bio/domain';
import { PlaygroundModeType } from '../store/playground-reducer';

export enum PlaygroundActionType {
  SET_INIT_PAYLOAD = 'SET_INIT_PAYLOAD',
  SET_PAYLOAD = 'SET_PAYLOAD',
  CLEAR_PAYLOAD = 'CLEAR_PAYLOAD',
  CHANGE_MODE = 'CHANGE_MODE',
  SET_CARD = 'SET_CARD',
  SET_CARD_PAYLOAD = 'SET_CARD_PAYLOAD',
  DELETE_CARD = 'DELETE_CARD',
  REORDER_CARDS = 'REORDER_CARDS',
  SET_IS_CARD_DRAGGABLE = 'SET_IS_CARD_DRAGGABLE',
  CLEAR_ACTIONS = 'CLEAR_ACTIONS',
}

export interface ISetInitPayloadAction {
  type: PlaygroundActionType.SET_INIT_PAYLOAD;
  payload: ModifyLinkInBioDto;
}

export interface ISetPayloadAction {
  type: PlaygroundActionType.SET_PAYLOAD;
  payload: ModifyLinkInBioDto;
}

export interface IClearPayloadAction {
  type: PlaygroundActionType.CLEAR_PAYLOAD;
}

export interface IChangeModeAction {
  type: PlaygroundActionType.CHANGE_MODE;
  payload: PlaygroundModeType;
}

export interface ISetCardAction {
  type: PlaygroundActionType.SET_CARD;
  payload: ModifyCardDto;
}

export interface ISetCardPayloadAction {
  type: PlaygroundActionType.SET_CARD_PAYLOAD;
  payload: ModifyCardDto & {
    cardId: string;
  };
}

export interface IDeleteCardAction {
  type: PlaygroundActionType.DELETE_CARD;
  payload: string;
}

export interface IReorderCardsAction {
  type: PlaygroundActionType.REORDER_CARDS;
  payload: ModifyCardDto[];
}

export interface ISetIsCardDraggableAction {
  type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE;
  payload: boolean;
}

export interface IClearActionsAction {
  type: PlaygroundActionType.CLEAR_ACTIONS;
}

export type PlaygroundAction =
  | ISetInitPayloadAction
  | ISetPayloadAction
  | IClearPayloadAction
  | IChangeModeAction
  | ISetCardAction
  | ISetCardPayloadAction
  | IDeleteCardAction
  | IReorderCardsAction
  | ISetIsCardDraggableAction
  | IClearActionsAction;

export type PlaygroundDispatch = (action: PlaygroundAction) => void;
