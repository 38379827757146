import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';

const CardsEmptySkeleton = () => {
  const { state } = usePlayground();

  if (state.mode === PlaygroundMode.PREVIEW || state.payload.cards?.length) return null;

  return (
    <div className="w-full grid grid-cols-2 gap-2 relative">
      <div className="grid gap-2">
        <div>
          <div className="w-full h-44 bg-dark rounded-2xl" />
        </div>
        <div>
          <div className="w-full h-32 bg-dark rounded-2xl" />
        </div>
      </div>
      <div className="grid gap-2">
        <div>
          <div className="w-full h-32 bg-dark rounded-2xl" />
        </div>
        <div>
          <div className="w-full h-44 bg-dark rounded-2xl" />
        </div>
      </div>
      <div className="bg-gradient-to-b from-transparent to-main w-full h-40 absolute bottom-0 left-0 " />
    </div>
  );
};

export default CardsEmptySkeleton;
