import { cn } from '@/core/utils/cn';
import { BentoGridProps, BentoItem } from './types';

const BentoGrid = ({ items, gridCols, classNames }: BentoGridProps): React.ReactNode => {
  return (
    <div
      className={cn(`grid gap-y-2 ${classNames?.container ?? ''}`)}
      style={{
        gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
      }}
    >
      {items.map((item: BentoItem, index: number) => {
        return (
          <div
            key={item.id ?? index}
            className={classNames?.elementContainer}
            style={{
              gridColumn: `span ${item.width}`,
              // gridRowEnd: `span ${Math.ceil(item.height / 100)}`,
            }}
          >
            {item.element}
          </div>
        );
      })}
    </div>
  );
};

export default BentoGrid;
