import OtpForm from '@/core/design-system/OtpForm';
import useSubscribe from '@/modules/link-in-bio/hooks/subscribe/useSubscribe.hook';
import useVerifySubscribe from '@/modules/link-in-bio/hooks/subscribe/useVerifySubscribe.hook';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { useState } from 'react';

type SubscriberVerificationViewProps = {
  email: string;
  toggleDialog: () => void;
  setShowVerificationStep: (value: boolean) => void;
};

const SubscriberVerificationView = ({
  email,
  toggleDialog,
  setShowVerificationStep,
}: SubscriberVerificationViewProps) => {
  const { state } = usePlayground();
  const [pin, setPin] = useState<string[]>(['', '', '', '', '', '']);

  const resendSubscribeCode = useSubscribe();

  const verifySubscribe = useVerifySubscribe({
    mutationConfig: {
      onSuccess: () => {
        toggleDialog();
        setShowVerificationStep(false);
      },
    },
  });

  const resendSubscribeCodeHandler = () => {
    resendSubscribeCode.mutate({
      subscribeDto: {
        email,
        captcha_token: '',
      },
      linkInBioId: state.payload._id!,
    });
  };

  const verifySubscribeHandler = () => {
    const code = pin.join('');
    if (code.length !== 6) return;

    verifySubscribe.mutate({
      linkInBioId: state.payload._id!,
      code,
      email,
    });
  };

  return (
    <>
      <h1 className="text-lighter text-2xl font-bold mb-2">Confirm your email address</h1>
      <p className="text-light text-base">
        We've sent a 6-digit code to <strong className="text-lighter">{email}</strong>. <br />
        Enter it below.
      </p>

      <div className="pt-4 pb-8">
        <div className="rounded-xl p-2 gap-2 flex justify-center bg-dark border border-solid border-secondary">
          <OtpForm pin={pin} setPin={setPin} />
        </div>
      </div>

      <button
        className="rounded-lg py-2 px-4 text-lighter text-sm font-bold w-full bg-emerald hover:bg-emerald-light transition-all duration-200 ease-in-out"
        onClick={verifySubscribeHandler}
      >
        Confirm
      </button>

      <p className="mt-2 text-sm text-light">
        Didn't receive a code?{' '}
        <a onClick={resendSubscribeCodeHandler} className="text-input-focus cursor-pointer">
          Resend
        </a>
      </p>
    </>
  );
};

export default SubscriberVerificationView;
