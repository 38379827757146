import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import { cn } from '@/core/utils/cn';
import { ENUM_DOMAIN_STATUS } from '@/modules/playground/enums/domain-status.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { useEffect, useState } from 'react';
import useDnsLookup from '@/modules/link-in-bio/hooks/domain/useDnsLookup.hook';
import useAddDomain from '@/modules/link-in-bio/hooks/domain/useAddDomain.hook';
import { LinkInBioDto } from '@link-in-bio/domain';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import toastClient from '@/core/lib/toast-client';
import TwitterText from 'twitter-text';
import _ from 'lodash';
import DnsRecordsTable from './DnsRecordsTable';

const CustomDomain = () => {
  const { state, dispatch } = usePlayground();
  const [isDnsLookupLoading, setIsDnsLookupLoading] = useState<boolean>(false);
  const [customDomainValue, setCustomDomainValue] = useState<string>('');

  useEffect(() => {
    if (!state.payload.domain) return;
    setCustomDomainValue(state.payload.domain);
  }, [state.payload.domain]);

  const addDomain = useAddDomain({
    mutationConfig: {
      onSuccess: ({ data }: { data: LinkInBioDto }) => {
        dispatch({
          type: PlaygroundActionType.SET_PAYLOAD,
          payload: {
            domain: data.domain,
            domain_status: data.domain_status,
          },
        });
      },
    },
  });

  const dnsLookup = useDnsLookup({
    mutationConfig: {
      onSuccess: ({ data }: { data: { status: ENUM_DOMAIN_STATUS } }) => {
        dispatch({
          type: PlaygroundActionType.SET_PAYLOAD,
          payload: {
            domain_status: data.status,
          },
        });
      },
    },
  });

  const changeCustomDomainHandler = () => {
    if (customDomainValue && !TwitterText.isValidUrl(customDomainValue, true, false)) {
      toastClient.error('Please enter a valid domain!');
      return;
    }

    addDomain.mutate({
      linkInBioId: state.payload._id!,
      domain: customDomainValue,
    });

    dnsLookupHandler();
  };

  const dnsLookupHandler = async () => {
    if (!customDomainValue) return;

    setIsDnsLookupLoading(true);

    try {
      await dnsLookup.mutateAsync({
        domain: customDomainValue,
      });
    } finally {
      setIsDnsLookupLoading(false);
    }
  };

  const getStatusColor = () => {
    switch (state.payload.domain_status) {
      case ENUM_DOMAIN_STATUS.LIVE:
        return 'bg-success';
      case ENUM_DOMAIN_STATUS.PENDING:
        return 'bg-pending';
      case ENUM_DOMAIN_STATUS.FAILED:
        return 'bg-failed';
      default:
        return 'bg-pending';
    }
  };

  return (
    <>
      <div className="flex items-start justify-between">
        <div>
          <div className="text-lighter font-semibold">Custom Domain</div>
          <div className="text-light text-sm mt-1 mb-4">Type to connect a domain you own</div>
        </div>

        {state.payload.domain ? (
          <div className="flex items-center gap-x-3">
            <span className="relative flex size-3">
              <span
                className={cn(
                  'absolute inline-flex h-full w-full animate-ping rounded-full opacity-75',
                  getStatusColor(),
                )}
              ></span>
              <span className={cn('relative inline-flex size-3 rounded-full', getStatusColor())}></span>
            </span>
            <span className="text-lighter">{_.capitalize(state.payload.domain_status)}</span>
          </div>
        ) : null}
      </div>

      <div className="relative flex items-center w-full h-auto">
        <input
          type="text"
          placeholder="www.example.com"
          className="block w-full h-10 rounded-lg border border-solid border-secondary py-[14px] px-2 text-white bg-main focus:outline-none focus:border-primary"
          value={customDomainValue}
          onChange={(event) => {
            setCustomDomainValue(event.target.value);
          }}
        />
        <EnterKBD
          color="primary"
          text="Save"
          onClick={changeCustomDomainHandler}
          disabled={customDomainValue === state.payload.domain}
        />
      </div>

      <DnsRecordsTable isDnsLookupLoading={isDnsLookupLoading} dnsLookupHandler={dnsLookupHandler} />
    </>
  );
};

export default CustomDomain;
