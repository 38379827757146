export const createGoogleMapsIframe = (url: string): string => {
  const placeMatch = url.match(/place\/([^\/@]+)/);
  const placeName = placeMatch ? decodeURIComponent(placeMatch[1]).replace(/\+/g, ' ') : '';

  return `<iframe
    width="100%"
    height="450"
    frameborder="0" 
    style="border:0;border-radius:8px;"
    referrerpolicy="no-referrer-when-downgrade"
    src="https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_API_KEY}&q=${encodeURIComponent(placeName)}"
    allowfullscreen>
  </iframe>`;
};
