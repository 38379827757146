type SocialIconProps = {
  onClick?: () => void;
};

export const TwitterIcon = ({ onClick = () => {} }: SocialIconProps) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
    onClick={onClick}
  >
    <g clipPath="url(#clip0_1662_7075)">
      <path
        d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.95431 31.5457 0 20.5 0C9.45431 0 0.5 8.95431 0.5 20C0.5 31.0457 9.45431 40 20.5 40Z"
        fill="black"
      />
      <path
        d="M26.3537 10.8594H29.4549L22.6796 18.6031L30.6502 29.1406H24.4093L19.5212 22.7497L13.928 29.1406H10.8249L18.0718 20.8578L10.4255 10.8594H16.8249L21.2434 16.7009L26.3537 10.8594ZM25.2652 27.2844H26.9837L15.8912 12.6181H14.0471L25.2652 27.2844Z"
        fill="white"
      />
      <circle cx="20.5" cy="20" r="19.375" stroke="#1B1D21" strokeWidth="1.25" />
    </g>
    <defs>
      <clipPath id="clip0_1662_7075">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
