export const PointerEventVariants = {
  EDITOR: {
    pointerEvents: 'auto' as 'auto',
  },
  PREVIEW: {
    pointerEvents: 'none' as 'none',
  },
};

export const AutoPointerEventVariants = {
  EDITOR: {
    pointerEvents: 'auto' as 'auto',
  },
  PREVIEW: {
    pointerEvents: 'auto' as 'auto',
  },
};
