import { resendEmailConfirmationInputSchema } from '../validators/resend-email-confirmation.validator';
import Form from '@/core/components/form/Form';
import Input from '@/core/components/form/fields/Input';
import Button from '@/core/design-system/Button';
import useResendEmailConfirmation from '../hooks/useResendEmailConfirmation.hook';
import toastClient from '@/core/lib/toast-client';

const ResendEmailConfirmationForm = () => {
  const resendConfirmationEmail = useResendEmailConfirmation({
    mutationConfig: {
      onSuccess: () => {
        toastClient.success('Email has been sent successfully!');
      },
    },
  });

  return (
    <div>
      <Form
        onSubmit={(values) => {
          resendConfirmationEmail.mutate(values);
        }}
        schema={resendEmailConfirmationInputSchema}
      >
        {({ register, formState }) => (
          <>
            <Input
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div>
              <Button>Resend confirmation email</Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ResendEmailConfirmationForm;
