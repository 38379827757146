export const youtubeFormatUrl = (youtubeUrl: string): string | null => {
  try {
    const url = new URL(youtubeUrl);

    if (url.hostname === 'www.youtube.com' && url.pathname === '/watch') {
      const videoId = url.searchParams.get('v');
      return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    }

    if (url.hostname === 'youtu.be') {
      const videoId = url.pathname.substring(1);
      return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    }

    if (url.hostname === 'www.youtube.com' && url.pathname.startsWith('/embed/')) {
      return youtubeUrl;
    }

    return null;
  } catch {
    return null;
  }
};
