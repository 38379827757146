export const getCoordinatesFromPlacename = async (placeName: string) => {
  const API_KEY = process.env.GOOGLE_API_KEY;
  const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(placeName)}&key=${API_KEY}`;

  try {
    const response = await fetch(geocodingUrl);
    const data = await response.json();

    if (data.status === 'OK') {
      const location = data.results[0].geometry.location;
      const lat = location.lat;
      const lng = location.lng;
      return { lat, lng };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
