import { useState } from 'react';
import MailingIntegrationDialog from '../../../playground/components/mailing-integration/MailingIntegrationDialog';
import MailingIntegrationButton from './MailingIntegrationButton';

const MailingIntegration = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <MailingIntegrationButton toggleDialog={toggleDialog} />
      <MailingIntegrationDialog isOpen={isDialogOpen} toggleDialog={toggleDialog} />
    </>
  );
};

export default MailingIntegration;
