import usePlayground from '../../../playground/hooks/usePlayground.hook';
import { useState } from 'react';
import { isUserSubscribed } from '@/core/lib/cookie';
import { CloseIcon } from '@/assets/icons/close.icon';
import Modal from '@/core/design-system/Modal';
import NotSubscriberView from './views/not-subscriber-view';
import IsSubscriberView from './views/is-subscriber-view';
import SubscriberVerificationView from './views/subscriber-verification-view';

type SubscribeDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
  email: string;
  setEmail: (email: string) => void;
};

const SubscribeDialog = ({ isOpen, toggleDialog, email, setEmail }: SubscribeDialogProps) => {
  const { state } = usePlayground();
  const [showVerificationStep, setShowVerificationStep] = useState<boolean>(false);

  const isSubscriber = isUserSubscribed(state.payload.username!);

  const renderView = () => {
    if (showVerificationStep) {
      return (
        <SubscriberVerificationView
          email={email}
          toggleDialog={toggleDialog}
          setShowVerificationStep={setShowVerificationStep}
        />
      );
    }

    if (isSubscriber) {
      return <IsSubscriberView toggleDialog={toggleDialog} />;
    }

    return <NotSubscriberView setShowVerificationStep={setShowVerificationStep} email={email} setEmail={setEmail} />;
  };

  return (
    <Modal isOpen={isOpen} toggleDialog={toggleDialog} dialogPanelClassName="md:w-[410px]">
      <div className="z-10 p-6 text-center rounded-2xl bg-radial-gradient shadow-radial-shadow">
        <div onClick={toggleDialog} className="z-50 cursor-pointer absolute right-4 top-6">
          <CloseIcon />
        </div>

        {renderView()}
      </div>
    </Modal>
  );
};

export default SubscribeDialog;
