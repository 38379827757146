const PostSkeleton = () => {
  return (
    <div className="w-full bg-dark animate-pulse">
      <div className="absolute top-4 left-4 bg-surface-dark w-8 h-8 rounded-full" />
      <div className="rounded-[10px] bg-secondary w-full h-32 mb-3" />
      <p className="rounded-md h-4 w-1/2 bg-secondary mb-2" />
      <div className="rounded-md h-16 bg-secondary" />
    </div>
  );
};

export default PostSkeleton;
