import { cn } from '@/core/utils/cn';

type EnterKBDProps = {
  color?: 'primary' | 'secondary';
  text?: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export const EnterKBD = ({
  color = 'secondary',
  text = '↵ Enter',
  disabled = false,
  className = '',
  onClick = () => {},
}: EnterKBDProps) => {
  return (
    <div
      className={cn(
        'absolute inset-y-0 right-0 flex py-1 pr-1 cursor-pointer',
        { 'pointer-events-none': disabled },
        className,
      )}
    >
      <kbd
        className={cn(
          'inline-flex items-center rounded-[5px] p-3 text-sm text-lighter font-bold hover:bg-primary-dark transition-all duration-200 ease-in-out',
          `bg-${disabled ? 'secondary' : color}`,
        )}
        onClick={onClick}
      >
        {text}
      </kbd>
    </div>
  );
};
