export const extractPlaceNameFromIframe = async (iframeSrc: string) => {
  const regex = /!2s([^!&]+)/;
  const htmlEmbedSrc = iframeSrc.match(regex);

  if (htmlEmbedSrc && htmlEmbedSrc[1]) {
    return decodeURIComponent(htmlEmbedSrc[1]) || null;
  }

  const urlEmbedSrc = iframeSrc.match(/src="([^"]+)"/);
  if (!urlEmbedSrc) return null;

  const urlParams = new URL(urlEmbedSrc[1]).searchParams;
  const place = urlParams.get('q');
  return place ? decodeURIComponent(place.replace(/\+/g, ' ')) : null;
};
