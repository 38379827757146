import Cookies from 'js-cookie';

export const getCookieKeyByValue = (cookieValue: string) => {
  const cookies = Cookies.get();
  for (const cookieName in cookies) {
    if (cookies[cookieName] === cookieValue) {
      return cookieName;
    }
  }
  return null;
};

export const isUserSubscribed = (username: string) => {
  const allCookies = Cookies.get();

  const userSubCookies = Object.keys(allCookies)
    .filter((cookieName) => cookieName.startsWith('user_subscription-'))
    .reduce((result: { [key: string]: string }, cookieName) => {
      result[cookieName] = allCookies[cookieName];
      return result;
    }, {});

  const isSubscriber = Object.keys(userSubCookies).some((cookieName) => {
    const cookieUrl = userSubCookies[cookieName];
    const urlPart = cookieUrl.replace(`${process.env.CLIENT_URL}/` || '', '');
    return urlPart === username;
  });

  return isSubscriber;
};
