import { ENUM_EMBED_HOSTNAME } from '@/modules/playground/enums/embed-hostname.enum';

export const embedElementStyleOverride = (html: string): string => {
  const removeStylesAndWidth = (html: string) => html.replace(/(style="[^"]*"|width="[^"]*")/g, '');

  if (html.includes(ENUM_EMBED_HOSTNAME.FACEBOOK)) {
    return removeStylesAndWidth(html).replace('<iframe', '<iframe style="background-color: #fff;" width="100%"');
  }

  if (html.includes(ENUM_EMBED_HOSTNAME.CALENDLY)) {
    return html.replace(/style="[^"]*"/g, '').replace('<div', '<div style="width:100%;min-width:320px;height:700px;"');
  }

  if (html.includes(ENUM_EMBED_HOSTNAME.PINTEREST)) {
    return html;
  }

  if (html.startsWith('<iframe')) {
    return removeStylesAndWidth(html).replace('<iframe', '<iframe style="border-radius:8px;" width="100%"');
  }

  return html;
};
