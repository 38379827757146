const LineSkeleton = () => {
  return (
    <div className="w-full px-2 flex justify-between items-center bg-dark animate-pulse">
      <div className="flex justify-start items-center">
        <div className="bg-secondary rounded-lg w-8 h-8 object-contain" />
        <div className="rounded-md ml-4 py-2 w-48 bg-secondary" />
      </div>
    </div>
  );
};

export default LineSkeleton;
