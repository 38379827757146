export const spotifyFormatUrl = (spotifyUrl: string): string | null => {
  try {
    const url = new URL(spotifyUrl);

    if (url.hostname === 'open.spotify.com' && url.pathname.startsWith('/playlist/')) {
      return `https://open.spotify.com/embed${url.pathname}`;
    }

    if (url.hostname === 'open.spotify.com' && url.pathname.startsWith('/embed/')) {
      return spotifyUrl;
    }

    return null;
  } catch {
    return null;
  }
};
