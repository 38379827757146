import Axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import toastClient from './toast-client';

function requestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = 'application/json';
    config.withCredentials = true;
  }

  return config;
}

export const api = Axios.create({
  baseURL: process.env.API_HOST,
  timeout: 10000,
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    toastClient.error(message);

    if (error.response?.status === 401) {
      window.location.href = '/auth/login';
    }

    return Promise.reject(error);
  },
);
