import { useEffect, useState } from 'react';

const useShrink = (delay: number = 2000) => {
  const [isShrunk, setIsShrunk] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShrunk(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return isShrunk;
};

export default useShrink;
