import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { createSpreadsheet } from '../../api/mailing-integration/create-spreadsheet.api';

type UseCreateSpreadsheetOptions = {
  mutationConfig?: MutationConfig<typeof createSpreadsheet>;
};

const useCreateSpreadsheet = ({ mutationConfig }: UseCreateSpreadsheetOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: createSpreadsheet,
  });
};

export default useCreateSpreadsheet;
