import { SettingsIcon } from '@/assets/icons/settings.icon';

type SettingsButtonProps = {
  toggleDialog: () => void;
};

const SettingsButton = ({ toggleDialog }: SettingsButtonProps) => {
  return (
    <button
      type="button"
      onClick={toggleDialog}
      className="text-left flex items-center justify-between gap-x-2 mt-1 mb-2 py-2 px-3 rounded-lg w-full bg-transparent border border-secondary border-1 text-light font-bold text-sm hover:bg-dark transition-all duration-200 ease-in-out"
    >
      Settings
      <SettingsIcon />
    </button>
  );
};

export default SettingsButton;
