import Form from '@/core/components/form/Form';
import LinkieNotFound from './LinkieNotFound';
import LinkieCustomDomainError from './LinkieCustomDomainError';
import Profile from './profile';
import Cards from './cards';
import usePlayground from '../../hooks/usePlayground.hook';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';

const PlaygroundForm = () => {
  const { state, requestStatus } = usePlayground();

  if (window.location.host !== removeHttpProtocol(process.env.CLIENT_URL!) && requestStatus.isError) {
    return <LinkieCustomDomainError />;
  }

  if (!state.payload._id && requestStatus.isFetched) {
    return <LinkieNotFound />;
  }

  return (
    <Form
      onSubmit={() => {}}
      options={{
        values: state.payload,
      }}
    >
      {() => (
        <div className="px-[25px] md:px-[70px] py-[45px] flex flex-col items-center justify-center w-full">
          <Profile />
          <Cards />
        </div>
      )}
    </Form>
  );
};

export default PlaygroundForm;
