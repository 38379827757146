import { ENUM_EMBED_HOSTNAME } from '@/modules/playground/enums/embed-hostname.enum';
import { createYoutubeIframe } from './youtube/youtube-create-iframe';
import { createSpotifyIframe } from './spotify/spotify-create-iframe';
import { createFacebookIframe } from './facebook/facebook-create-iframe';
import { createCalendlyElement } from './calendly/calendly-create-element';
import { createCalElement } from './cal/cal.create-element';
import { createGoogleMapsIframe } from './google-maps/google-maps-create-iframe';

export const embedFromUrl = (value: string): string => {
  const url = new URL(value);
  const hostname = url.hostname;

  switch (hostname) {
    case ENUM_EMBED_HOSTNAME.FACEBOOK:
      return createFacebookIframe(value);
    case ENUM_EMBED_HOSTNAME.YOUTUBE:
    case ENUM_EMBED_HOSTNAME.YOUTU_BE:
      return createYoutubeIframe(value);
    case ENUM_EMBED_HOSTNAME.SPOTIFY:
      return createSpotifyIframe(value);
    case ENUM_EMBED_HOSTNAME.CALENDLY:
      return createCalendlyElement(value);
    case ENUM_EMBED_HOSTNAME.CAL:
      return createCalElement(value);
    case ENUM_EMBED_HOSTNAME.GOOGLE_MAPS:
      return createGoogleMapsIframe(value);
    default:
      return `<iframe style="border-radius:8px;" width="100%" height="300" src="${value}" title="Linkie embed" frameborder="0"></iframe>`;
  }
};
