export const facebookFormatUrl = (facebookUrl: string): string | null => {
  try {
    const url = new URL(facebookUrl);

    if (url.hostname === 'www.facebook.com' && url.pathname.includes('/posts/')) {
      return `https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(facebookUrl)}&show_text=true&width=500`;
    }

    return null;
  } catch {
    return null;
  }
};
