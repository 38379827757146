import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useFormContext } from 'react-hook-form';
import { cn } from '@/core/utils/cn';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import Button from '@/core/design-system/Button';
import Input from '@/core/components/form/fields/Input';
import { useCardPreview } from '@/modules/playground/hooks/useCardPreview.hook';

const LinkPreviewCTAButton = () => {
  const { state, dispatch } = usePlayground();
  const { getCardIndex, handleInputChange } = useCardPreview();
  const { register } = useFormContext();

  const hasButton = state.payload.cards?.[getCardIndex()]?.link_details?.cta_label ?? false;

  const getContainerClassname = () => {
    if (!hasButton && state.mode === PlaygroundMode.PREVIEW) {
      return 'invisible opacity-0';
    }

    if (!hasButton && state.mode === PlaygroundMode.EDITOR) {
      return 'opacity-0 invisible group-hover/card:opacity-100 group-hover/card:visible';
    }

    return 'bg-primary';
  };

  return (
    <Button
      type="button"
      className={cn(
        'bg-transparent hover:text-lighter px-0 py-0 w-fit mt-1 hover:border-transparent',
        getContainerClassname(),
      )}
    >
      <Input
        placeholder="Add Button"
        registration={register(`cards.${getCardIndex()}.link_details.cta_label`)}
        className={cn(
          'text-center outline-none font-bold font-sm py-2 border-secondary border border-solid px-3 w-full text-surface hover:bg-transparent hover:border-transparent hover:text-lighter focus:text-lighter focus:bg-primary focus:border-transparent',
          hasButton && 'text-lighter border-transparent',
        )}
        onChange={handleInputChange(PlaygroundActionType.SET_CARD_PAYLOAD)}
        onFocus={() => dispatch({ type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE, payload: false })}
        onBlur={() => dispatch({ type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE, payload: true })}
      />
    </Button>
  );
};

export default LinkPreviewCTAButton;
