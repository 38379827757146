import { DefaultLayoutIcon } from '@/assets/icons/layouts/default-layout.icon';
import { LineLayoutIcon } from '@/assets/icons/layouts/line-layout.icon';
import { IconLayoutIcon } from '@/assets/icons/layouts/icon-layout.icon';
import { SmallLayoutIcon } from '@/assets/icons/layouts/small-layout.icon';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';
import CardActions, { CardActionItem } from '../../components/CardActions';

const LayoutButtons = ({ linkId }: { linkId: string }) => {
  const { dispatch } = usePlayground();

  const handleLayoutChange = (layout: string) => {
    dispatch({
      type: PlaygroundActionType.SET_CARD_PAYLOAD,
      payload: {
        [`cards.${linkId}.link_details.layout`]: layout,
        cardId: linkId,
      },
    });
  };

  return (
    <CardActions>
      <CardActionItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.ICON)}>
        <IconLayoutIcon />
      </CardActionItem>
      <CardActionItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.LINE)}>
        <LineLayoutIcon />
      </CardActionItem>
      <CardActionItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.SMALL)}>
        <SmallLayoutIcon />
      </CardActionItem>
      <CardActionItem onClick={() => handleLayoutChange(ENUM_LINK_LAYOUT.DEFAULT)}>
        <DefaultLayoutIcon />
      </CardActionItem>
    </CardActions>
  );
};

export default LayoutButtons;
