import LinkPreviewFavicon from './components/Favicon';
import { useCardPreview } from '@/modules/playground/hooks/useCardPreview.hook';

const IconLayout = () => {
  const { card } = useCardPreview();

  return (
    <div className="w-full flex justify-center items-center">
      <LinkPreviewFavicon link={card} />
    </div>
  );
};

export default IconLayout;
