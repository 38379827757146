import Button from '@/core/design-system/Button';
import { extractPlaceNameFromIframe } from '@/core/utils/map/extract-place-name-from-iframe';
import { getCoordinatesFromPlacename } from '@/core/utils/map/get-coordinates-from-placename';
import { openDirectionsToPlace } from '@/core/utils/map/open-directions-to-place';
import toastClient from '@/core/lib/toast-client';

type GetDirectionsButtonProps = {
  iframe: string;
};

const GetDirectionsButton = ({ iframe }: GetDirectionsButtonProps) => {
  const getDirectionsHandler = async () => {
    const placeName = await extractPlaceNameFromIframe(iframe);
    if (!placeName) return null;

    const coords = await getCoordinatesFromPlacename(placeName);

    if (coords) {
      openDirectionsToPlace(coords);
    } else {
      toastClient.error('Could not get coordinates for this place.');
    }
  };

  return (
    <Button
      type="button"
      variant="filled"
      color="primary"
      className="w-fit absolute bottom-7 left-20"
      onClick={getDirectionsHandler}
    >
      Get Directions
    </Button>
  );
};

export default GetDirectionsButton;
