import toastClient from '@/core/lib/toast-client';

export const openDirectionsToPlace = async (coordinates: { lat: number; lng: number }) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;

        const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${coordinates.lat},${coordinates.lng}&travelmode=driving`;
        window.open(directionsUrl, '_blank');
      },

      function () {
        const placeUrl = `https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`;
        window.open(placeUrl, '_blank');
      },
    );
  } else {
    toastClient.error('Geolocation is not supported by this browser.');
  }
};
