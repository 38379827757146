import { AddLinkIcon } from '@/assets/icons/cards/add-link.icon';
import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import { cn } from '@/core/utils/cn';
import { useCards } from '@/modules/playground/hooks/useCards.hook';

const AddLinkView = () => {
  const { handleLinkDetailsInputChange, linkDetails } = useCards();

  return (
    <div className="w-full md:w-[80%] mx-auto flex flex-col items-center">
      <div className="relative">
        <AddLinkIcon />

        <div className="bg-gradient-to-b from-transparent to-main w-full h-28 absolute bottom-0 left-0 " />
      </div>

      <h1 className="text-lighter text-3xl font-bold my-4">Paste Website URL</h1>
      <p className="text-light text-sm mb-4">Paste any website link</p>

      <div className="relative flex items-center w-full h-auto">
        <input
          name="url"
          type="text"
          placeholder="Paste URL"
          className={cn(
            'block w-full h-10 rounded-lg border border-solid border-secondary py-[14px] px-2 text-white bg-surface-dark focus:bg-transparent focus:outline-none focus:border-primary transition-colors duration-200 ease-in-out',
            !linkDetails.url ? 'pr-20' : '',
          )}
          onChange={handleLinkDetailsInputChange}
        />
        {!linkDetails.url ? (
          <>
            <EnterKBD color="primary" text="V" disabled />
            <EnterKBD color="primary" text="⌘" disabled className="right-9" />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AddLinkView;
