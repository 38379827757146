import { useCardPreview } from '@/modules/playground/hooks/useCardPreview.hook';
import LinkPreviewFavicon from './components/Favicon';
import LinkPreviewTitle from './components/Title';
import { ExternalLinkIcon } from '@/assets/icons/external-link.icon';

const LineLayout = () => {
  const { card } = useCardPreview();

  return (
    <div className="w-full px-2 flex justify-between items-center">
      <div className="flex justify-start items-center w-full">
        <LinkPreviewFavicon link={card} />
        <LinkPreviewTitle className="ml-4" rows={1} maxHeight="max-h-[2.4rem]" />
      </div>

      <div>
        <ExternalLinkIcon />
      </div>
    </div>
  );
};

export default LineLayout;
