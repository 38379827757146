import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useFormContext } from 'react-hook-form';
import Textarea from '@/core/components/form/fields/Textarea';
import { cn } from '@/core/utils/cn';
import { useCardPreview } from '@/modules/playground/hooks/useCardPreview.hook';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';

type LinkPreviewTitleProps = {
  className?: string;
  rows?: number;
  maxHeight?: string;
};

const LinkPreviewTitle = ({ className, rows = 2, maxHeight = 'max-h-[3.9rem]' }: LinkPreviewTitleProps) => {
  const { getCardIndex, handleInputChange } = useCardPreview();
  const { dispatch } = usePlayground();
  const { register } = useFormContext();

  return (
    <div className={cn('text-sm mt-2 mb-1 w-full text-left', className)}>
      <Textarea
        fieldName="title"
        registration={register(`cards.${getCardIndex()}.link_preview_details.title`)}
        placeholder="Title"
        className={cn(
          'leading-6 text-lighter text-left border-0 outline-none w-full pl-0 hover:bg-secondary hover:pl-3 focus:bg-secondary focus:pl-3',
          maxHeight,
        )}
        rows={rows}
        onChange={handleInputChange(PlaygroundActionType.SET_CARD_PAYLOAD)}
        onFocus={() => dispatch({ type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE, payload: false })}
        onBlur={() => dispatch({ type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE, payload: true })}
      />
    </div>
  );
};

export default LinkPreviewTitle;
