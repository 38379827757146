import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import useUnsubscribe from '@/modules/link-in-bio/hooks/subscribe/useUnsubscribe.hook';
import { getCookieKeyByValue } from '@/core/lib/cookie';
import DefaultAvatarAsset from '@/assets/images/default-avatar.png';

type IsSubscriberViewProps = {
  toggleDialog: () => void;
};

const IsSubscriberView = ({ toggleDialog }: IsSubscriberViewProps) => {
  const { state } = usePlayground();

  const unsubscribe = useUnsubscribe({
    mutationConfig: {
      onSuccess: () => {
        toggleDialog();
      },
    },
  });

  const unsubscribeHandler = () => {
    const subscriberEmail = getCookieKeyByValue(`${process.env.CLIENT_URL}/${state.payload.username}`);
    if (!subscriberEmail) return;

    unsubscribe.mutate({
      linkInBioId: state.payload._id!,
      email: decodeURIComponent(subscriberEmail.replace('user_subscription-', '')),
    });
  };

  return (
    <>
      <div className="relative">
        <img
          src={state.payload.profile_image_url ?? DefaultAvatarAsset}
          alt="Profile Image"
          className="mx-auto w-[140px] h-[140px] rounded-full shadow-profile-image-shadow"
        />

        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 trans w-fit text-xs rounded-3xl flex justify-center items-center py-1 px-2 text-lighter bg-profile-gradient shadow-profile-shadow">
          {state.payload.name}
        </div>
      </div>

      <div className="py-4">
        <h1 className="text-lighter text-2xl font-bold mb-2">Unsubscribe? </h1>
        <p className="text-light text-base">
          You won't be notified when <strong className="text-lighter">{state.payload.username}</strong> shares something
          important.
        </p>
      </div>

      <button
        className="mb-2 rounded-lg py-2 px-4 text-lighter text-sm font-bold w-full bg-emerald hover:bg-emerald-light transition-all duration-200 ease-in-out"
        onClick={unsubscribeHandler}
      >
        Unsubscribe
      </button>

      <button
        className="rounded-lg py-2 px-4 text-lighter text-sm font-bold w-full bg-transparent border border-solid border-emerald hover:bg-emerald transition-all duration-200 ease-in-out"
        onClick={toggleDialog}
      >
        Cancel
      </button>
    </>
  );
};

export default IsSubscriberView;
