import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { deintegrateMailing } from '../../api/mailing-integration/deintegrate-mailing';

type UseDeintegrateMailingOptions = {
  mutationConfig?: MutationConfig<typeof deintegrateMailing>;
};

const useDeintegrateMailing = ({ mutationConfig }: UseDeintegrateMailingOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: deintegrateMailing,
  });
};

export default useDeintegrateMailing;
