export const deepUpdate = <T extends Record<string, any>>(obj: T, pathArray: string[], value: any): T => {
  if (!pathArray.length) return obj;

  const [key, ...rest] = pathArray;

  return {
    ...obj,
    [key]: rest.length > 0 ? deepUpdate(obj[key] || {}, rest, value) : value,
  };
};
