export const AddEmbedIcon = () => (
  <svg width="163" height="132" viewBox="0 0 163 132" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2112_7566)">
      <path
        d="M5.23203 0.366667H157.765C158.835 0.366667 159.861 0.791606 160.617 1.548C161.374 2.3044 161.799 3.33029 161.799 4.4V9.9H1.1987V4.4C1.1987 3.33029 1.62364 2.3044 2.38003 1.548C3.13643 0.791606 4.16232 0.366667 5.23203 0.366667Z"
        fill="#2D3036"
        stroke="url(#paint0_linear_2112_7566)"
        strokeWidth="0.733333"
      />
      <path
        d="M157.764 0.734375H5.23112C3.20608 0.734375 1.56445 2.376 1.56445 4.40104V127.601C1.56445 129.626 3.20608 131.268 5.23112 131.268H157.764C159.79 131.268 161.431 129.626 161.431 127.601V4.40104C161.431 2.376 159.79 0.734375 157.764 0.734375Z"
        fill="#171818"
        stroke="url(#paint1_linear_2112_7566)"
      />
      <path
        d="M2.66602 10.2656H161.458"
        stroke="url(#paint2_linear_2112_7566)"
        strokeWidth="0.733333"
        strokeLinecap="square"
      />
      <path
        d="M8.53255 7.33464C9.54507 7.33464 10.3659 6.51382 10.3659 5.5013C10.3659 4.48878 9.54507 3.66797 8.53255 3.66797C7.52003 3.66797 6.69922 4.48878 6.69922 5.5013C6.69922 6.51382 7.52003 7.33464 8.53255 7.33464Z"
        fill="#3D3D3D"
      />
      <path
        d="M14.3978 7.33464C15.4103 7.33464 16.2311 6.51382 16.2311 5.5013C16.2311 4.48878 15.4103 3.66797 14.3978 3.66797C13.3853 3.66797 12.5645 4.48878 12.5645 5.5013C12.5645 6.51382 13.3853 7.33464 14.3978 7.33464Z"
        fill="#3D3D3D"
      />
      <path
        d="M20.265 7.33464C21.2775 7.33464 22.0983 6.51382 22.0983 5.5013C22.0983 4.48878 21.2775 3.66797 20.265 3.66797C19.2525 3.66797 18.4316 4.48878 18.4316 5.5013C18.4316 6.51382 19.2525 7.33464 20.265 7.33464Z"
        fill="#3D3D3D"
      />
      <path
        d="M148.601 27.5H12.9344C11.7193 27.5 10.7344 28.485 10.7344 29.7V109.633C10.7344 110.848 11.7193 111.833 12.9344 111.833H148.601C149.816 111.833 150.801 110.848 150.801 109.633V29.7C150.801 28.485 149.816 27.5 148.601 27.5Z"
        fill="#1B1D21"
        stroke="url(#paint3_linear_2112_7566)"
        strokeWidth="0.733333"
      />
      <g clipPath="url(#clip1_2112_7566)">
        <path
          d="M117.267 59H43.9333C42.3133 59 41 60.3133 41 61.9333V127.2C41 128.82 42.3133 130.133 43.9333 130.133H117.267C118.887 130.133 120.2 128.82 120.2 127.2V61.9333C120.2 60.3133 118.887 59 117.267 59Z"
          fill="#1B1D21"
          stroke="#454648"
        />
        <path
          d="M82.4335 71.8357C83.2435 71.8357 83.9001 71.179 83.9001 70.369C83.9001 69.559 83.2435 68.9023 82.4335 68.9023C81.6234 68.9023 80.9668 69.559 80.9668 70.369C80.9668 71.179 81.6234 71.8357 82.4335 71.8357Z"
          fill="#63D2E6"
        />
        <path
          d="M78.035 71.8357C78.845 71.8357 79.5017 71.179 79.5017 70.369C79.5017 69.559 78.845 68.9023 78.035 68.9023C77.225 68.9023 76.5684 69.559 76.5684 70.369C76.5684 71.179 77.225 71.8357 78.035 71.8357Z"
          fill="#2D3036"
        />
        <path
          d="M67.4009 68.9023H46.8676C46.2601 68.9023 45.7676 69.3948 45.7676 70.0023C45.7676 70.6099 46.2601 71.1023 46.8676 71.1023H67.4009C68.0084 71.1023 68.5009 70.6099 68.5009 70.0023C68.5009 69.3948 68.0084 68.9023 67.4009 68.9023Z"
          fill="#2D3036"
        />
        <path
          d="M58.9667 101.898C59.7767 101.898 60.4333 101.242 60.4333 100.432C60.4333 99.6215 59.7767 98.9648 58.9667 98.9648C58.1566 98.9648 57.5 99.6215 57.5 100.432C57.5 101.242 58.1566 101.898 58.9667 101.898Z"
          fill="#63D2E6"
        />
        <path
          d="M64.8339 101.898C65.6439 101.898 66.3005 101.242 66.3005 100.432C66.3005 99.6215 65.6439 98.9648 64.8339 98.9648C64.0238 98.9648 63.3672 99.6215 63.3672 100.432C63.3672 101.242 64.0238 101.898 64.8339 101.898Z"
          fill="#63D2E6"
        />
        <path
          d="M70.701 101.898C71.5111 101.898 72.1677 101.242 72.1677 100.432C72.1677 99.6215 71.5111 98.9648 70.701 98.9648C69.891 98.9648 69.2344 99.6215 69.2344 100.432C69.2344 101.242 69.891 101.898 70.701 101.898Z"
          fill="#63D2E6"
        />
        <path
          d="M76.5682 101.898C77.3782 101.898 78.0349 101.242 78.0349 100.432C78.0349 99.6215 77.3782 98.9648 76.5682 98.9648C75.7582 98.9648 75.1016 99.6215 75.1016 100.432C75.1016 101.242 75.7582 101.898 76.5682 101.898Z"
          fill="#63D2E6"
        />
        <path
          d="M82.4335 101.898C83.2435 101.898 83.9001 101.242 83.9001 100.432C83.9001 99.6215 83.2435 98.9648 82.4335 98.9648C81.6234 98.9648 80.9668 99.6215 80.9668 100.432C80.9668 101.242 81.6234 101.898 82.4335 101.898Z"
          fill="#2D3036"
        />
        <path
          d="M47.2342 101.898C48.0443 101.898 48.7009 101.242 48.7009 100.432C48.7009 99.6215 48.0443 98.9648 47.2342 98.9648C46.4242 98.9648 45.7676 99.6215 45.7676 100.432C45.7676 101.242 46.4242 101.898 47.2342 101.898Z"
          fill="#2D3036"
        />
        <path
          d="M53.1014 101.898C53.9114 101.898 54.5681 101.242 54.5681 100.432C54.5681 99.6215 53.9114 98.9648 53.1014 98.9648C52.2914 98.9648 51.6348 99.6215 51.6348 100.432C51.6348 101.242 52.2914 101.898 53.1014 101.898Z"
          fill="#63D2E6"
        />
        <path
          d="M58.9667 108.5C59.7767 108.5 60.4333 107.843 60.4333 107.033C60.4333 106.223 59.7767 105.566 58.9667 105.566C58.1566 105.566 57.5 106.223 57.5 107.033C57.5 107.843 58.1566 108.5 58.9667 108.5Z"
          fill="#63D2E6"
        />
        <path
          d="M64.8339 108.5C65.6439 108.5 66.3005 107.843 66.3005 107.033C66.3005 106.223 65.6439 105.566 64.8339 105.566C64.0238 105.566 63.3672 106.223 63.3672 107.033C63.3672 107.843 64.0238 108.5 64.8339 108.5Z"
          fill="#63D2E6"
        />
        <path
          d="M70.701 108.5C71.5111 108.5 72.1677 107.843 72.1677 107.033C72.1677 106.223 71.5111 105.566 70.701 105.566C69.891 105.566 69.2344 106.223 69.2344 107.033C69.2344 107.843 69.891 108.5 70.701 108.5Z"
          fill="#63D2E6"
        />
        <path
          d="M47.2342 108.5C48.0443 108.5 48.7009 107.843 48.7009 107.033C48.7009 106.223 48.0443 105.566 47.2342 105.566C46.4242 105.566 45.7676 106.223 45.7676 107.033C45.7676 107.843 46.4242 108.5 47.2342 108.5Z"
          fill="#2D3036"
        />
        <path
          d="M53.1014 108.5C53.9114 108.5 54.5681 107.843 54.5681 107.033C54.5681 106.223 53.9114 105.566 53.1014 105.566C52.2914 105.566 51.6348 106.223 51.6348 107.033C51.6348 107.843 52.2914 108.5 53.1014 108.5Z"
          fill="#63D2E6"
        />
        <path
          d="M47.6009 74.0312H46.8676C46.2601 74.0312 45.7676 74.5237 45.7676 75.1313C45.7676 75.7388 46.2601 76.2313 46.8676 76.2313H47.6009C48.2084 76.2313 48.7009 75.7388 48.7009 75.1313C48.7009 74.5237 48.2084 74.0312 47.6009 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M53.4681 74.0312H52.7348C52.1273 74.0312 51.6348 74.5237 51.6348 75.1313C51.6348 75.7388 52.1273 76.2313 52.7348 76.2313H53.4681C54.0756 76.2313 54.5681 75.7388 54.5681 75.1313C54.5681 74.5237 54.0756 74.0312 53.4681 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M59.3333 74.0312H58.6C57.9925 74.0312 57.5 74.5237 57.5 75.1313C57.5 75.7388 57.9925 76.2313 58.6 76.2313H59.3333C59.9408 76.2313 60.4333 75.7388 60.4333 75.1313C60.4333 74.5237 59.9408 74.0312 59.3333 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M65.2005 74.0312H64.4672C63.8597 74.0312 63.3672 74.5237 63.3672 75.1313C63.3672 75.7388 63.8597 76.2313 64.4672 76.2313H65.2005C65.808 76.2313 66.3005 75.7388 66.3005 75.1313C66.3005 74.5237 65.808 74.0312 65.2005 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M71.0677 74.0312H70.3344C69.7269 74.0312 69.2344 74.5237 69.2344 75.1313C69.2344 75.7388 69.7269 76.2313 70.3344 76.2313H71.0677C71.6752 76.2313 72.1677 75.7388 72.1677 75.1313C72.1677 74.5237 71.6752 74.0312 71.0677 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M76.9349 74.0312H76.2016C75.594 74.0312 75.1016 74.5237 75.1016 75.1313C75.1016 75.7388 75.594 76.2313 76.2016 76.2313H76.9349C77.5424 76.2313 78.0349 75.7388 78.0349 75.1313C78.0349 74.5237 77.5424 74.0312 76.9349 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M82.8001 74.0312H82.0668C81.4593 74.0312 80.9668 74.5237 80.9668 75.1313C80.9668 75.7388 81.4593 76.2313 82.0668 76.2313H82.8001C83.4076 76.2313 83.9001 75.7388 83.9001 75.1313C83.9001 74.5237 83.4076 74.0312 82.8001 74.0312Z"
          fill="#2D3036"
        />
        <path
          d="M58.9667 95.3005C59.7767 95.3005 60.4333 94.6439 60.4333 93.8339C60.4333 93.0238 59.7767 92.3672 58.9667 92.3672C58.1566 92.3672 57.5 93.0238 57.5 93.8339C57.5 94.6439 58.1566 95.3005 58.9667 95.3005Z"
          fill="#63D2E6"
        />
        <path
          d="M64.8339 95.3005C65.6439 95.3005 66.3005 94.6439 66.3005 93.8339C66.3005 93.0238 65.6439 92.3672 64.8339 92.3672C64.0238 92.3672 63.3672 93.0238 63.3672 93.8339C63.3672 94.6439 64.0238 95.3005 64.8339 95.3005Z"
          fill="#63D2E6"
        />
        <path
          d="M70.701 95.3005C71.5111 95.3005 72.1677 94.6439 72.1677 93.8339C72.1677 93.0238 71.5111 92.3672 70.701 92.3672C69.891 92.3672 69.2344 93.0238 69.2344 93.8339C69.2344 94.6439 69.891 95.3005 70.701 95.3005Z"
          fill="#63D2E6"
        />
        <path
          d="M76.5682 95.3005C77.3782 95.3005 78.0349 94.6439 78.0349 93.8339C78.0349 93.0238 77.3782 92.3672 76.5682 92.3672C75.7582 92.3672 75.1016 93.0238 75.1016 93.8339C75.1016 94.6439 75.7582 95.3005 76.5682 95.3005Z"
          fill="#63D2E6"
        />
        <path
          d="M82.4335 95.3005C83.2435 95.3005 83.9001 94.6439 83.9001 93.8339C83.9001 93.0238 83.2435 92.3672 82.4335 92.3672C81.6234 92.3672 80.9668 93.0238 80.9668 93.8339C80.9668 94.6439 81.6234 95.3005 82.4335 95.3005Z"
          fill="#2D3036"
        />
        <path
          d="M47.2342 95.3005C48.0443 95.3005 48.7009 94.6439 48.7009 93.8339C48.7009 93.0238 48.0443 92.3672 47.2342 92.3672C46.4242 92.3672 45.7676 93.0238 45.7676 93.8339C45.7676 94.6439 46.4242 95.3005 47.2342 95.3005Z"
          fill="#2D3036"
        />
        <path
          d="M53.1014 95.3005C53.9114 95.3005 54.5681 94.6439 54.5681 93.8339C54.5681 93.0238 53.9114 92.3672 53.1014 92.3672C52.2914 92.3672 51.6348 93.0238 51.6348 93.8339C51.6348 94.6439 52.2914 95.3005 53.1014 95.3005Z"
          fill="#63D2E6"
        />
        <path
          d="M64.8339 82.0974C65.6439 82.0974 66.3005 81.4407 66.3005 80.6307C66.3005 79.8207 65.6439 79.1641 64.8339 79.1641C64.0238 79.1641 63.3672 79.8207 63.3672 80.6307C63.3672 81.4407 64.0238 82.0974 64.8339 82.0974Z"
          fill="#2D3036"
        />
        <path
          d="M70.701 82.0974C71.5111 82.0974 72.1677 81.4407 72.1677 80.6307C72.1677 79.8207 71.5111 79.1641 70.701 79.1641C69.891 79.1641 69.2344 79.8207 69.2344 80.6307C69.2344 81.4407 69.891 82.0974 70.701 82.0974Z"
          fill="#2D3036"
        />
        <path
          d="M76.5663 82.0974C77.3763 82.0974 78.0329 81.4407 78.0329 80.6307C78.0329 79.8207 77.3763 79.1641 76.5663 79.1641C75.7563 79.1641 75.0996 79.8207 75.0996 80.6307C75.0996 81.4407 75.7563 82.0974 76.5663 82.0974Z"
          fill="#2D3036"
        />
        <path
          d="M82.4335 82.0974C83.2435 82.0974 83.9001 81.4407 83.9001 80.6307C83.9001 79.8207 83.2435 79.1641 82.4335 79.1641C81.6234 79.1641 80.9668 79.8207 80.9668 80.6307C80.9668 81.4407 81.6234 82.0974 82.4335 82.0974Z"
          fill="#2D3036"
        />
        <path
          d="M58.9671 89.7974C60.3846 89.7974 61.5337 88.6483 61.5337 87.2307C61.5337 85.8132 60.3846 84.6641 58.9671 84.6641C57.5495 84.6641 56.4004 85.8132 56.4004 87.2307C56.4004 88.6483 57.5495 89.7974 58.9671 89.7974Z"
          fill="#63D2E6"
        />
        <path
          d="M64.8339 88.699C65.6439 88.699 66.3005 88.0423 66.3005 87.2323C66.3005 86.4223 65.6439 85.7656 64.8339 85.7656C64.0238 85.7656 63.3672 86.4223 63.3672 87.2323C63.3672 88.0423 64.0238 88.699 64.8339 88.699Z"
          fill="#63D2E6"
        />
        <path
          d="M70.701 88.699C71.5111 88.699 72.1677 88.0423 72.1677 87.2323C72.1677 86.4223 71.5111 85.7656 70.701 85.7656C69.891 85.7656 69.2344 86.4223 69.2344 87.2323C69.2344 88.0423 69.891 88.699 70.701 88.699Z"
          fill="#63D2E6"
        />
        <path
          d="M76.5682 88.699C77.3782 88.699 78.0349 88.0423 78.0349 87.2323C78.0349 86.4223 77.3782 85.7656 76.5682 85.7656C75.7582 85.7656 75.1016 86.4223 75.1016 87.2323C75.1016 88.0423 75.7582 88.699 76.5682 88.699Z"
          fill="#63D2E6"
        />
        <path
          d="M82.4335 88.699C83.2435 88.699 83.9001 88.0423 83.9001 87.2323C83.9001 86.4223 83.2435 85.7656 82.4335 85.7656C81.6234 85.7656 80.9668 86.4223 80.9668 87.2323C80.9668 88.0423 81.6234 88.699 82.4335 88.699Z"
          fill="#2D3036"
        />
        <path
          d="M47.2342 88.699C48.0443 88.699 48.7009 88.0423 48.7009 87.2323C48.7009 86.4223 48.0443 85.7656 47.2342 85.7656C46.4242 85.7656 45.7676 86.4223 45.7676 87.2323C45.7676 88.0423 46.4242 88.699 47.2342 88.699Z"
          fill="#2D3036"
        />
        <path
          d="M53.1014 88.699C53.9114 88.699 54.5681 88.0423 54.5681 87.2323C54.5681 86.4223 53.9114 85.7656 53.1014 85.7656C52.2914 85.7656 51.6348 86.4223 51.6348 87.2323C51.6348 88.0423 52.2914 88.699 53.1014 88.699Z"
          fill="#2D3036"
        />
        <path
          d="M115.8 74.3984H90.8671C90.057 74.3984 89.4004 75.0551 89.4004 75.8651V78.0651C89.4004 78.8751 90.057 79.5318 90.8671 79.5318H115.8C116.61 79.5318 117.267 78.8751 117.267 78.0651V75.8651C117.267 75.0551 116.61 74.3984 115.8 74.3984Z"
          fill="#1B1D21"
        />
        <path
          d="M106.633 68.8984H90.4999C89.6899 68.8984 89.0332 69.5551 89.0332 70.3651C89.0332 71.1751 89.6899 71.8318 90.4999 71.8318H106.633C107.443 71.8318 108.1 71.1751 108.1 70.3651C108.1 69.5551 107.443 68.8984 106.633 68.8984Z"
          fill="#2D3036"
        />
        <path
          d="M115.8 81.7344H90.8671C90.057 81.7344 89.4004 82.391 89.4004 83.201V85.401C89.4004 86.2111 90.057 86.8677 90.8671 86.8677H115.8C116.61 86.8677 117.267 86.2111 117.267 85.401V83.201C117.267 82.391 116.61 81.7344 115.8 81.7344Z"
          fill="#1B1D21"
        />
        <path
          d="M115.8 89.0664H90.8671C90.057 89.0664 89.4004 89.7231 89.4004 90.5331V92.7331C89.4004 93.5431 90.057 94.1997 90.8671 94.1997H115.8C116.61 94.1997 117.267 93.5431 117.267 92.7331V90.5331C117.267 89.7231 116.61 89.0664 115.8 89.0664Z"
          fill="#1B1D21"
        />
        <path
          d="M115.8 96.3984H90.8671C90.057 96.3984 89.4004 97.0551 89.4004 97.8651V100.065C89.4004 100.875 90.057 101.532 90.8671 101.532H115.8C116.61 101.532 117.267 100.875 117.267 100.065V97.8651C117.267 97.0551 116.61 96.3984 115.8 96.3984Z"
          fill="#1B1D21"
        />
        <path
          d="M115.8 103.734H90.8671C90.057 103.734 89.4004 104.391 89.4004 105.201V107.401C89.4004 108.211 90.057 108.868 90.8671 108.868H115.8C116.61 108.868 117.267 108.211 117.267 107.401V105.201C117.267 104.391 116.61 103.734 115.8 103.734Z"
          fill="#1B1D21"
        />
        <path
          d="M75.1009 63.0352H47.2342C46.4242 63.0352 45.7676 63.6918 45.7676 64.5018C45.7676 65.3118 46.4242 65.9685 47.2342 65.9685H75.1009C75.9109 65.9685 76.5676 65.3118 76.5676 64.5018C76.5676 63.6918 75.9109 63.0352 75.1009 63.0352Z"
          fill="#2D3036"
        />
      </g>
      <path
        d="M80.3 39.6C82.1225 39.6 83.6 38.1225 83.6 36.3C83.6 34.4775 82.1225 33 80.3 33C78.4775 33 77 34.4775 77 36.3C77 38.1225 78.4775 39.6 80.3 39.6Z"
        fill="#2D3036"
      />
      <path
        d="M95.2667 42.5352H65.9333C64.3133 42.5352 63 43.8485 63 45.4685C63 47.0885 64.3133 48.4018 65.9333 48.4018H95.2667C96.8867 48.4018 98.2 47.0885 98.2 45.4685C98.2 43.8485 96.8867 42.5352 95.2667 42.5352Z"
        fill="#2D3036"
      />
      <path
        d="M92.5667 51.332H69.1C68.4925 51.332 68 51.8245 68 52.432C68 53.0395 68.4925 53.532 69.1 53.532H92.5667C93.1742 53.532 93.6667 53.0395 93.6667 52.432C93.6667 51.8245 93.1742 51.332 92.5667 51.332Z"
        fill="#2D3036"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3652 10.6328H151.165V23.8328H10.3652V10.6328ZM10.3652 115.866H151.165V130.533H10.3652V115.866Z"
        fill="#34363C"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2112_7566"
        x1="81.4987"
        y1="0"
        x2="81.4987"
        y2="10.2667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#606060" />
        <stop offset="1" stopColor="#2F2F2F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2112_7566"
        x1="81.4978"
        y1="131.268"
        x2="81.4978"
        y2="0.734375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#171819" />
        <stop offset="1" stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2112_7566"
        x1="82.0622"
        y1="11.2656"
        x2="82.0622"
        y2="10.2656"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#171819" />
        <stop offset="1" stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2112_7566"
        x1="80.7677"
        y1="111.833"
        x2="80.7677"
        y2="27.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#171819" />
        <stop offset="1" stopColor="#4A4A4A" />
      </linearGradient>
      <clipPath id="clip0_2112_7566">
        <rect width="161.333" height="132" fill="white" transform="translate(0.833984)" />
      </clipPath>
      <clipPath id="clip1_2112_7566">
        <rect width="79" height="52" fill="white" transform="translate(41 59)" />
      </clipPath>
    </defs>
  </svg>
);
