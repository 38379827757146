import { ThumbnailDefaultIcon } from '@/assets/icons/thumbnail-default.icon';
import useLinkThumbnailUpload from '@/modules/playground/hooks/useLinkThumbnailUpload.hook';
import { CardDto } from '@link-in-bio/domain';
import { useState } from 'react';
import { CameraIcon } from '@/assets/icons/camera.icon';

type PostPreviewThumbnailProps = {
  post: CardDto;
};

const PostPreviewThumbnail = ({ post }: PostPreviewThumbnailProps) => {
  const {
    fileInputRef: mainFileInputRef,
    openExplorer: mainOpenExplorer,
    uploadImageHandler: mainUploadImageHandler,
  } = useLinkThumbnailUpload();

  const [hasImageError, setHasImageError] = useState<boolean>(false);

  const onImageError = () => {
    setHasImageError(true);
  };

  const renderThumbnail = () => {
    if (hasImageError) {
      return <ThumbnailDefaultIcon />;
    }

    if (post.link_preview_details.thumbnail_url) {
      return (
        <img
          src={post.link_preview_details.thumbnail_url}
          alt="Post Image"
          className="w-full h-full rounded-[10px] mb-3"
          onError={onImageError}
        />
      );
    }

    return <ThumbnailDefaultIcon />;
  };

  return (
    <>
      <div className="relative group/thumbnail cursor-pointer h-full w-full" onClick={mainOpenExplorer}>
        {renderThumbnail()}
        <div className="absolute inset-0 bg-black opacity-0 group-hover/thumbnail:opacity-50 rounded-lg transition-all duration-200"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white opacity-0 group-hover/thumbnail:opacity-100 transition-all duration-200">
          <CameraIcon />
        </div>
      </div>

      <input
        ref={mainFileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png,.webp"
        className="hidden"
        id="thumbnail"
        name="thumbnail"
        onChange={(e) => mainUploadImageHandler(e, post._id || post.client_id || '')}
      />
    </>
  );
};

export default PostPreviewThumbnail;
