import { AddEmbedIcon } from '@/assets/icons/cards/add-embed.icon';
import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import { cn } from '@/core/utils/cn';
import { useCards } from '@/modules/playground/hooks/useCards.hook';
import { Tab, TabGroup, TabList, TabPanel, TabPanels, Textarea } from '@headlessui/react';
import { motion } from 'framer-motion';

const AddEmbedView = () => {
  const { handleEmbedDetailsInputChange, embedDetails } = useCards();

  return (
    <div className="w-full md:w-[80%] mx-auto flex flex-col items-center">
      <div className="relative">
        <AddEmbedIcon />
        <div className="bg-gradient-to-b from-transparent to-main w-full h-28 absolute bottom-0 left-0 " />
      </div>

      <h1 className="text-lighter text-3xl font-bold my-4">Paste Embed URL</h1>
      <p className="text-light text-sm mb-4 text-center w-full md:w-[80%]">
        Share from YouTube, Spotify, Facebook, Calendly, Google Maps and more.
      </p>

      <TabGroup className="w-full">
        <TabList className="w-fit mx-auto flex p-1 gap-x-1 bg-dark rounded-lg">
          <Tab className="rounded-lg p-2 text-sm text-light outline-none data-[selected]:bg-main data-[hover]:bg-main data-[selected]:data-[hover]:bg-main data-[selected]:text-lighter data-[hover]:text-lighter transition-all duration-200 ease-in-out">
            HTML Code
          </Tab>
          <Tab className="rounded-lg p-2 text-sm text-light outline-none data-[selected]:bg-main data-[hover]:bg-main data-[selected]:data-[hover]:bg-main data-[selected]:text-lighter data-[hover]:text-lighter transition-all duration-200 ease-in-out">
            Paste URL
          </Tab>
        </TabList>
        <TabPanels className="mt-4">
          <TabPanel>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
            >
              <Textarea
                name="html"
                placeholder="Paste Embed Code"
                className="text-lighter outline-none block w-full resize-none rounded-lg border border-solid border-secondary bg-surface-dark p-4 text-sm focus:bg-transparent focus:outline-none focus:border-primary transition-all duration-200 ease-in-out"
                rows={8}
                onChange={handleEmbedDetailsInputChange}
                value={embedDetails.html}
              />
            </motion.div>
          </TabPanel>
          <TabPanel>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
              className="relative flex items-center w-full h-auto"
            >
              <input
                name="url"
                type="text"
                placeholder="Paste URL"
                className={cn(
                  'block w-full h-10 rounded-lg border border-solid border-secondary py-[14px] px-2 text-white bg-surface-dark focus:bg-transparent focus:outline-none focus:border-primary transition-colors duration-200 ease-in-out',
                  !embedDetails.url ? 'pr-20' : '',
                )}
                onChange={handleEmbedDetailsInputChange}
                value={embedDetails.url}
              />
              {!embedDetails.url ? (
                <>
                  <EnterKBD color="primary" text="V" disabled />
                  <EnterKBD color="primary" text="⌘" disabled className="right-9" />
                </>
              ) : null}
            </motion.div>
            <p className="text-light text-xs mt-2">Some websites don't support embeding.</p>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default AddEmbedView;
