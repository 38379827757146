export const GoogleSpreadsheetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
    <g clipPath="url(#clip0_1725_16977)">
      <mask
        id="mask0_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1725_16977)">
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L9.02909 2.6992L7.26292 0.226562Z"
          fill="#0F9D58"
        />
      </g>
      <mask
        id="mask1_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1725_16977)">
        <path
          d="M3.02441 7.82031V12.9422H8.67616V7.82031H3.02441ZM5.49705 12.2357H3.73088V11.3527H5.49705V12.2357ZM5.49705 10.8228H3.73088V9.93972H5.49705V10.8228ZM5.49705 9.40986H3.73088V8.52678H5.49705V9.40986ZM7.96969 12.2357H6.20352V11.3527H7.96969V12.2357ZM7.96969 10.8228H6.20352V9.93972H7.96969V10.8228ZM7.96969 9.40986H6.20352V8.52678H7.96969V9.40986Z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="mask2_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_1725_16977)">
        <path d="M7.57324 4.15625L11.5021 8.08421V4.46621L7.57324 4.15625Z" fill="url(#paint0_linear_1725_16977)" />
      </g>
      <mask
        id="mask3_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_1725_16977)">
        <path
          d="M7.2627 0.226562V3.40567C7.2627 3.99115 7.73691 4.46537 8.3224 4.46537H11.5015L7.2627 0.226562Z"
          fill="#87CEAC"
        />
      </g>
      <mask
        id="mask4_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_1725_16977)">
        <path
          d="M1.25794 0.226562C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V1.37457C0.198242 0.791737 0.675108 0.314871 1.25794 0.314871H7.26292V0.226562H1.25794Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask5_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_1725_16977)">
        <path
          d="M10.442 15.6808H1.25794C0.675108 15.6808 0.198242 15.2039 0.198242 14.6211V14.7094C0.198242 15.2922 0.675108 15.7691 1.25794 15.7691H10.442C11.0249 15.7691 11.5017 15.2922 11.5017 14.7094V14.6211C11.5017 15.2039 11.0249 15.6808 10.442 15.6808Z"
          fill="#263238"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask6_1725_16977"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="16"
      >
        <path
          d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_1725_16977)">
        <path
          d="M8.3224 4.46595C7.73691 4.46595 7.2627 3.99174 7.2627 3.40625V3.49456C7.2627 4.08004 7.73691 4.55426 8.3224 4.55426H11.5015V4.46595H8.3224Z"
          fill="#263238"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M7.26292 0.226562H1.25794C0.675108 0.226562 0.198242 0.703428 0.198242 1.28626V14.7091C0.198242 15.292 0.675108 15.7689 1.25794 15.7689H10.442C11.0249 15.7689 11.5017 15.292 11.5017 14.7091V4.46537L7.26292 0.226562Z"
        fill="url(#paint1_radial_1725_16977)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1725_16977"
        x1="204.037"
        y1="37.8821"
        x2="204.037"
        y2="397.007"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#263238" stopOpacity="0.2" />
        <stop offset="1" stopColor="#263238" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1725_16977"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.0082 30.945) scale(1822.67 1822.67)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_1725_16977">
        <rect width="11.7015" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
