const SmallSkeleton = () => {
  return (
    <div className="w-full p-2 bg-dark animate-pulse">
      <div className="text-left">
        <div className="bg-secondary rounded-lg w-8 h-8 object-contain" />
        <div className="rounded-md mt-4 py-2 bg-secondary" />
        <p className="mt-2 rounded-md h-3 w-1/2 bg-secondary" />
        <div className="rounded-lg bg-secondary w-[150px] mt-6 h-10" />
      </div>
    </div>
  );
};

export default SmallSkeleton;
