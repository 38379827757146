type CardOptionsItemProps = {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
};

const CardOptionsItem = ({ label, icon, onClick }: CardOptionsItemProps) => {
  return (
    <div
      className="w-full bg-transparent rounded-xl border border-secondary p-6 cursor-pointer hover:bg-secondary transition-all duration-200 ease-in-out"
      onClick={onClick}
    >
      {icon}
      <div className="text-lighter font-bold text-sm mt-3">{label}</div>
    </div>
  );
};

export default CardOptionsItem;
