export enum ENUM_EMBED_HOSTNAME {
  YOUTUBE = 'www.youtube.com',
  YOUTU_BE = 'youtu.be',
  FACEBOOK = 'www.facebook.com',
  INSTAGRAM = 'instagram.com',
  THREADS = 'threads.net',
  TIKTOK = 'tiktok.com',
  TWITTER = 'twitter.com',
  LINKEDIN = 'linkedin.com',
  PINTEREST = 'pinterest.com',
  BLUESKY = 'bsky.app',
  MASTODON = 'fediscience.org',
  REDDIT = 'reddit.com',
  CALENDLY = 'calendly.com',
  CAL = 'cal.com',
  SPOTIFY = 'open.spotify.com',
  GOOGLE_MAPS = 'www.google.com',
}
