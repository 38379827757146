export const AddPostIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={557} height={148} fill="none">
    <g opacity={0.72}>
      <rect width={59.167} height={59.167} x={98.917} y={12.417} fill="#171717" rx={9.583} />
      <rect width={59.167} height={59.167} x={98.917} y={12.417} stroke="url(#a)" strokeWidth={0.833} rx={9.583} />
      <g clipPath="url(#b)">
        <path
          fill="#007EBB"
          fillRule="evenodd"
          d="M128.501 58.665c9.204 0 16.666-7.462 16.666-16.666 0-9.205-7.462-16.667-16.666-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.204 7.462 16.666 16.667 16.666Z"
          clipRule="evenodd"
        />
        <circle cx={128.501} cy={41.999} r={16.146} stroke="#1B1D21" strokeWidth={1.042} />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M139.148 51.722h-4.186v-7.129c0-1.954-.742-3.047-2.289-3.047-1.683 0-2.563 1.137-2.563 3.047v7.129h-4.033v-13.58h4.033v1.829s1.213-2.244 4.095-2.244c2.88 0 4.943 1.759 4.943 5.397v8.598Zm-17.883-15.358a2.497 2.497 0 0 1-2.488-2.506 2.497 2.497 0 0 1 2.488-2.506 2.495 2.495 0 0 1 2.486 2.506 2.495 2.495 0 0 1-2.486 2.506Zm-2.083 15.358h4.206v-13.58h-4.206v13.58Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <rect width={67.056} height={67.056} x={166.972} y={4.472} fill="#171717" rx={10.861} />
    <rect width={67.056} height={67.056} x={166.972} y={4.472} stroke="url(#c)" strokeWidth={0.944} rx={10.861} />
    <g clipPath="url(#d)">
      <path
        fill="#000"
        d="M200.5 56.887c10.432 0 18.889-8.457 18.889-18.889 0-10.432-8.457-18.889-18.889-18.889-10.432 0-18.889 8.457-18.889 18.89 0 10.431 8.457 18.888 18.889 18.888Z"
      />
      <path
        fill="#fff"
        d="M206.03 29.363h2.929l-6.399 7.314 7.527 9.952h-5.894l-4.616-6.036-5.283 6.036h-2.93l6.844-7.823-7.222-9.443h6.044l4.173 5.517 4.827-5.517Zm-1.028 15.513h1.623l-10.477-13.852h-1.741l10.595 13.852Z"
      />
      <circle cx={200.5} cy={37.998} r={18.299} stroke="#1B1D21" strokeWidth={1.181} />
    </g>
    <rect width={71} height={71} x={243} y={0.5} fill="#171717" rx={11.5} />
    <rect width={71} height={71} x={243} y={0.5} stroke="url(#e)" rx={11.5} />
    <path
      fill="url(#f)"
      d="M275.164 55.78C265.722 54.115 258.5 45.89 258.5 36c0-11 9-20 20.001-20 11.001 0 19.999 9 19.999 20 0 9.89-7.222 18.113-16.667 19.78l-1.111-.886h-4.444l-1.114.885Z"
    />
    <path
      fill="#fff"
      d="m286.279 41.555.889-5.556h-5.333V32.11c0-1.556.555-2.779 2.999-2.779h2.556v-5.11c-1.445-.223-2.999-.444-4.446-.444-4.555 0-7.779 2.779-7.779 7.78V36h-4.997v5.556h4.997V55.67c2.2.445 4.467.445 6.666 0V41.56l4.448-.005Z"
    />
    <circle cx={278.5} cy={36} r={19.375} stroke="#1B1D21" strokeWidth={1.25} />
    <rect width={67.056} height={67.056} x={322.972} y={4.472} fill="#171717" rx={10.861} />
    <rect width={67.056} height={67.056} x={322.972} y={4.472} stroke="url(#g)" strokeWidth={0.944} rx={10.861} />
    <g clipPath="url(#h)">
      <path
        fill="url(#i)"
        d="M356.506 19.11c-7.886 0-10.192.008-10.64.045-1.619.134-2.626.39-3.723.935a7.547 7.547 0 0 0-2.17 1.59c-1.198 1.244-1.925 2.775-2.188 4.595-.128.884-.165 1.064-.172 5.576-.003 1.505 0 3.484 0 6.14 0 7.882.008 10.186.046 10.634.131 1.575.378 2.566.901 3.65.999 2.075 2.908 3.633 5.157 4.214.779.2 1.639.311 2.743.363.467.02 5.235.035 10.006.035s9.541-.006 9.998-.029c1.278-.06 2.02-.16 2.841-.372a7.91 7.91 0 0 0 5.157-4.223c.513-1.057.773-2.086.891-3.58.025-.325.036-5.514.036-10.697 0-5.184-.012-10.364-.037-10.69-.119-1.516-.379-2.536-.909-3.615a7.482 7.482 0 0 0-1.616-2.215c-1.25-1.194-2.778-1.92-4.599-2.183-.883-.128-1.059-.166-5.574-.174h-6.148Z"
      />
      <path
        fill="url(#j)"
        d="M356.506 19.11c-7.886 0-10.192.008-10.64.045-1.619.134-2.626.39-3.723.935a7.547 7.547 0 0 0-2.17 1.59c-1.198 1.244-1.925 2.775-2.188 4.595-.128.884-.165 1.064-.172 5.576-.003 1.505 0 3.484 0 6.14 0 7.882.008 10.186.046 10.634.131 1.575.378 2.566.901 3.65.999 2.075 2.908 3.633 5.157 4.214.779.2 1.639.311 2.743.363.467.02 5.235.035 10.006.035s9.541-.006 9.998-.029c1.278-.06 2.02-.16 2.841-.372a7.91 7.91 0 0 0 5.157-4.223c.513-1.057.773-2.086.891-3.58.025-.325.036-5.514.036-10.697 0-5.184-.012-10.364-.037-10.69-.119-1.516-.379-2.536-.909-3.615a7.482 7.482 0 0 0-1.616-2.215c-1.25-1.194-2.778-1.92-4.599-2.183-.883-.128-1.059-.166-5.574-.174h-6.148Z"
      />
      <path
        fill="url(#k)"
        d="M356.506 19.11c-7.886 0-10.192.008-10.64.045-1.619.134-2.626.39-3.723.935a7.547 7.547 0 0 0-2.17 1.59c-1.198 1.244-1.925 2.775-2.188 4.595-.128.884-.165 1.064-.172 5.576-.003 1.505 0 3.484 0 6.14 0 7.882.008 10.186.046 10.634.131 1.575.378 2.566.901 3.65.999 2.075 2.908 3.633 5.157 4.214.779.2 1.639.311 2.743.363.467.02 5.235.035 10.006.035s9.541-.006 9.998-.029c1.278-.06 2.02-.16 2.841-.372a7.91 7.91 0 0 0 5.157-4.223c.513-1.057.773-2.086.891-3.58.025-.325.036-5.514.036-10.697 0-5.184-.012-10.364-.037-10.69-.119-1.516-.379-2.536-.909-3.615a7.482 7.482 0 0 0-1.616-2.215c-1.25-1.194-2.778-1.92-4.599-2.183-.883-.128-1.059-.166-5.574-.174h-6.148Z"
      />
      <path
        fill="url(#l)"
        d="M356.506 19.11c-7.886 0-10.192.008-10.64.045-1.619.134-2.626.39-3.723.935a7.547 7.547 0 0 0-2.17 1.59c-1.198 1.244-1.925 2.775-2.188 4.595-.128.884-.165 1.064-.172 5.576-.003 1.505 0 3.484 0 6.14 0 7.882.008 10.186.046 10.634.131 1.575.378 2.566.901 3.65.999 2.075 2.908 3.633 5.157 4.214.779.2 1.639.311 2.743.363.467.02 5.235.035 10.006.035s9.541-.006 9.998-.029c1.278-.06 2.02-.16 2.841-.372a7.91 7.91 0 0 0 5.157-4.223c.513-1.057.773-2.086.891-3.58.025-.325.036-5.514.036-10.697 0-5.184-.012-10.364-.037-10.69-.119-1.516-.379-2.536-.909-3.615a7.482 7.482 0 0 0-1.616-2.215c-1.25-1.194-2.778-1.92-4.599-2.183-.883-.128-1.059-.166-5.574-.174h-6.148Z"
      />
      <circle cx={356.5} cy={37.998} r={18.299} stroke="#1B1D21" strokeWidth={1.181} />
      <path
        fill="#fff"
        d="M356.501 27.375c-2.886 0-3.248.013-4.381.064-1.131.052-1.903.231-2.579.494a5.196 5.196 0 0 0-1.882 1.225 5.212 5.212 0 0 0-1.226 1.882c-.264.676-.443 1.448-.494 2.579-.051 1.133-.064 1.495-.064 4.381s.013 3.247.064 4.38c.052 1.131.231 1.903.494 2.579a5.195 5.195 0 0 0 1.225 1.882 5.205 5.205 0 0 0 1.882 1.226c.676.263 1.448.442 2.579.494 1.133.051 1.495.064 4.381.064 2.885 0 3.247-.013 4.38-.064 1.131-.052 1.904-.231 2.58-.494a5.193 5.193 0 0 0 1.881-1.226 5.212 5.212 0 0 0 1.226-1.882c.261-.676.441-1.448.494-2.579.051-1.133.064-1.494.064-4.38s-.013-3.248-.064-4.381c-.053-1.131-.233-1.903-.494-2.579a5.211 5.211 0 0 0-1.226-1.882 5.19 5.19 0 0 0-1.882-1.225c-.677-.263-1.45-.442-2.581-.494-1.133-.051-1.494-.064-4.38-.064h.003Zm-.953 1.915h.953c2.837 0 3.173.01 4.293.06 1.036.048 1.599.221 1.973.367.496.192.85.423 1.221.794.372.372.602.726.795 1.222.146.374.319.937.366 1.973.051 1.12.062 1.456.062 4.292 0 2.835-.011 3.172-.062 4.292-.047 1.036-.22 1.598-.366 1.972a3.276 3.276 0 0 1-.795 1.22 3.287 3.287 0 0 1-1.221.795c-.374.146-.937.319-1.973.366-1.12.051-1.456.062-4.293.062s-3.173-.01-4.293-.062c-1.036-.048-1.599-.22-1.973-.366a3.291 3.291 0 0 1-1.222-.795 3.29 3.29 0 0 1-.795-1.221c-.146-.374-.319-.936-.366-1.972-.051-1.12-.061-1.457-.061-4.294 0-2.837.01-3.172.061-4.292.047-1.036.22-1.598.366-1.973.192-.496.423-.85.795-1.222a3.297 3.297 0 0 1 1.222-.795c.374-.146.937-.319 1.973-.366.98-.045 1.36-.058 3.34-.06v.003Zm6.625 1.764a1.275 1.275 0 1 0 0 2.55 1.275 1.275 0 0 0 0-2.55Zm-5.672 1.49a5.456 5.456 0 1 0 .001 10.913 5.456 5.456 0 0 0-.001-10.913Zm0 1.914a3.542 3.542 0 1 1 0 7.084 3.542 3.542 0 0 1 0-7.084Z"
      />
    </g>
    <g opacity={0.72}>
      <rect width={59.167} height={59.167} x={398.917} y={12.417} fill="#171717" rx={9.583} />
      <rect width={59.167} height={59.167} x={398.917} y={12.417} stroke="url(#m)" strokeWidth={0.833} rx={9.583} />
      <g clipPath="url(#n)">
        <path
          fill="#1A121F"
          fillRule="evenodd"
          d="M428.501 25.332c9.205 0 16.666 7.462 16.666 16.667 0 9.205-7.461 16.666-16.666 16.666S411.834 51.205 411.834 42s7.462-16.667 16.667-16.667Zm3.346 7.064c.034.288.082.566.146.834h.63c.117 1 .401 1.912.847 2.67.728.675 1.679 1.09 2.828 1.164v.92c.213.045.435.075.666.09v2.97c-1.485.145-2.784-.343-4.298-1.254l.232 5.085c0 1.64.006 2.389-.874 3.897-2.054 3.523-5.819 3.668-8.217 1.833-1.227-.426-2.307-1.247-2.989-2.361-1.987-3.252-.195-8.552 5.569-9.097l-.009.783v.197c.31-.064.636-.114.979-.147v3.132c-1.248.205-2.041.589-2.412 1.298-.742 1.42-.405 2.652.372 3.43 1.627.28 3.44-.848 3.167-4.335V32.396h3.363Z"
          clipRule="evenodd"
        />
        <circle cx={428.501} cy={41.999} r={16.146} stroke="#1B1D21" strokeWidth={1.042} />
        <path
          fill="#26F4EE"
          fillRule="evenodd"
          d="M431.838 32.395c.309 2.643 1.89 4.509 4.448 4.673v2.508l-.016.001v-2.13c-2.558-.164-4.029-1.74-4.338-4.385h-2.71v11.123c.391 5.006-3.502 5.155-4.992 3.257 1.747 1.094 4.584.383 4.248-3.925V32.394h3.36Zm-7.28 18.438c-1.531-.316-2.923-1.232-3.741-2.572-1.986-3.256-.196-8.562 5.565-9.108l-.009.785c-5.341.852-5.877 6.6-4.398 8.99.57.923 1.506 1.571 2.583 1.905Z"
          clipRule="evenodd"
        />
        <path
          fill="#FB2C53"
          fillRule="evenodd"
          d="M432.638 33.23c.176 1.504.73 2.813 1.648 3.691-1.289-.666-2.067-1.931-2.31-3.69h.662Zm3.664 4.752c.218.046.446.078.683.093v2.969c-1.487.146-2.788-.343-4.305-1.253l.233 5.084c0 1.64.006 2.39-.875 3.898-2.148 3.676-6.162 3.674-8.538 1.58 3.098 1.777 8.506.386 8.496-5.645v-5.55c1.517.911 2.818 1.4 4.306 1.254v-2.43Zm-9.92 2.146c.311-.065.638-.115.981-.147v3.131c-1.25.206-2.044.59-2.416 1.298-1.045 1.997.048 3.624 1.495 4.137-1.81-.065-3.607-1.922-2.359-4.303.371-.71 1.049-1.093 2.299-1.298v-2.818Z"
          clipRule="evenodd"
        />
        <path
          fill="#FEFEFE"
          fillRule="evenodd"
          d="M431.932 33.063c.309 2.64 1.78 4.215 4.338 4.38v2.968c-1.484.146-2.783-.342-4.295-1.253v5.55c.011 7.243-7.775 7.794-10 4.202-1.479-2.388-.942-8.129 4.398-8.98v3.014c-1.247.206-1.923.59-2.294 1.298-2.289 4.381 5.696 6.988 5.144-.07v-11.11h2.709Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <g opacity={0.72}>
      <rect width={67.056} height={67.056} x={54.972} y={80.472} fill="#171717" rx={10.861} />
      <rect width={67.056} height={67.056} x={54.972} y={80.472} stroke="url(#o)" strokeWidth={0.944} rx={10.861} />
      <g clipPath="url(#p)">
        <path
          fill="url(#q)"
          fillRule="evenodd"
          d="M88.5 95.11c10.425 0 18.889 8.452 18.889 18.888 0 10.425-8.453 18.889-18.889 18.889-10.424 0-18.889-8.453-18.889-18.889 0-10.424 8.453-18.889 18.89-18.889Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          d="M98.426 108.057c-.308-2.291-2.305-4.097-4.672-4.447-.4-.059-1.913-.274-5.418-.274h-.026c-3.506 0-4.258.215-4.657.274-2.301.34-4.403 1.963-4.913 4.281-.245 1.141-.271 2.407-.225 3.568.064 1.665.077 3.327.228 4.985.105 1.102.287 2.194.546 3.27.485 1.987 2.447 3.64 4.369 4.315 2.058.703 4.27.82 6.391.337.233-.054.463-.117.691-.189.516-.164 1.12-.347 1.564-.669a.043.043 0 0 0 .014-.017.052.052 0 0 0 .006-.021v-1.607a.05.05 0 0 0-.01-.03.047.047 0 0 0-.027-.016.074.074 0 0 0-.021 0c-1.359.325-2.75.487-4.148.485-2.404 0-3.05-1.141-3.235-1.616a4.996 4.996 0 0 1-.281-1.273c0-.008 0-.015.004-.022a.057.057 0 0 1 .013-.017.046.046 0 0 1 .02-.009.04.04 0 0 1 .02 0 17.36 17.36 0 0 0 4.08.485c.331 0 .66 0 .991-.009 1.382-.039 2.84-.109 4.2-.375.033-.007.067-.013.096-.021 2.145-.412 4.187-1.705 4.394-4.979.008-.129.027-1.35.027-1.483 0-.455.146-3.225-.021-4.926Zm-3.399 2.574v5.691h-2.255v-5.524c0-1.163-.485-1.756-1.47-1.756-1.084 0-1.627.702-1.627 2.088v3.023h-2.242v-3.023c0-1.386-.544-2.088-1.627-2.088-.98 0-1.47.593-1.47 1.756v5.524h-2.254v-5.691c0-1.163.297-2.087.89-2.772.613-.683 1.416-1.034 2.414-1.034 1.154 0 2.027.444 2.608 1.33l.561.942.563-.942c.581-.886 1.454-1.33 2.606-1.33.996 0 1.8.351 2.414 1.034.594.684.89 1.608.89 2.772Z"
        />
        <circle cx={88.5} cy={113.998} r={18.299} stroke="#1B1D21" strokeWidth={1.181} />
      </g>
    </g>
    <g opacity={0.72}>
      <rect width={67.056} height={67.056} x={130.972} y={80.472} fill="#171717" rx={10.861} />
      <rect width={67.056} height={67.056} x={130.972} y={80.472} stroke="url(#r)" strokeWidth={0.944} rx={10.861} />
      <path
        fill="#1185FE"
        d="M164.5 132.887c10.432 0 18.889-8.457 18.889-18.889 0-10.432-8.457-18.889-18.889-18.889-10.432 0-18.889 8.457-18.889 18.889 0 10.432 8.457 18.889 18.889 18.889Z"
      />
      <g clipPath="url(#s)">
        <path
          fill="#fff"
          d="M159.438 107.217c2.094 1.556 4.346 4.713 5.172 6.407.827-1.694 3.079-4.851 5.172-6.407 1.51-1.124 3.958-1.993 3.958.773 0 .552-.32 4.64-.507 5.303-.652 2.307-3.027 2.896-5.14 2.54 3.693.622 4.632 2.684 2.604 4.747-3.853 3.916-5.538-.983-5.97-2.238-.079-.231-.116-.338-.117-.247 0-.091-.037.016-.117.247-.431 1.255-2.116 6.154-5.969 2.238-2.029-2.063-1.09-4.125 2.603-4.747-2.113.356-4.488-.233-5.139-2.54-.188-.663-.508-4.751-.508-5.303 0-2.766 2.448-1.897 3.958-.773Z"
        />
      </g>
    </g>
    <rect width={67.056} height={67.056} x={206.972} y={80.472} fill="#171717" rx={10.861} />
    <rect width={67.056} height={67.056} x={206.972} y={80.472} stroke="url(#t)" strokeWidth={0.944} rx={10.861} />
    <g clipPath="url(#u)">
      <path
        fill="#CA2127"
        d="M258.834 113.946c0 10.158-8.237 18.396-18.395 18.396s-18.396-8.238-18.396-18.396c0-10.158 8.238-18.395 18.396-18.395 10.158 0 18.395 8.237 18.395 18.395Z"
      />
      <circle cx={240.5} cy={113.998} r={18.299} stroke="#1B1D21" strokeWidth={1.181} />
      <path
        fill="#fff"
        d="M238.894 101.707c-5.184.556-10.347 4.543-10.557 10.252-.137 3.484 1.322 6.097 4.806 6.831.399-1.647.472-2.539.472-2.539s-1.532-1.469-1.689-2.403c-1.281-7.514 9.098-12.645 14.544-7.398 3.757 3.641 1.281 14.838-4.785 13.673-5.814-1.112 2.844-10.021-1.794-11.774-3.768-1.427-5.772 4.355-3.988 7.22-1.049 4.932-3.306 9.581-2.393 15.762 2.97-2.047 3.967-5.971 4.786-10.064 1.49.86 2.287 1.763 4.187 1.899 7.009.515 10.924-6.663 9.969-13.295-.861-5.877-7.01-8.868-13.558-8.164Z"
      />
    </g>
    <rect width={67.056} height={67.056} x={282.972} y={80.472} fill="#171717" rx={10.861} />
    <rect width={67.056} height={67.056} x={282.972} y={80.472} stroke="url(#v)" strokeWidth={0.944} rx={10.861} />
    <g clipPath="url(#w)">
      <path
        fill="#000"
        d="M316.5 132.887c10.432 0 18.889-8.457 18.889-18.889 0-10.432-8.457-18.889-18.889-18.889-10.432 0-18.889 8.457-18.889 18.889 0 10.432 8.457 18.889 18.889 18.889Z"
      />
      <path
        fill="#fff"
        d="M322.158 113.548c3.135 1.33 4.676 4.513 3.653 7.809-.696 2.249-2.682 4.065-4.809 4.887-2.683 1.039-6.284.972-8.93-.134-5.365-2.242-6.618-8.467-6.084-13.646.37-3.624 1.867-7.159 5.172-8.975 2.49-1.375 5.677-1.569 8.404-.994 2.831.598 5.128 2.287 6.522 4.843a11.79 11.79 0 0 1 1.037 2.615c.008.038-.007.075-.044.082l-1.972.524c-.037.007-.074-.015-.081-.045-.297-1.136-.763-2.145-1.408-3.027-2.016-2.765-5.714-3.505-8.9-2.929-3.758.68-5.833 3.34-6.44 6.994-.497 2.967-.341 6.405 1.015 9.14 1.141 2.302 3.209 3.594 5.699 3.953a12.17 12.17 0 0 0 3.935-.075c1.838-.343 3.809-1.569 4.609-3.325.519-1.136.571-2.578 0-3.707a3.794 3.794 0 0 0-1.46-1.547c-.029-.022-.074-.007-.088.023-.008.007-.008.015-.008.022a8.263 8.263 0 0 1-.385 1.629c-.267.755-.652 1.405-1.164 1.936-1.719 1.786-4.713 1.943-6.736.657-1.616-1.031-2.223-3.138-1.453-4.872.801-1.793 2.757-2.571 4.617-2.66a15.302 15.302 0 0 1 2.92.119c.037.008.067-.022.074-.059v-.023a4.731 4.731 0 0 0-.296-1.009c-.623-1.382-2.023-1.748-3.424-1.569-.897.112-1.601.538-2.127 1.27-.022.03-.059.038-.089.015l-1.675-1.151c-.029-.022-.037-.059-.014-.097.896-1.27 2.112-2.01 3.653-2.212 1.275-.172 2.705.03 3.817.71 1.667 1.031 2.26 2.885 2.408 4.76.015.038.03.06.052.068Zm-7.996 2.929c-.334 1.129.437 2.018 1.482 2.287 1.467.374 3.098.045 3.794-1.472.274-.598.437-1.301.489-2.1 0-.038-.022-.067-.052-.067a11.379 11.379 0 0 0-3.349-.15c-.927.097-2.075.523-2.364 1.502Z"
      />
    </g>
    <g opacity={0.72}>
      <rect width={67.056} height={67.056} x={358.972} y={80.472} fill="#171717" rx={10.861} />
      <rect width={67.056} height={67.056} x={358.972} y={80.472} stroke="url(#x)" strokeWidth={0.944} rx={10.861} />
      <g clipPath="url(#y)">
        <path
          fill="url(#z)"
          d="M392.5 132.887c10.432 0 18.889-8.457 18.889-18.889 0-10.432-8.457-18.889-18.889-18.889-10.432 0-18.889 8.457-18.889 18.889 0 10.432 8.457 18.889 18.889 18.889Z"
        />
        <path
          fill="#C8DAEA"
          d="m386.396 115.377 2.241 6.203s.281.58.581.58c.3 0 4.762-4.642 4.762-4.642l4.962-9.584-12.466 5.842-.08 1.601Z"
        />
        <path fill="#A9C6D8" d="m389.369 116.969-.43 4.572s-.18 1.401 1.221 0c1.4-1.401 2.741-2.481 2.741-2.481" />
        <path
          fill="#fff"
          d="m386.437 115.6-4.61-1.502s-.551-.224-.374-.731c.037-.104.11-.193.331-.346 1.021-.712 18.905-7.14 18.905-7.14s.505-.17.803-.057a.434.434 0 0 1 .297.323c.032.134.045.271.04.407-.002.119-.016.228-.027.4-.109 1.758-3.368 14.874-3.368 14.874s-.195.768-.894.794a1.27 1.27 0 0 1-.933-.361c-1.371-1.179-6.11-4.364-7.157-5.064a.217.217 0 0 1-.059-.062.19.19 0 0 1-.027-.08c-.015-.074.065-.165.065-.165s8.253-7.336 8.472-8.106c.017-.059-.047-.089-.133-.063-.548.202-10.05 6.202-11.099 6.864a.506.506 0 0 1-.232.015Z"
        />
        <circle cx={392.502} cy={113.998} r={18.299} stroke="#1B1D21" strokeWidth={1.181} />
      </g>
    </g>
    <g opacity={0.72}>
      <rect width={67.056} height={67.056} x={434.972} y={80.472} fill="#171717" rx={10.861} />
      <rect width={67.056} height={67.056} x={434.972} y={80.472} stroke="url(#A)" strokeWidth={0.944} rx={10.861} />
      <g clipPath="url(#B)">
        <path
          fill="#ED2024"
          d="M468.5 132.887c10.432 0 18.889-8.457 18.889-18.889 0-10.432-8.457-18.889-18.889-18.889-10.432 0-18.889 8.457-18.889 18.889 0 10.432 8.457 18.889 18.889 18.889Z"
        />
        <path
          fill="#fff"
          d="M477.154 109.652a2.282 2.282 0 0 0-1.6-1.6c-1.406-.38-7.063-.38-7.063-.38s-5.648 0-7.062.38a2.282 2.282 0 0 0-1.6 1.6c-.38 1.405-.38 4.349-.38 4.349s0 2.943.38 4.348a2.282 2.282 0 0 0 1.6 1.6c1.406.38 7.062.38 7.062.38s5.649 0 7.063-.38a2.282 2.282 0 0 0 1.6-1.6c.38-1.405.38-4.348.38-4.348s0-2.944-.38-4.349Z"
        />
        <path fill="#ED2024" d="m466.688 116.703 4.693-2.713-4.693-2.705v5.418Z" />
        <circle cx={468.5} cy={113.998} r={18.299} stroke="#1B1D21" strokeWidth={1.181} />
      </g>
    </g>
    <defs>
      <linearGradient id="a" x1={128.5} x2={128.5} y1={72} y2={12} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="c" x1={200.5} x2={200.5} y1={72} y2={4} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="e" x1={278.5} x2={278.5} y1={72} y2={0} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="f" x1={278.524} x2={278.524} y1={54.243} y2={15.625} gradientUnits="userSpaceOnUse">
        <stop stopColor="#0062E0" />
        <stop offset={1} stopColor="#19AFFF" />
      </linearGradient>
      <linearGradient id="g" x1={356.5} x2={356.5} y1={72} y2={4} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="m" x1={428.5} x2={428.5} y1={72} y2={12} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="o" x1={88.5} x2={88.5} y1={148} y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="q" x1={88.136} x2={88.136} y1={95.11} y2={132.553} gradientUnits="userSpaceOnUse">
        <stop stopColor="#6364FF" />
        <stop offset={1} stopColor="#563ACC" />
      </linearGradient>
      <linearGradient id="r" x1={164.5} x2={164.5} y1={148} y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="t" x1={240.5} x2={240.5} y1={148} y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="v" x1={316.5} x2={316.5} y1={148} y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="x" x1={392.5} x2={392.5} y1={148} y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <linearGradient id="z" x1={392.5} x2={392.5} y1={132.887} y2={95.109} gradientUnits="userSpaceOnUse">
        <stop stopColor="#1D93D2" />
        <stop offset={1} stopColor="#38B0E3" />
      </linearGradient>
      <linearGradient id="A" x1={468.5} x2={468.5} y1={148} y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#171819" />
        <stop offset={1} stopColor="#4A4A4A" />
      </linearGradient>
      <clipPath id="b">
        <path fill="#fff" d="M111.834 25.332h33.333v33.333h-33.333z" />
      </clipPath>
      <clipPath id="d">
        <path fill="#fff" d="M181.611 19.11h37.778v37.777h-37.778z" />
      </clipPath>
      <clipPath id="h">
        <rect width={37.778} height={37.778} x={337.611} y={19.109} fill="#fff" rx={18.889} />
      </clipPath>
      <clipPath id="n">
        <path fill="#fff" d="M411.834 25.332h33.333v33.333h-33.333z" />
      </clipPath>
      <clipPath id="p">
        <path fill="#fff" d="M69.611 95.11h37.778v37.777H69.611z" />
      </clipPath>
      <clipPath id="s">
        <path fill="#fff" d="M155.166 105.844h18.889v16.528h-18.889z" />
      </clipPath>
      <clipPath id="u">
        <path fill="#fff" d="M221.611 95.11h37.778v37.777h-37.778z" />
      </clipPath>
      <clipPath id="w">
        <path fill="#fff" d="M297.611 95.11h37.778v37.777h-37.778z" />
      </clipPath>
      <clipPath id="y">
        <path fill="#fff" d="M373.611 95.11h37.778v37.777h-37.778z" />
      </clipPath>
      <clipPath id="B">
        <path fill="#fff" d="M449.611 95.11h37.778v37.777h-37.778z" />
      </clipPath>
      <radialGradient
        id="i"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-23.23165 6.552 -4.7204 -16.73729 374.169 36.816)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF005F" />
        <stop offset={1} stopColor="#FC01D8" />
      </radialGradient>
      <radialGradient
        id="j"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(0 -30.1549 31.9938 0 347.646 59.797)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC0" />
        <stop offset={0.124} stopColor="#FC0" />
        <stop offset={0.567} stopColor="#FE4A05" />
        <stop offset={0.694} stopColor="#FF0F3F" />
        <stop offset={1} stopColor="#FE0657" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="k"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-59.87 227.66 -282.199) scale(12.4693 16.2337)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC0" />
        <stop offset={1} stopColor="#FC0" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="l"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(164.274 169.943 33.99) scale(23.8701 8.13253)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#780CFF" />
        <stop offset={1} stopColor="#820BFF" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);
