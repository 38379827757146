import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import toastClient from '@/core/lib/toast-client';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';
import { ENUM_DOMAIN_STATUS } from '@/modules/playground/enums/domain-status.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { useEffect, useState } from 'react';

const FreeDomain = () => {
  const { state, changeUsername } = usePlayground();
  const [freeDomainValue, setFreeDomainValue] = useState<string>('');

  useEffect(() => {
    if (!state.payload.username) return;
    setFreeDomainValue(state.payload.username);
  }, [state.payload.username]);

  const changeFreeDomainHandler = () => {
    if (!freeDomainValue) {
      toastClient.error('Please enter a username!');
      return;
    }

    changeUsername.mutate({
      linkInBioId: state.payload._id!,
      username: freeDomainValue,
    });
  };

  return (
    <>
      <div className="flex items-start justify-between">
        <div>
          <div className="text-lighter font-semibold">Free Domain</div>
          <div className="text-light text-sm mt-1 mb-4">Type to change your username</div>
        </div>
        <div className="flex items-center gap-x-3">
          <span className="relative flex size-3">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-success opacity-75"></span>
            <span className="relative inline-flex size-3 rounded-full bg-success"></span>
          </span>
          <span className="text-lighter">Live</span>
        </div>
      </div>
      <div className="relative flex items-center w-full h-auto rounded-lg border border-solid border-secondary bg-main">
        <span className="flex select-none items-center pl-2 text-light">
          {removeHttpProtocol(process.env.HOME_URL!)}/
        </span>
        <input
          type="text"
          className="block w-full h-10 border-0 bg-transparent py-[14px] pr-2 text-white focus:outline-none"
          value={freeDomainValue}
          onChange={(event) => {
            setFreeDomainValue(event.target.value);
          }}
        />
        <EnterKBD
          color="primary"
          text="Save"
          onClick={changeFreeDomainHandler}
          disabled={freeDomainValue === state.payload.username}
        />
      </div>
      {state.payload.domain_status === ENUM_DOMAIN_STATUS.LIVE ? (
        <span className="text-lighter text-xs">Your Linkie will automatically redirect to your custom domain</span>
      ) : null}
    </>
  );
};

export default FreeDomain;
