import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/core/lib/query-client';
import { isGoogleConnected } from '../../api/mailing-integration/is-google-connected.api';

const getIsGoogleConnectedQueryOptions = (linkInBioId: string) => {
  return queryOptions({
    queryKey: ['is-google-connected', linkInBioId],
    queryFn: () => isGoogleConnected(linkInBioId),
  });
};

type UseIsGoogleConnectedOptions = {
  queryConfig?: QueryConfig<typeof getIsGoogleConnectedQueryOptions>;
};

const useIsGoogleConnected = (linkInBioId: string, { queryConfig }: UseIsGoogleConnectedOptions = {}) => {
  return useQuery({
    ...getIsGoogleConnectedQueryOptions(linkInBioId),
    ...queryConfig,
  });
};

export default useIsGoogleConnected;
