import { useCards } from '@/modules/playground/hooks/useCards.hook';
import CardActions, { CardActionItem } from '../../components/CardActions';
import { ENUM_CARD_TYPE } from '@/modules/playground/enums/card-type.enum';
import { CardDto } from '@link-in-bio/domain';
import { EditEmbedIcon } from '@/assets/icons/cards/edit-embed.icon';

type EmbedActionsProps = {
  embed: CardDto;
};

const EmbedActions = ({ embed }: EmbedActionsProps) => {
  const { toggleAddDialog, setSelectedCardToAdd, setEditDetails, setEmbedDetails } = useCards();

  const editEmbedHandler = () => {
    setEditDetails((prev) => ({
      ...prev,
      isEdit: true,
      cardId: embed._id || embed.client_id || '',
      cardType: ENUM_CARD_TYPE.EMBED,
    }));
    setEmbedDetails({
      url: embed.embed_details.url,
      html: embed.embed_details.html,
    });
    setSelectedCardToAdd(ENUM_CARD_TYPE.EMBED);
    toggleAddDialog();
  };

  return (
    <CardActions className="w-fit">
      <CardActionItem onClick={editEmbedHandler}>
        <EditEmbedIcon /> <span className="text-sm ml-2">Edit Embed</span>
      </CardActionItem>
    </CardActions>
  );
};

export default EmbedActions;
