import { ENUM_EMBED_HOSTNAME } from '@/modules/playground/enums/embed-hostname.enum';

export const sanitize = (input: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  const allElements = doc.body.getElementsByTagName('*');

  for (let element of allElements) {
    if (element.tagName.toLowerCase() === 'script') {
      const src = element.getAttribute('src') || '';
      if (
        src.includes(ENUM_EMBED_HOSTNAME.INSTAGRAM) ||
        src.includes(ENUM_EMBED_HOSTNAME.THREADS) ||
        src.includes(ENUM_EMBED_HOSTNAME.TIKTOK) ||
        src.includes(ENUM_EMBED_HOSTNAME.TWITTER) ||
        src.includes(ENUM_EMBED_HOSTNAME.BLUESKY) ||
        src.includes(ENUM_EMBED_HOSTNAME.MASTODON) ||
        src.includes(ENUM_EMBED_HOSTNAME.REDDIT) ||
        src.includes(ENUM_EMBED_HOSTNAME.CALENDLY) ||
        input.includes(ENUM_EMBED_HOSTNAME.CAL)
      ) {
        continue;
      }

      element.remove();
    }
  }

  return doc.body.innerHTML;
};
