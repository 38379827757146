import { cn } from '@/core/utils/cn';

type PostPreviewFaviconProps = {
  favicon_url: string;
  className?: string;
};

const PostPreviewFavicon = ({ favicon_url, className = '' }: PostPreviewFaviconProps) => {
  return <img src={favicon_url} className={cn('z-50 w-8 h-8 rounded-full border border-white', className)} />;
};

export default PostPreviewFavicon;
