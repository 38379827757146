import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useFormContext } from 'react-hook-form';
import Textarea from '@/core/components/form/fields/Textarea';
import { cn } from '@/core/utils/cn';
import { useCardPreview } from '@/modules/playground/hooks/useCardPreview.hook';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { motion } from 'framer-motion';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import { PostFieldVariants } from '@/core/constants/mode-variants/input.variants';

const MotionTextarea = motion.create(Textarea);

type PostPreviewDescriptionProps = {
  className?: string;
};

const PostPreviewDescription = ({ className }: PostPreviewDescriptionProps) => {
  const { getCardIndex, handleInputChange } = useCardPreview();
  const { state, dispatch } = usePlayground();
  const { register } = useFormContext();

  return (
    <div className={cn('text-sm mt-2 mb-1 w-full text-left', className)}>
      <MotionTextarea
        key={state.mode}
        initial={state.mode === PlaygroundMode.EDITOR ? 'PREVIEW' : 'EDITOR'}
        animate={state.mode === PlaygroundMode.EDITOR ? 'EDITOR' : 'PREVIEW'}
        variants={PostFieldVariants}
        fieldName={`cards.${getCardIndex()}.link_preview_details.description`}
        registration={register(`cards.${getCardIndex()}.link_preview_details.description`)}
        placeholder="Description"
        className="leading-6 text-light text-left border-0 outline-none w-full pl-0 hover:bg-secondary hover:pl-3 focus:bg-secondary focus:pl-3"
        onChange={handleInputChange(PlaygroundActionType.SET_CARD_PAYLOAD)}
        onFocus={() => dispatch({ type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE, payload: false })}
        onBlur={() => dispatch({ type: PlaygroundActionType.SET_IS_CARD_DRAGGABLE, payload: true })}
        transition={{
          duration: 0.4,
          ease: 'easeInOut',
        }}
      />
    </div>
  );
};

export default PostPreviewDescription;
