export const shareLinkToSocial = (platform: string, url: string) => {
  let shareUrl = '';

  switch (platform) {
    case 'facebook':
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
      break;
    case 'whatsapp':
      shareUrl = `https://wa.me/?text=${encodeURIComponent(url)}`;
      break;
    case 'twitter':
      shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
      break;
    case 'linkedin':
      shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
      break;
    case 'threads':
      shareUrl = `https://www.threads.net/intent/post?text=${encodeURIComponent(url)}`;
      break;
    case 'bluesky':
      shareUrl = `https://bsky.app/intent/compose?url=${encodeURIComponent(url)}`;
      break;
    case 'mastodon':
      shareUrl = `https://mastodonshare.com?url=${encodeURIComponent(url)}`;
      break;
    default:
      shareUrl = url;
  }

  return shareUrl;
};
