import { ModifyLinkInBioDto } from '@link-in-bio/domain';
import { PlaygroundAction, PlaygroundActionType } from '../types/playground-action.type';
import { PlaygroundMode } from '../enums/mode.enum';
import { merge } from 'lodash';
import { ENUM_DOMAIN_STATUS } from '../enums/domain-status.enum';
import { deepUpdate } from '@/core/utils/deep-update';
import { findEmptyCardIndex, getNewCardPosition, updateCardAtIndex } from '@/core/utils/card/card-reducer-helpers';

export type PlaygroundModeType = PlaygroundMode.EDITOR | PlaygroundMode.PREVIEW;

export type PlaygroundState = {
  mode: PlaygroundMode;
  payload: ModifyLinkInBioDto;
  actions: string[];
  isCardDraggable: boolean;
};

export const initialState: PlaygroundState = {
  mode: PlaygroundMode.PREVIEW,
  payload: {
    _id: '',
    username: '',
    name: '',
    title: '',
    bio: '',
    cta_button: {
      label: '',
      url: '',
    },
    domain: '',
    domain_status: ENUM_DOMAIN_STATUS.PENDING,
    cards: [],
    mailing_integrations: [],
  },
  actions: [],
  isCardDraggable: true,
};

export const playgroundReducer = (state: PlaygroundState, action: PlaygroundAction): PlaygroundState => {
  switch (action.type) {
    case PlaygroundActionType.SET_INIT_PAYLOAD:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
      };
    case PlaygroundActionType.SET_PAYLOAD:
      return {
        ...state,
        payload: merge({}, state.payload, action.payload),
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.CLEAR_PAYLOAD:
      return {
        ...state,
        payload: initialState.payload,
      };
    case PlaygroundActionType.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case PlaygroundActionType.SET_CARD: {
      const currentCards = state.payload.cards || [];
      const emptyCardIndex = findEmptyCardIndex(currentCards);

      let updatedCards;

      if (emptyCardIndex !== -1) {
        updatedCards = updateCardAtIndex(currentCards, emptyCardIndex, action.payload);
      } else {
        const newPosition = getNewCardPosition(currentCards);
        updatedCards = [{ ...action.payload, position: newPosition }, ...currentCards];
      }

      return {
        ...state,
        payload: {
          ...state.payload,
          cards: updatedCards,
        },
        actions: [...state.actions, action.type],
      };
    }
    case PlaygroundActionType.SET_CARD_PAYLOAD:
      return {
        ...state,
        payload: {
          ...state.payload,
          cards: state.payload.cards?.map((card) => {
            if ((card._id || card.client_id) === action.payload.cardId) {
              const fullPath = Object.keys(action.payload)[0];
              const pathArray = fullPath.split('.').slice(2);
              const fieldValue = Object.values(action.payload)[0];

              return deepUpdate(card, pathArray, fieldValue);
            }
            return card;
          }),
        },
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.DELETE_CARD:
      return {
        ...state,
        payload: {
          ...state.payload,
          cards: state.payload.cards?.filter((card) => (card._id || card.client_id) !== action.payload),
        },
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.REORDER_CARDS:
      return {
        ...state,
        payload: {
          ...state.payload,
          cards: action.payload.map((card, index) => ({
            ...card,
            position: action.payload.length - 1 - index,
          })),
        },
        actions: [...state.actions, action.type],
      };
    case PlaygroundActionType.CLEAR_ACTIONS:
      return {
        ...state,
        actions: [],
      };
    case PlaygroundActionType.SET_IS_CARD_DRAGGABLE:
      return {
        ...state,
        isCardDraggable: action.payload,
      };
    default:
      return state;
  }
};
