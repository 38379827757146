import { createContext, ReactNode } from 'react';
import usePlayground from '../hooks/usePlayground.hook';
import { ISetCardPayloadAction, PlaygroundActionType } from '../types/playground-action.type';
import { CardDto } from '@link-in-bio/domain';

type CardPreviewProviderProps = {
  children: ReactNode;
  card: CardDto;
};

export const CardPreviewContext = createContext<
  | {
      card: CardDto;
      getCardIndex: Function;
      handleInputChange: Function;
    }
  | undefined
>(undefined);

function CardPreviewProvider({ children, card }: CardPreviewProviderProps) {
  const { state, dispatchWithDebounce } = usePlayground();

  const handleInputChange =
    (actionType: PlaygroundActionType.SET_CARD_PAYLOAD) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      const action: ISetCardPayloadAction = {
        type: actionType,
        payload: {
          [name]: value,
          cardId: card._id || card.client_id || '',
        },
      };

      dispatchWithDebounce(action);
    };

  const getCardIndex = () => {
    return (
      state.payload.cards?.findIndex((c) => {
        if (c._id) return c._id === card._id;
        return c.client_id === card.client_id;
      }) ?? 0
    );
  };

  const value = {
    card,
    getCardIndex,
    handleInputChange,
  };

  return <CardPreviewContext.Provider value={value}>{children}</CardPreviewContext.Provider>;
}

export default CardPreviewProvider;
