export const PreivewOnlyVariants = {
  PREVIEW: {
    pointerEvents: 'auto' as 'auto',
    opacity: 1,
    visibility: 'visible' as 'visible',
  },
  EDITOR: {
    pointerEvents: 'none' as 'none',
    visibility: 'hidden' as 'hidden',
    opacity: 0,
  },
};

export const EditorOnlyVariants = {
  PREVIEW: {
    pointerEvents: 'none' as 'none',
    visibility: 'hidden' as 'hidden',
    opacity: 0,
  },
  EDITOR: {
    pointerEvents: 'auto' as 'auto',
    visibility: 'visible' as 'visible',
    opacity: 1,
  },
};
