import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { subscribe } from '../../api/subscribe/subscribe.api';

type UseSubscribeOptions = {
  mutationConfig?: MutationConfig<typeof subscribe>;
};

const useSubscribe = ({ mutationConfig }: UseSubscribeOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: subscribe,
  });
};

export default useSubscribe;
