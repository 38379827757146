import { useContext, useEffect } from 'react';
import { PlaygroundContext } from '../context/playground.context';
import { PlaygroundAction, PlaygroundActionType } from '../types/playground-action.type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlaygroundMode } from '../enums/mode.enum';
import useAuth from '@/modules/auth/hooks/useAuth.hook';
import useModifyLinkInBio from '@/modules/link-in-bio/hooks/useModifyLinkInBio.hook';
import useGetDefaultLinkInBio from '@/modules/link-in-bio/hooks/useGetDefaultLinkInBio.hook';
import useUploadProfileImage from '@/modules/link-in-bio/hooks/useUploadProfileImage';
import { ILinkPreviewDetails, LinkInBioDto, ModifyLinkInBioDto } from '@link-in-bio/domain';
import useUploadLinkThumbnail from '@/modules/link-in-bio/hooks/useUploadLinkThumbnail.hook';
import useUploadLinkFavicon from '@/modules/link-in-bio/hooks/useUploadLinkFavicon.hook';
import { readBase64Image } from '@/core/utils/image/read-base64-image';
import Cookies from 'js-cookie';
import useGetLinkie from '@/modules/link-in-bio/hooks/useGetLinkie.hook';
import useChangeUsername from '@/modules/link-in-bio/hooks/useChangeUsername.hook';
import toastClient from '@/core/lib/toast-client';
import { ENUM_DOMAIN_STATUS } from '../enums/domain-status.enum';
import { removeHttpProtocol } from '@/core/utils/remove-http-protocol';

const usePlayground = () => {
  const context = useContext(PlaygroundContext);
  if (context === undefined) {
    throw new Error('usePlayground must be used within a PlaygroundProvider');
  }

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { state: authState } = useAuth();

  const uploadProfileImage = useUploadProfileImage();
  const uploadLinkThumbnail = useUploadLinkThumbnail();
  const uploadLinkFavicon = useUploadLinkFavicon();

  const modifyLinkInBio = useModifyLinkInBio({
    mutationConfig: {
      onSuccess: async ({ data }: { data: LinkInBioDto }) => {
        const profile_image_url = context.state.payload.profile_image_url;
        const avatar = profile_image_url ? readBase64Image(profile_image_url) : null;

        const mutationPromises = [];

        if (avatar) {
          const uploadProfileImagePromise = uploadProfileImage.mutateAsync({
            linkInBioId: context.state.payload._id!,
            file: avatar,
          });

          mutationPromises.push(uploadProfileImagePromise);
        }

        context.state.payload.cards?.forEach(async (card, index: number) => {
          if (card.link_preview_details?.thumbnail_url) {
            let canUploadThumbnail: boolean = false;

            if (
              card.link_preview_details?.thumbnail_url.startsWith('data:image') ||
              !card.link_preview_details?.thumbnail_url.startsWith(
                `https://${process.env.AWS_S3_BUCKET}.s3.${process.env.AWS_S3_REGION}`,
              )
            ) {
              canUploadThumbnail = true;
            }

            if (canUploadThumbnail) {
              const cardId = data.cards?.[index]?._id;

              const uploadThumbnailPromise = uploadLinkThumbnail.mutateAsync({
                linkInBioId: context.state.payload._id!,
                cardId: cardId ?? '',
                imageUrl:
                  card.link_preview_details?.thumbnail_url.startsWith('data:image') ||
                  card.link_preview_details?.thumbnail_url.startsWith(
                    `https://${process.env.AWS_S3_BUCKET}.s3.${process.env.AWS_S3_REGION}`,
                  )
                    ? ''
                    : encodeURIComponent(card.link_preview_details?.thumbnail_url),
                file: readBase64Image(card.link_preview_details?.thumbnail_url) as File,
              });

              mutationPromises.push(uploadThumbnailPromise);
            }
          }

          if (card.link_preview_details?.favicon_url) {
            const favicon = card.link_preview_details?.favicon_url
              ? readBase64Image(card.link_preview_details?.favicon_url)
              : null;

            if (favicon) {
              const cardId = data.cards?.[index]?._id;

              const uploadFaviconPromise = uploadLinkFavicon.mutateAsync({
                linkInBioId: context.state.payload._id!,
                cardId: cardId ?? '',
                file: favicon,
              });

              mutationPromises.push(uploadFaviconPromise);
            }
          }
        });

        try {
          await Promise.all(mutationPromises);
          context.dispatch({ type: PlaygroundActionType.CLEAR_ACTIONS });
          toastClient.success('Linkie has been updated successfully!');
        } catch (error) {
          console.log(error);
        }
      },
    },
  });

  const changeUsername = useChangeUsername({
    mutationConfig: {
      onSuccess: async ({ data }: { data: LinkInBioDto }) => {
        context.dispatch({
          type: PlaygroundActionType.SET_PAYLOAD,
          payload: {
            username: data.username,
          },
        });

        toastClient.success('Username has been changed successfully!');
      },
    },
  });

  const defaultLinkInBioQuery = useGetDefaultLinkInBio(authState.user?._id ?? '', {
    queryConfig: {
      enabled: authState.isAuthenticated && !!authState.user?._id && location.pathname === '/playground',
    },
  });

  const getLinkieQuery = useGetLinkie(
    {
      username: params.username,
      domain: window.location.host,
    },
    {
      queryConfig: {
        enabled: (!!params.username || !!window.location.host) && location.pathname !== '/playground',
        retry: false,
      },
    },
  );

  useEffect(() => {
    setDefaultLinkInBioData();
  }, [defaultLinkInBioQuery.data]);

  useEffect(() => {
    setShareablePreviewData();
  }, [getLinkieQuery.data]);

  const setDefaultLinkInBioData = () => {
    if (!defaultLinkInBioQuery?.data || context.state.payload._id) return;

    context.dispatch({
      type: PlaygroundActionType.SET_INIT_PAYLOAD,
      payload: defaultLinkInBioQuery.data.data as ModifyLinkInBioDto,
    });

    Cookies.set('linkInBioId', defaultLinkInBioQuery.data.data._id);
  };

  const setShareablePreviewData = () => {
    if (!getLinkieQuery?.data) return;

    context.dispatch({
      type: PlaygroundActionType.SET_INIT_PAYLOAD,
      payload: getLinkieQuery.data.data as ModifyLinkInBioDto,
    });

    Cookies.set('linkieUsername', getLinkieQuery.data?.data?.username || '');
  };

  const handleInputChange =
    (actionType: PlaygroundActionType) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      const action = {
        type: actionType,
        payload: {
          [name]: value,
        },
      };

      context.dispatchWithDebounce(action as PlaygroundAction);
    };

  const getShareableDomain = () => {
    if (context.state.payload.domain && context.state.payload.domain_status === ENUM_DOMAIN_STATUS.LIVE) {
      return context.state.payload.domain;
    }

    return removeHttpProtocol(`${process.env.HOME_URL}/${context.state.payload.username}`);
  };

  const openPreview = () => {
    if (!authState.isAuthenticated) {
      navigate('/auth/register');
      return;
    }

    window.open(`${process.env.HOME_URL}/${context.state.payload.username}`, '_blank');
  };

  const prepareLinkPreviewDetails = (linkPreviewDetails: ILinkPreviewDetails | undefined) => {
    return {
      ...linkPreviewDetails,
      title: linkPreviewDetails?.title ?? '',
      description: linkPreviewDetails?.description ?? '',
      url: linkPreviewDetails?.url ?? '',
      provider_display: linkPreviewDetails?.provider_display ?? '',
      thumbnail_url:
        linkPreviewDetails?.thumbnail_url && linkPreviewDetails?.thumbnail_url.startsWith('data:')
          ? ''
          : (linkPreviewDetails?.thumbnail_url ?? ''),
      favicon_url:
        linkPreviewDetails?.favicon_url && linkPreviewDetails?.favicon_url.startsWith('data:')
          ? ''
          : (linkPreviewDetails?.favicon_url ?? ''),
    };
  };

  const handleSubmit = async () => {
    if (authState.isAuthenticated) {
      modifyLinkInBio.mutate({
        ...context.state.payload,
        profile_image_url: undefined,
        cards: context.state.payload.cards?.map((link) => ({
          ...link,
          link_preview_details: prepareLinkPreviewDetails(link.link_preview_details),
        })),
      });

      return;
    }

    navigate('/auth/register');
  };

  const changeMode = () => {
    const mode = context.state.mode === PlaygroundMode.EDITOR ? PlaygroundMode.PREVIEW : PlaygroundMode.EDITOR;
    context.dispatch({ type: PlaygroundActionType.CHANGE_MODE, payload: mode });
  };

  return {
    ...context,
    changeUsername,
    handleInputChange,
    openPreview,
    getShareableDomain,
    handleSubmit,
    changeMode,
    isSubmitting: modifyLinkInBio.isPending,
    requestStatus: {
      isFetched: getLinkieQuery.isFetched,
      isError: getLinkieQuery.isError,
    },
  };
};

export default usePlayground;
