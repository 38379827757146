import { CloseIcon } from '@/assets/icons/close.icon';
import CardOptionsContainer from './card-options-selector/CardOptionsContainer';
import { ENUM_CARD_TYPE } from '@/modules/playground/enums/card-type.enum';
import _ from 'lodash';
import AddLinkView from './add-card-views/add-link-view';
import Button from '@/core/design-system/Button';
import Glow from '@/core/design-system/Glow';
import AddPostView from './add-card-views/add-post-view';
import { ArrowLeftIcon } from '@/assets/icons/arrow-left.icon';
import { useCards } from '@/modules/playground/hooks/useCards.hook';
import Modal from '@/core/design-system/Modal';
import AddEmbedView from './add-card-views/add-embed-view';

const AddCardDialog = () => {
  const {
    isAddDialogOpen,
    toggleAddDialog,
    selectedCardToAdd,
    editDetails,
    setSelectedCardToAdd,
    resetEditDetails,
    resetLinkDetails,
    resetPostDetails,
    submitHandler,
  } = useCards();

  const handleCloseDialog = () => {
    toggleAddDialog();
    resetEditDetails();
    resetLinkDetails();
    resetPostDetails();
    setSelectedCardToAdd(undefined);
  };

  const handleOptionSelect = (option: ENUM_CARD_TYPE) => {
    setSelectedCardToAdd(option);
  };

  const handleBack = () => {
    resetEditDetails();
    resetLinkDetails();
    resetPostDetails();
    setSelectedCardToAdd(undefined);
  };

  const renderAddCardViews = () => {
    switch (selectedCardToAdd) {
      case ENUM_CARD_TYPE.LINK:
        return <AddLinkView />;
      case ENUM_CARD_TYPE.POST:
        return <AddPostView />;
      case ENUM_CARD_TYPE.EMBED:
        return <AddEmbedView />;
      default:
        return <CardOptionsContainer handleOptionSelect={handleOptionSelect} />;
    }
  };

  return (
    <Modal isOpen={isAddDialogOpen} toggleDialog={handleCloseDialog}>
      <Glow className="top-0 h-[15vh] md:h-[5vh]" />
      <div className="p-6 rounded-2xl bg-main border border-1 border-secondary">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            {selectedCardToAdd ? (
              <div className="z-50 cursor-pointer" onClick={handleBack}>
                <ArrowLeftIcon />
              </div>
            ) : null}
            <div className="text-xl text-lighter font-semibold">{_.capitalize(selectedCardToAdd) || 'Cards'}</div>
          </div>
          <div onClick={handleCloseDialog} className="z-50 cursor-pointer">
            <CloseIcon />
          </div>
        </div>

        <div className="w-full h-[1px] bg-secondary my-6" />

        {renderAddCardViews()}

        {selectedCardToAdd ? (
          <div className="w-full flex items-center justify-end gap-2 mt-20">
            <Button className="w-fit" variant="outlined" color="secondary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button className="w-fit" onClick={submitHandler}>
              {editDetails.isEdit ? 'Modify' : 'Add'} {_.capitalize(selectedCardToAdd)}
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default AddCardDialog;
