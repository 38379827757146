type SocialIconProps = {
  onClick?: () => void;
};

export const LinkedInIcon = ({ onClick = () => {} }: SocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    className="cursor-pointer"
    onClick={onClick}
  >
    <g clipPath="url(#clip0_1662_7076)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z"
        fill="#007EBB"
      />
      <circle cx="20.5" cy="20" r="19.375" stroke="#1B1D21" strokeWidth="1.25" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2779 31.6671H28.2554V23.1125C28.2554 20.7671 27.3642 19.4564 25.5078 19.4564C23.4882 19.4564 22.4331 20.8204 22.4331 23.1125V31.6671H17.5928V15.3708H22.4331V17.5659C22.4331 17.5659 23.8885 14.8729 27.3467 14.8729C30.8033 14.8729 33.2779 16.9837 33.2779 21.3493V31.6671ZM11.8182 13.2369C10.1695 13.2369 8.8335 11.8904 8.8335 10.2298C8.8335 8.56915 10.1695 7.22266 11.8182 7.22266C13.467 7.22266 14.8022 8.56915 14.8022 10.2298C14.8022 11.8904 13.467 13.2369 11.8182 13.2369ZM9.31888 31.6671H14.3661V15.3708H9.31888V31.6671Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1662_7076">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
