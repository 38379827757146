import { createContext, ReactNode, useState } from 'react';
import { ENUM_CARD_TYPE } from '../enums/card-type.enum';
import { IEmbedDetails, ILinkDetails, ILinkPreviewResponse, IPostDetails } from '@link-in-bio/domain';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';
import { cloudflareWorkerReverseProxy } from '@/core/lib/cloudflare-worker-reverse-proxy';
import usePlayground from '../hooks/usePlayground.hook';
import { PlaygroundActionType } from '../types/playground-action.type';
import TwitterText from 'twitter-text';
import toastClient from '@/core/lib/toast-client';
import { embedFromUrl } from '@/core/utils/embed';
import { embedElementStyleOverride } from '@/core/utils/embed/embed-element-style-override';
import { sanitize } from '@/core/utils/sanitize';

interface IEditDetails {
  isEdit: boolean;
  cardId: string;
  cardType: ENUM_CARD_TYPE | undefined;
}

interface IEmbedDetailsWithUrl extends IEmbedDetails {
  url: string;
}

type CardsProviderProps = {
  children: ReactNode;
};

export const CardsContext = createContext<
  | {
      isAddDialogOpen: boolean;
      toggleAddDialog: () => void;
      selectedCardToAdd: ENUM_CARD_TYPE | undefined;
      setSelectedCardToAdd: (cardType: ENUM_CARD_TYPE | undefined) => void;
      editDetails: IEditDetails;
      setEditDetails: React.Dispatch<React.SetStateAction<IEditDetails>>;
      linkDetails: ILinkDetails;
      setLinkDetails: React.Dispatch<React.SetStateAction<ILinkDetails>>;
      postDetails: IPostDetails;
      setPostDetails: React.Dispatch<React.SetStateAction<IPostDetails>>;
      embedDetails: IEmbedDetailsWithUrl;
      setEmbedDetails: React.Dispatch<React.SetStateAction<IEmbedDetailsWithUrl>>;
      resetEditDetails: () => void;
      resetLinkDetails: () => void;
      resetPostDetails: () => void;
      submitHandler: () => void;
      handleLinkDetailsInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
      handlePostDetailsInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
      handleEmbedDetailsInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    }
  | undefined
>(undefined);

function CardsProvider({ children }: CardsProviderProps) {
  const { dispatch } = usePlayground();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [selectedCardToAdd, setSelectedCardToAdd] = useState<ENUM_CARD_TYPE | undefined>(undefined);
  const [editDetails, setEditDetails] = useState<IEditDetails>({
    isEdit: false,
    cardId: '',
    cardType: undefined,
  });
  const [linkDetails, setLinkDetails] = useState<ILinkDetails>({
    url: '',
    layout: ENUM_LINK_LAYOUT.DEFAULT,
    cta_label: '',
  });
  const [postDetails, setPostDetails] = useState<IPostDetails>({
    url: '',
    destination_url: '',
  });
  const [embedDetails, setEmbedDetails] = useState<IEmbedDetailsWithUrl>({
    html: '',
    url: '',
  });

  const resetEditDetails = () => {
    setEditDetails({
      isEdit: false,
      cardId: '',
      cardType: undefined,
    });
  };

  const resetLinkDetails = () => {
    setLinkDetails({
      url: '',
      layout: ENUM_LINK_LAYOUT.DEFAULT,
      cta_label: '',
    });
  };

  const resetPostDetails = () => {
    setPostDetails({
      url: '',
      destination_url: '',
    });
  };

  const resetEmbedDetails = () => {
    setEmbedDetails({
      html: '',
      url: '',
    });
  };

  const toggleAddDialog = () => {
    setIsAddDialogOpen(!isAddDialogOpen);
  };

  const handleLinkDetailsInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    setLinkDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePostDetailsInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    setPostDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEmbedDetailsInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    if (name === 'url') {
      const embedElement = embedFromUrl(value);

      if (embedElement) {
        setEmbedDetails((prev) => ({
          ...prev,
          html: embedElement,
          url: value,
        }));
      }

      return;
    }

    if (name === 'html') {
      setEmbedDetails((prev) => ({
        ...prev,
        html: embedElementStyleOverride(value),
      }));

      return;
    }

    setEmbedDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitLinkHandler = async () => {
    if (!linkDetails.url || (linkDetails.url && !TwitterText.isValidUrl(linkDetails.url, true, true))) {
      toastClient.error('Please enter a valid link');
      return;
    }

    dispatch({
      type: PlaygroundActionType.SET_CARD,
      payload: {
        type: ENUM_CARD_TYPE.LINK,
        link_details: {
          url: '',
          layout: ENUM_LINK_LAYOUT.DEFAULT,
          cta_label: '',
        },
      },
    });

    toggleAddDialog();
    setSelectedCardToAdd(undefined);

    const linkPreview: ILinkPreviewResponse = await cloudflareWorkerReverseProxy.post(`/link-preview`, {
      url: linkDetails.url,
    });

    dispatch({
      type: PlaygroundActionType.SET_CARD,
      payload: {
        type: ENUM_CARD_TYPE.LINK,
        client_id: crypto.randomUUID(),
        link_details: {
          url: linkDetails.url,
          layout: linkDetails.layout,
          cta_label: linkDetails.cta_label,
        },
        link_preview_details: {
          title: linkPreview.title,
          description: linkPreview.description,
          url: linkPreview.url,
          provider_display: linkPreview.provider_display,
          thumbnail_url: linkPreview.images[0],
          favicon_url: linkPreview.favicon,
        },
      },
    });

    resetLinkDetails();
  };

  const submitPostHandler = async () => {
    if (
      !postDetails.url ||
      (postDetails.url && !TwitterText.isValidUrl(encodeURI(new URL(postDetails.url).toString()), false, true))
    ) {
      toastClient.error('Please enter a valid link');
      return;
    }

    if (editDetails.isEdit) {
      dispatch({
        type: PlaygroundActionType.SET_CARD_PAYLOAD,
        payload: {
          [`cards.${editDetails.cardId}.post_details.destination_url`]: postDetails.destination_url,
          cardId: editDetails.cardId,
        },
      });

      toastClient.success('Post custom URL updated successfully');
      toggleAddDialog();
      setSelectedCardToAdd(undefined);
      resetEditDetails();
      resetPostDetails();

      return;
    }

    dispatch({
      type: PlaygroundActionType.SET_CARD,
      payload: {
        type: ENUM_CARD_TYPE.POST,
        post_details: {
          url: '',
          destination_url: '',
        },
      },
    });

    toggleAddDialog();
    setSelectedCardToAdd(undefined);

    const linkPreview: ILinkPreviewResponse = await cloudflareWorkerReverseProxy.post(`/link-preview`, {
      url: postDetails.url,
    });

    dispatch({
      type: PlaygroundActionType.SET_CARD,
      payload: {
        type: ENUM_CARD_TYPE.POST,
        client_id: crypto.randomUUID(),
        post_details: {
          url: postDetails.url,
          destination_url: postDetails.destination_url,
        },
        link_preview_details: {
          title: linkPreview.title,
          description: linkPreview.description,
          url: linkPreview.url,
          provider_display: linkPreview.provider_display,
          thumbnail_url: linkPreview.images[0],
          favicon_url: linkPreview.favicon,
        },
      },
    });

    resetPostDetails();
  };

  const submitEmbedHandler = async () => {
    const sanitizedHtml = sanitize(embedDetails.html);

    if (!sanitizedHtml.trim()) {
      toastClient.error('Please enter a valid embed code');
      return;
    }

    if (editDetails.isEdit) {
      dispatch({
        type: PlaygroundActionType.SET_CARD_PAYLOAD,
        payload: {
          [`cards.${editDetails.cardId}.embed_details.html`]: sanitizedHtml,
          cardId: editDetails.cardId,
        },
      });

      toastClient.success('Embed updated successfully');
      toggleAddDialog();
      setSelectedCardToAdd(undefined);
      resetEditDetails();
      resetEmbedDetails();

      return;
    }

    toggleAddDialog();
    setSelectedCardToAdd(undefined);

    dispatch({
      type: PlaygroundActionType.SET_CARD,
      payload: {
        type: ENUM_CARD_TYPE.EMBED,
        client_id: crypto.randomUUID(),
        embed_details: {
          html: sanitizedHtml,
        },
      },
    });

    resetEmbedDetails();
  };

  const submitHandler = () => {
    switch (selectedCardToAdd) {
      case ENUM_CARD_TYPE.LINK:
        submitLinkHandler();
        break;
      case ENUM_CARD_TYPE.POST:
        submitPostHandler();
        break;
      case ENUM_CARD_TYPE.EMBED:
        submitEmbedHandler();
        break;
      default:
        break;
    }
  };

  const value = {
    isAddDialogOpen,
    toggleAddDialog,
    selectedCardToAdd,
    setSelectedCardToAdd,
    editDetails,
    setEditDetails,
    linkDetails,
    setLinkDetails,
    postDetails,
    setPostDetails,
    embedDetails,
    setEmbedDetails,
    resetEditDetails,
    resetLinkDetails,
    resetPostDetails,
    submitHandler,
    handleLinkDetailsInputChange,
    handlePostDetailsInputChange,
    handleEmbedDetailsInputChange,
  };

  return <CardsContext.Provider value={value}>{children}</CardsContext.Provider>;
}

export default CardsProvider;
