import CardsProvider from '@/modules/playground/context/cards.context';
import AddCard from '../add-card';
import CardsEmptySkeleton from './CardsEmptySkeleton';
import CardsGrid from './CardsGrid';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';

const Cards = () => {
  const { state } = usePlayground();

  return (
    <CardsProvider>
      {state.mode === PlaygroundMode.EDITOR ? <AddCard /> : null}
      <CardsGrid />
      <CardsEmptySkeleton />
    </CardsProvider>
  );
};

export default Cards;
