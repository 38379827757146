import { toast } from 'react-hot-toast';

const toastStyles = {
  style: {
    background: '#23262c',
    color: '#f3f4f6',
  },
};

const toastClient = {
  success: (message: string) => toast.success(message, toastStyles),
  error: (message: string) => toast.error(message, toastStyles),
  default: (message: string) => toast(message, toastStyles),
};

export default toastClient;
