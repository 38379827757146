import { CardDto } from '@link-in-bio/domain';
import useLinkFaviconUpload from '@/modules/playground/hooks/useLinkFaviconUpload.hook';
import { FaviconDefaultIcon } from '@/assets/icons/favicon-default.icon';
import { useState } from 'react';
import { CameraIcon } from '@/assets/icons/camera.icon';

type LinkPreviewFaviconProps = {
  link: CardDto;
};

const LinkPreviewFavicon = ({ link }: LinkPreviewFaviconProps) => {
  const {
    fileInputRef: faviconFileInputRef,
    openExplorer: faviconOpenExplorer,
    uploadFaviconHandler: faviconUploadFaviconHandler,
  } = useLinkFaviconUpload();

  const [hasImageError, setHasImageError] = useState<boolean>(false);

  const getFaviconSrc = () => {
    if (link.link_preview_details.favicon_url) return link.link_preview_details.favicon_url;
    return undefined;
  };

  const onImageError = () => {
    setHasImageError(true);
  };

  const renderThumbnail = () => {
    if (hasImageError) {
      return <FaviconDefaultIcon />;
    }

    if (getFaviconSrc()) {
      return (
        <img
          src={getFaviconSrc()}
          alt="Link Icon"
          className="rounded-lg w-full h-full object-contain"
          onError={onImageError}
        />
      );
    }

    return <FaviconDefaultIcon />;
  };

  return (
    <>
      <div className="relative group/thumbnail cursor-pointer w-8 h-8" onClick={faviconOpenExplorer}>
        {renderThumbnail()}
        <div className="absolute inset-0 bg-black opacity-0 group-hover/thumbnail:opacity-40 rounded-lg transition-all duration-200"></div>
        <div className="absolute inset-0 flex items-center justify-center text-white opacity-0 group-hover/thumbnail:opacity-100 transition-all duration-200">
          <CameraIcon size={4} />
        </div>
      </div>

      <input
        ref={faviconFileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png,.webp"
        className="hidden"
        id="favicon"
        name="favicon"
        onChange={(e) => faviconUploadFaviconHandler(e, link._id || link.client_id || '')}
      />
    </>
  );
};

export default LinkPreviewFavicon;
