import { ChangeEvent, KeyboardEvent } from 'react';

type OtpFormProps = {
  pin: string[];
  setPin: (pin: string[]) => void;
};

const OtpForm = ({ pin, setPin }: OtpFormProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    if (value.length <= 1) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      if (value && index < pin.length - 1) {
        const nextInput = document.getElementById(`pin-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleBackspace = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && pin[index] === '') {
      if (index > 0) {
        const prevInput = document.getElementById(`pin-input-${index - 1}`);
        if (prevInput) prevInput.focus();
      }
    }
  };

  const handlePaste = (e: { clipboardData: { getData: (arg0: string) => any }; preventDefault: () => void }) => {
    const pastedValue = e.clipboardData.getData('Text');
    if (pastedValue.length === 6) {
      const newPin = pastedValue.split('');
      setPin(newPin);

      const lastInput = document.getElementById('pin-input-5');
      if (lastInput) lastInput.focus();
    }

    e.preventDefault();
  };

  return (
    <>
      {pin.map((digit, index) => (
        <input
          key={index}
          id={`pin-input-${index}`}
          type="text"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          onPaste={handlePaste}
          className="text-2xl rounded-lg font-bold text-lighter outline-none w-full h-[67px] text-center border-2 border-secondary bg-main"
        />
      ))}
    </>
  );
};

export default OtpForm;
