import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/core/lib/query-client';
import { getDefaultLinkInBio } from '../api/get-default-link-in-bio.api';

const getDefaultLinkInBioQueryOptions = (userId: string) => {
  return queryOptions({
    queryKey: ['default-link-in-bio', userId],
    queryFn: () => getDefaultLinkInBio(userId),
  });
};

type UseGetDefaultLinkInBioOptions = {
  queryConfig?: QueryConfig<typeof getDefaultLinkInBioQueryOptions>;
};

const useGetDefaultLinkInBio = (userId: string, { queryConfig }: UseGetDefaultLinkInBioOptions = {}) => {
  return useQuery({
    ...getDefaultLinkInBioQueryOptions(userId),
    ...queryConfig,
  });
};

export default useGetDefaultLinkInBio;
