import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/core/lib/query-client';
import { getGoogleSpreadsheets } from '../../api/mailing-integration/get-google-spreadsheets.api';

const getGoogleSpreadsheetQueryOptions = (linkInBioId: string) => {
  return queryOptions({
    queryKey: ['google-spreadsheets', linkInBioId],
    queryFn: () => getGoogleSpreadsheets(linkInBioId),
  });
};

type UseGetGoogleSpreadsheetOptions = {
  queryConfig?: QueryConfig<typeof getGoogleSpreadsheetQueryOptions>;
};

const useGetGoogleSpreadsheet = (linkInBioId: string, { queryConfig }: UseGetGoogleSpreadsheetOptions = {}) => {
  return useQuery({
    ...getGoogleSpreadsheetQueryOptions(linkInBioId),
    ...queryConfig,
  });
};

export default useGetGoogleSpreadsheet;
