import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { verifySubscribe } from '../../api/subscribe/verify-subscribe.api';

type UseVerifySubscribeOptions = {
  mutationConfig?: MutationConfig<typeof verifySubscribe>;
};

const useVerifySubscribe = ({ mutationConfig }: UseVerifySubscribeOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: verifySubscribe,
  });
};

export default useVerifySubscribe;
