import CustomDomain from './CustomDomain';
import FreeDomain from './FreeDomain';

const DomainSettings = () => {
  return (
    <>
      <FreeDomain />
      <div className="w-full h-[1px] bg-secondary my-6" />
      <CustomDomain />
    </>
  );
};

export default DomainSettings;
