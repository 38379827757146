import { cn } from '@/core/utils/cn';

type CheckIconProps = {
  className?: string;
};

export const CheckIcon = ({ className = '' }: CheckIconProps) => (
  <svg
    className={cn(
      'stroke-[#3E4046] opacity-0 group-data-[checked]:opacity-100 group-data-[checked]:stroke-white',
      className,
    )}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
