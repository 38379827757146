import usePlayground from '../../../playground/hooks/usePlayground.hook';
import Button from '@/core/design-system/Button';
import { useState } from 'react';
import DefaultAvatarAsset from '@/assets/images/default-avatar.png';
import { CloseIcon } from '@/assets/icons/close.icon';
import { FacebookIcon } from '@/assets/icons/socials/facebook.icon';
import { WhatsappIcon } from '@/assets/icons/socials/whatsapp.icon';
import { TwitterIcon } from '@/assets/icons/socials/twitter.icon';
import { LinkedInIcon } from '@/assets/icons/socials/linkedin.icon';
import { ThreadsIcon } from '@/assets/icons/socials/threads.icon';
import { BlueskyIcon } from '@/assets/icons/socials/bluesky.icon';
import { MastodonIcon } from '@/assets/icons/socials/mastodon.icon';
import Modal from '@/core/design-system/Modal';
import { shareLinkToSocial } from '@/core/utils/share-link-to-social';
import { copyToClipboard } from '@/core/utils/copy-to-clipboard';

type ShareDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
};

const ShareDialog = ({ isOpen, toggleDialog }: ShareDialogProps) => {
  const { state, getShareableDomain } = usePlayground();
  const [copyButtonText, setCopyButtonText] = useState<string>('Copy');

  const handleCopyClick = () => {
    copyToClipboard(getShareableDomain(), (success) => {
      if (success) {
        setCopyButtonText('Copied');
        setTimeout(() => setCopyButtonText('Copy'), 1000);
      }
    });
  };

  const shareLink = (platform: string) => {
    const shareUrl = shareLinkToSocial(platform, getShareableDomain());
    return shareUrl;
  };

  return (
    <Modal isOpen={isOpen} toggleDialog={toggleDialog} dialogPanelClassName="md:w-[410px]">
      <div className="z-10 p-6 text-center rounded-2xl bg-radial-gradient shadow-radial-shadow">
        <div onClick={toggleDialog} className="cursor-pointer z-50 absolute right-4 top-6">
          <CloseIcon />
        </div>

        <div className="relative">
          <img
            src={state.payload.profile_image_url ?? DefaultAvatarAsset}
            alt="Profile Image"
            className="mx-auto w-[140px] h-[140px] rounded-full shadow-profile-image-shadow"
          />

          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 trans w-fit text-xs rounded-3xl flex justify-center items-center py-1 px-2 text-lighter bg-profile-gradient shadow-profile-shadow">
            {state.payload.name}
          </div>
        </div>

        <div className="py-8">
          <h1 className="text-lighter text-2xl font-bold mb-2">Share your Linkie</h1>
        </div>

        <div className="relative bg-dark border border-solid border-secondary py-2 px-2 rounded-xl">
          <input
            value={getShareableDomain()}
            className="text-sm outline-none w-full rounded-lg border border-solid border-secondary bg-main py-2 px-4 text-lighter"
            readOnly
          />
          <div
            className="absolute top-1/2 right-[10px] transform -translate-y-1/2 flex py-[2px] pr-[2px] h-8 cursor-pointer"
            onClick={handleCopyClick}
          >
            <kbd className="inline-flex items-center rounded-[5px] py-2 px-3 text-sm text-lighter font-bold bg-primary">
              {copyButtonText}
            </kbd>
          </div>
        </div>

        <div className="pt-8">
          <p className="text-light text-sm text-left mb-2">Share on socials</p>

          <div className="rounded-xl p-3 bg-main flex justify-center items-center gap-4">
            <FacebookIcon onClick={() => window.open(shareLink('facebook'), '_blank')} />
            <WhatsappIcon onClick={() => window.open(shareLink('whatsapp'), '_blank')} />
            <TwitterIcon onClick={() => window.open(shareLink('twitter'), '_blank')} />
            <LinkedInIcon onClick={() => window.open(shareLink('linkedin'), '_blank')} />
            <ThreadsIcon onClick={() => window.open(shareLink('threads'), '_blank')} />
            <BlueskyIcon onClick={() => window.open(shareLink('bluesky'), '_blank')} />
            <MastodonIcon onClick={() => window.open(shareLink('mastodon'), '_blank')} />
          </div>
        </div>
      </div>

      <div className="relative space-y-4 rounded-2xl p-6 mt-2 bg-dark border border-solid border-secondary shadow-surface-shadow">
        <h3 className="font-[600] text-lg text-lighter">Create your free Linkie</h3>
        <p className="text-light text-sm pb-3">Showcase all your important links in one place.</p>

        <div className="flex gap-2">
          <Button
            type="button"
            className="w-fit"
            onClick={() => {
              window.open(process.env.HOME_URL, '_blank', 'noreferrer');
            }}
          >
            Create Linkie
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareDialog;
