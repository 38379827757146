import Button from '@/core/design-system/Button';
import { CloseIcon } from '@/assets/icons/close.icon';
import Modal from '@/core/design-system/Modal';
import { ChangeEvent, useState } from 'react';
import useCreateSpreadsheet from '@/modules/link-in-bio/hooks/mailing-integration/useCreateSpreadsheet.hook';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { Field, Input, Label } from '@headlessui/react';
import { CreateGoogleSpreadsheetIcon } from '@/assets/icons/create-google-spreadsheet.icon';

type CreateSpreadsheetDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
  onCreateSpreadsheetSuccess: () => void;
};

const CreateSpreadsheetDialog = ({
  isOpen,
  toggleDialog,
  onCreateSpreadsheetSuccess,
}: CreateSpreadsheetDialogProps) => {
  const { state } = usePlayground();
  const [newSpreadsheetName, setNewSpreadsheetName] = useState<string>('');

  const createSpreadsheet = useCreateSpreadsheet({
    mutationConfig: {
      onSuccess: () => {
        setNewSpreadsheetName('');
        toggleDialog();
        onCreateSpreadsheetSuccess();
      },
    },
  });

  const createSpreadsheetHandler = () => {
    createSpreadsheet.mutate({
      linkInBioId: state.payload._id!,
      spreadsheetName: newSpreadsheetName,
    });
  };

  return (
    <Modal isOpen={isOpen} toggleDialog={toggleDialog} dialogPanelClassName="md:w-[450px]">
      <div className="p-6 rounded-2xl bg-main border border-1 border-secondary text-lighter h-[450px] flex flex-col">
        <div onClick={toggleDialog} className="cursor-pointer absolute right-4 top-6">
          <CloseIcon />
        </div>

        <div>
          <h1 className="font-bold text-lg pb-4">Create Google Spreadsheet</h1>
          <div className="block h-[1px] border-0 border-t border-t-secondary" />
        </div>

        <div className="w-full py-4 flex-grow">
          <div className="flex justify-center">
            <CreateGoogleSpreadsheetIcon />
          </div>

          <Field>
            <Label className="text-sm/6">Spreadsheet name</Label>
            <Input
              value={newSpreadsheetName}
              placeholder="Spreadsheet name"
              className="outline-none mt-2 block w-full rounded-lg border border-solid border-secondary bg-main py-1.5 px-3 text-sm/6 disabled:bg-secondary disabled:text-light focus:bg-dark focus:border-input-focus transition-all duration-200 ease-in-out"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNewSpreadsheetName(e.target.value);
              }}
            />
          </Field>
        </div>

        <div className="flex items-center justify-end gap-2">
          <Button variant="outlined" color="secondary" className="w-max" onClick={toggleDialog}>
            Cancel
          </Button>
          <Button
            variant="filled"
            color="primary"
            className="w-max"
            disabled={!newSpreadsheetName}
            onClick={createSpreadsheetHandler}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSpreadsheetDialog;
