import { CardDto } from '@link-in-bio/domain';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ENUM_CARD_TYPE } from '@/modules/playground/enums/card-type.enum';
import CardPreviewProvider from '@/modules/playground/context/card-preview.context';
import LinkPreviewItem from './links-preview/LinkPreviewItem';
import PostPreviewItem from './posts-preview/PostPreviewItem';
import EmbedPreviewItem from './embed-preview/EmbedPreviewItem';
import { ENUM_LINK_LAYOUT } from '@/modules/playground/enums/link.enum';
import BentoGrid from '@/core/components/bento';
import { motion } from 'framer-motion';

const CardsGrid = () => {
  const { state, dispatch } = usePlayground();
  const [heights, setHeights] = useState<Record<string, number>>({});
  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const sortedCards = (state.payload.cards || []).sort((a: CardDto | any, b: CardDto | any) => b.position - a.position);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = state.payload.cards?.findIndex(
          (card: CardDto | any) => card._id === active.id || card.client_id === active.id,
        );
        const newIndex = state.payload.cards?.findIndex(
          (card: CardDto | any) => card._id === over!.id || card.client_id === over!.id,
        );

        if (oldIndex !== undefined && newIndex !== undefined) {
          const orderedCards = arrayMove(state.payload.cards || [], oldIndex, newIndex);

          dispatch({
            type: PlaygroundActionType.REORDER_CARDS,
            payload: orderedCards,
          });
        }
      }
    },
    [state.payload.cards],
  );

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setHeights((prevHeights) => {
        let changed = false;
        const newHeights = { ...prevHeights };

        entries.forEach((entry) => {
          const id: string = (entry.target as HTMLElement).dataset.id!;
          if (id) {
            const newHeight = entry.contentRect.height;
            if (newHeights[id] !== newHeight) {
              newHeights[id] = newHeight;
              changed = true;
            }
          }
        });

        return changed ? newHeights : prevHeights;
      });
    });

    Object.values(itemRefs.current).forEach((el) => el && observer.observe(el));

    return () => observer.disconnect();
  }, [state.payload._id]);

  const renderCard = (card: CardDto) => {
    const key = `${card._id || card.client_id}`;

    return (
      <motion.div
        key={key}
        ref={(el) => (itemRefs.current[key] = el)}
        data-id={key}
        layout
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="px-1"
      >
        <CardPreviewProvider card={card}>
          {card.type === ENUM_CARD_TYPE.LINK && <LinkPreviewItem link={card} />}
          {card.type === ENUM_CARD_TYPE.POST && <PostPreviewItem post={card} />}
          {card.type === ENUM_CARD_TYPE.EMBED && <EmbedPreviewItem embed={card} />}
        </CardPreviewProvider>
      </motion.div>
    );
  };

  const getCardWidth = (card: CardDto): number => {
    if (card.type === ENUM_CARD_TYPE.LINK) {
      switch (card.link_details?.layout) {
        case ENUM_LINK_LAYOUT.ICON:
          return 1;
        case ENUM_LINK_LAYOUT.SMALL:
          return 3;
        case ENUM_LINK_LAYOUT.LINE:
        case ENUM_LINK_LAYOUT.DEFAULT:
          return 6;
        default:
          return 6;
      }
    }

    if (card.type === ENUM_CARD_TYPE.POST) {
      return 3;
    }

    return 6;
  };

  return (
    <div className="w-full text-center">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={(sortedCards || []).map((link: CardDto | any) => ({
            ...link,
            id: link._id || link.client_id,
          }))}
          strategy={rectSortingStrategy}
          disabled={state.mode !== PlaygroundMode.EDITOR}
        >
          <BentoGrid
            items={(sortedCards || []).map((card: CardDto | any) => ({
              id: card._id || card.client_id,
              element: renderCard(card),
              width: getCardWidth(card),
              height: heights[card._id || card.client_id],
            }))}
            gridCols={6}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default CardsGrid;
