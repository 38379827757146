export const CreateGoogleSpreadsheetIcon = () => (
  <svg width="341" height="151" viewBox="0 0 341 151" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3" clip-path="url(#clip0_2435_11620)">
      <path d="M159.686 26.3086H108.023V93.1367H159.686V26.3086Z" fill="#131313" />
      <path
        d="M159.687 93.1346L133.893 93.2259L108.024 93.2763L107.883 93.2768V93.1346L107.806 26.3065V26.0859H108.025L159.687 26.1368H159.856L159.855 26.306L159.799 59.7664L159.687 93.1346ZM159.687 93.1346L159.575 59.6742L159.518 26.3069L159.686 26.4762L108.024 26.527L108.243 26.3065L108.165 93.1346L108.024 92.9928L133.818 93.0432L159.687 93.1346Z"
        fill="#2E2E2E"
      />
      <path d="M113.676 40.5904L133.856 40.5391L154.037 40.5904L133.856 40.6421L113.676 40.5904Z" fill="white" />
      <path d="M113.676 42.2466L133.856 42.1953L154.037 42.2466L133.856 42.2979L113.676 42.2466Z" fill="white" />
      <path d="M113.676 43.9111L133.856 43.8594L154.037 43.9111L133.856 43.9624L113.676 43.9111Z" fill="white" />
      <path d="M113.676 45.5669L133.856 45.5156L154.037 45.5669L133.856 45.6187L113.676 45.5669Z" fill="white" />
      <path d="M113.676 47.2232L133.856 47.1719L154.037 47.2232L133.856 47.2745L113.676 47.2232Z" fill="white" />
      <path d="M113.676 48.8838L133.856 48.832L154.037 48.8838L133.856 48.9351L113.676 48.8838Z" fill="white" />
      <path d="M113.676 50.5396L133.856 50.4883L154.037 50.5396L133.856 50.5909L113.676 50.5396Z" fill="white" />
      <path d="M113.676 52.1958L133.856 52.1445L154.037 52.1958L133.856 52.2472L113.676 52.1958Z" fill="white" />
      <path d="M113.676 53.8604L133.856 53.8086L154.037 53.8604L133.856 53.9117L113.676 53.8604Z" fill="white" />
      <path d="M113.676 55.5162L133.856 55.4648L154.037 55.5162L133.856 55.5679L113.676 55.5162Z" fill="white" />
      <path d="M113.676 57.1724L133.856 57.1211L154.037 57.1724L133.856 57.2237L113.676 57.1724Z" fill="white" />
      <path d="M113.676 58.8365L133.856 58.7852L154.037 58.8365L133.856 58.8878L113.676 58.8365Z" fill="white" />
      <path d="M113.676 60.4927L133.856 60.4414L154.037 60.4927L133.856 60.5445L113.676 60.4927Z" fill="white" />
      <path d="M113.676 62.149L133.856 62.0977L154.037 62.149L133.856 62.2003L113.676 62.149Z" fill="white" />
      <path d="M113.676 63.8091L133.856 63.7578L154.037 63.8091L133.856 63.8604L113.676 63.8091Z" fill="white" />
      <path d="M121.004 58.7852H117.943V65.7454H121.004V58.7852Z" fill="#353535" />
      <path d="M126.682 55.0664H123.621V65.7395H126.682V55.0664Z" fill="#353535" />
      <path d="M132.358 50.9141H129.298V65.7424H132.358V50.9141Z" fill="#353535" />
      <path d="M138.037 49.1289H134.977V65.7428H138.037V49.1289Z" fill="#353535" />
      <path d="M143.716 45.9453H140.655V65.7404H143.716V45.9453Z" fill="#353535" />
      <path d="M149.395 42.8008H146.334V65.7393H149.395V42.8008Z" fill="#353535" />
      <path
        d="M114.771 39.4258L114.85 46.0048L114.905 52.5838C114.942 56.97 114.953 61.3557 114.96 65.7418L114.771 65.551L124.587 65.5429L134.404 65.5654L144.22 65.6284L154.036 65.7418L144.22 65.8548L134.404 65.9178L124.587 65.9403L114.771 65.9322H114.581L114.582 65.7414C114.588 61.3557 114.599 56.9695 114.637 52.5834L114.691 46.0043L114.771 39.4258Z"
        fill="white"
      />
      <path d="M121.004 67.1367H117.943V67.4153H121.004V67.1367Z" fill="white" />
      <path d="M126.682 67.1367H123.621V67.4153H126.682V67.1367Z" fill="white" />
      <path d="M132.358 67.1367H129.298V67.4153H132.358V67.1367Z" fill="white" />
      <path d="M138.037 67.1367H134.977V67.4153H138.037V67.1367Z" fill="white" />
      <path d="M143.716 67.1367H140.655V67.4153H143.716V67.1367Z" fill="white" />
      <path d="M149.395 67.1367H146.334V67.4153H149.395V67.1367Z" fill="white" />
      <path
        d="M118.289 59.6586C119.786 59.6383 121.279 59.5177 122.759 59.3165C124.24 59.1239 125.712 58.8615 127.164 58.5167C130.063 57.824 132.914 56.8846 135.572 55.5321C138.248 54.2241 140.743 52.5345 142.92 50.4906L143.334 50.1125L143.726 49.7124L144.507 48.9072C144.989 48.3365 145.506 47.7923 145.945 47.1842C146.893 46.0207 147.68 44.7375 148.452 43.4453L148.476 43.4584C147.115 46.1454 145.343 48.6493 143.158 50.7431C142.09 51.8157 140.891 52.7465 139.659 53.6188C138.426 54.4929 137.113 55.249 135.764 55.9241C133.057 57.2596 130.176 58.2183 127.236 58.8421C124.293 59.452 121.285 59.785 118.289 59.6586Z"
        fill="#2F2F2F"
      />
      <path
        d="M149.317 45.5906L148.893 45.6977L148.373 43.5927L146.327 44.2696L146.191 43.851L148.683 43.0273L149.317 45.5906Z"
        fill="#2F2F2F"
      />
      <path d="M112.812 77.7969H154.897V76.0807H112.812V77.7969Z" fill="#2E2E2E" />
      <path d="M112.812 82.1289H136.506V80.4127H112.812V82.1289Z" fill="#2E2E2E" />
      <path d="M122.007 35.4023H145.701V33.6861H122.007V35.4023Z" fill="#2E2E2E" />
      <path
        d="M149.92 109.43L99.3149 119.909L85.9658 54.4483L123.54 46.668L139.115 56.4431L149.92 109.43Z"
        fill="#353535"
      />
      <path
        opacity="0.8"
        d="M149.92 109.43L99.3149 119.909L85.9658 54.4483L123.54 46.668L139.115 56.4431L149.92 109.43Z"
        fill="black"
      />
      <path d="M123.54 46.668L139.115 56.4431L126.084 59.141L123.54 46.668Z" fill="#353535" />
      <path d="M93.4867 66.0961L121.622 60.2695L121.279 58.5885L93.1438 64.4151L93.4867 66.0961Z" fill="#2E2E2E" />
      <path d="M94.3509 70.3383L122.486 64.5117L122.143 62.8307L94.0081 68.6572L94.3509 70.3383Z" fill="#2E2E2E" />
      <path d="M95.2172 74.5805L123.353 68.7539L123.01 67.0729L94.8743 72.8994L95.2172 74.5805Z" fill="#2E2E2E" />
      <path d="M96.0806 78.8259L137.304 70.2891L136.961 68.608L95.7378 77.1449L96.0806 78.8259Z" fill="#2E2E2E" />
      <path d="M96.9469 83.0681L138.17 74.5312L137.827 72.8502L96.604 81.3871L96.9469 83.0681Z" fill="#2E2E2E" />
      <path d="M97.8111 87.3103L139.034 78.7734L138.691 77.0924L97.4683 85.6293L97.8111 87.3103Z" fill="#2E2E2E" />
      <path d="M98.6773 91.5525L139.9 83.0156L139.558 81.3346L98.3345 89.8715L98.6773 91.5525Z" fill="#2E2E2E" />
      <path d="M99.5416 95.7947L140.765 87.2578L140.422 85.5768L99.1987 94.1137L99.5416 95.7947Z" fill="#2E2E2E" />
      <path d="M100.408 100.037L141.631 91.5L141.288 89.819L100.065 98.3558L100.408 100.037Z" fill="#2E2E2E" />
      <path d="M101.272 104.279L142.495 95.7422L142.152 94.0612L100.929 102.598L101.272 104.279Z" fill="#2E2E2E" />
      <path d="M102.138 108.529L143.361 99.9922L143.018 98.3112L101.795 106.848L102.138 108.529Z" fill="#2E2E2E" />
      <path d="M103.003 112.771L144.226 104.234L143.883 102.553L102.66 111.09L103.003 112.771Z" fill="#2E2E2E" />
      <path
        d="M253.691 61.8542C234.757 63.24 215.861 64.6263 197.006 66.0121C190.55 41.2836 197.466 16.5545 192.437 -8.17407C211.371 -9.5599 230.267 -10.9462 249.122 -12.332C255.571 12.3966 248.654 37.1256 253.691 61.8542Z"
        fill="#131313"
      />
      <path
        d="M253.691 61.8524C234.766 63.2851 215.882 64.7173 197.043 66.1499C196.998 66.1535 196.952 66.1567 196.907 66.1603C196.894 66.1135 196.882 66.0671 196.87 66.0203C190.383 41.2935 197.269 16.5667 192.217 -8.16005C192.202 -8.23477 192.186 -8.30949 192.171 -8.3842C192.245 -8.3896 192.318 -8.395 192.392 -8.39995C211.326 -9.76419 230.224 -11.1284 249.081 -12.4927C249.133 -12.4963 249.185 -12.5003 249.237 -12.5039C249.251 -12.4512 249.265 -12.3981 249.278 -12.3455C255.678 12.3872 248.706 37.1198 253.691 61.8524ZM253.691 61.8524C248.602 37.1274 255.463 12.4025 248.967 -12.3225C249.032 -12.2735 249.098 -12.2244 249.163 -12.1753C230.311 -10.7674 211.416 -9.36 192.482 -7.95211C192.54 -8.03223 192.598 -8.1119 192.657 -8.19201C197.664 16.5388 190.718 41.2697 197.143 66.0005C197.086 65.9573 197.028 65.9141 196.97 65.8713C215.839 64.5314 234.748 63.1919 253.691 61.8524Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.278 2.42106C209.896 1.6505 220.505 0.879935 231.106 0.109375C231.174 0.744457 231.237 1.37954 231.294 2.01417C220.679 2.78473 210.056 3.55529 199.426 4.32585C199.382 3.69077 199.333 3.05614 199.278 2.42106Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.596 7.23356C210.243 6.463 220.885 5.69243 231.519 4.92188C231.56 5.55696 231.596 6.19204 231.628 6.82667C220.983 7.59723 210.33 8.36779 199.673 9.13835C199.651 8.50372 199.625 7.86864 199.596 7.23356Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.743 12.0382C210.414 11.2677 221.081 10.4971 231.742 9.72656C231.761 10.3616 231.776 10.9967 231.788 11.6318C221.118 12.4024 210.444 13.1729 199.764 13.9435C199.761 13.3084 199.753 12.6733 199.743 12.0382Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.764 16.8479C215.425 15.7191 231.078 14.5898 246.722 13.4609C246.727 14.096 246.73 14.7311 246.73 15.3657C231.076 16.4946 215.414 17.6239 199.746 18.7527C199.754 18.1181 199.76 17.483 199.764 16.8479Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.699 21.6526C215.375 20.5237 231.047 19.3945 246.714 18.2656C246.707 18.9007 246.698 19.5358 246.688 20.1709C231.016 21.2997 215.339 22.429 199.659 23.5578C199.673 22.9223 199.687 22.2877 199.699 21.6526Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.59 26.4651C215.272 25.3362 230.953 24.207 246.632 23.0781C246.618 23.7132 246.603 24.3483 246.588 24.9829C230.908 26.1118 215.225 27.241 199.544 28.3699C199.559 27.7348 199.575 27.1002 199.59 26.4651Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.478 31.2698C215.155 30.1409 230.835 29.0116 246.517 27.8828C246.501 28.5179 246.486 29.153 246.472 29.7881C230.792 30.9169 215.114 32.0462 199.441 33.175C199.452 32.5399 199.465 31.9049 199.478 31.2698Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.403 36.0784C215.067 34.9495 230.737 33.8202 246.411 32.6914C246.4 33.3265 246.39 33.9616 246.381 34.5962C230.712 35.725 215.049 36.8543 199.394 37.9832C199.394 37.3485 199.398 36.7134 199.403 36.0784Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.409 40.883C215.049 39.7542 230.699 38.6249 246.357 37.4961C246.355 38.1312 246.355 38.7663 246.358 39.4013C230.71 40.5302 215.071 41.6595 199.443 42.7883C199.428 42.1532 199.417 41.5181 199.409 40.883Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.534 45.6955C215.141 44.5667 230.761 43.4374 246.392 42.3086C246.405 42.9437 246.421 43.5788 246.441 44.2138C230.823 45.3427 215.218 46.472 199.627 47.6008C199.591 46.9657 199.561 46.3306 199.534 45.6955Z"
        fill="#2E2E2E"
      />
      <path
        d="M199.823 50.5002C215.387 49.3714 230.968 48.2421 246.563 47.1133C246.596 47.7484 246.633 48.3834 246.676 49.0185C231.098 50.1474 215.536 51.2766 199.992 52.4055C199.93 51.7704 199.874 51.1353 199.823 50.5002Z"
        fill="#2E2E2E"
      />
      <path
        d="M200.315 55.3127C215.826 54.1839 231.358 53.0546 246.907 51.9258C246.966 52.5609 247.031 53.1959 247.101 53.831C231.573 54.9599 216.064 56.0892 200.576 57.218C200.482 56.5829 200.396 55.9478 200.315 55.3127Z"
        fill="#2E2E2E"
      />
      <path
        d="M245.63 88.325C226.084 84.9848 206.78 81.6447 187.658 78.3041C190.131 53.8852 202.391 29.4658 201.763 5.04688C221.308 8.38702 240.613 11.7272 259.735 15.0678C257.261 39.4867 245.001 63.9056 245.63 88.325Z"
        fill="#353535"
      />
      <path
        opacity="0.8"
        d="M245.63 88.325C226.084 84.9848 206.78 81.6447 187.658 78.3041C190.131 53.8852 202.391 29.4658 201.763 5.04688C221.308 8.38702 240.613 11.7272 259.735 15.0678C257.261 39.4867 245.001 63.9056 245.63 88.325Z"
        fill="black"
      />
      <path
        d="M206.574 16.8203C217.17 18.6774 227.713 20.5345 238.213 22.3916C238.105 23.0185 237.992 23.6455 237.875 24.273C227.403 22.4159 216.892 20.5588 206.33 18.7017C206.417 18.0743 206.498 17.4473 206.574 16.8203Z"
        fill="#2E2E2E"
      />
      <path
        d="M205.894 21.5664C216.407 23.4235 226.875 25.2806 237.308 27.1377C237.177 27.7646 237.043 28.3916 236.904 29.0186C226.494 27.1615 216.052 25.3044 205.568 23.4473C205.682 22.8204 205.79 22.1934 205.894 21.5664Z"
        fill="#2E2E2E"
      />
      <path
        d="M205.017 26.3164C215.461 28.1735 225.868 30.0306 236.248 31.8877C236.1 32.5146 235.948 33.1416 235.795 33.7691C225.431 31.912 215.044 30.0549 204.624 28.1978C204.759 27.5704 204.89 26.9434 205.017 26.3164Z"
        fill="#2E2E2E"
      />
      <path
        d="M203.982 31.0625C219.203 33.7833 234.364 36.5046 249.497 39.2254C249.331 39.8524 249.164 40.4794 248.995 41.1068C233.874 38.3855 218.732 35.6647 203.537 32.9439C203.689 32.3165 203.837 31.6895 203.982 31.0625Z"
        fill="#2E2E2E"
      />
      <path
        d="M202.826 35.8125C217.988 38.5333 233.108 41.2546 248.217 43.9754C248.046 44.6024 247.874 45.2294 247.702 45.8568C232.596 43.1355 217.486 40.4147 202.342 37.6939C202.506 37.0665 202.667 36.4395 202.826 35.8125Z"
        fill="#2E2E2E"
      />
      <path
        d="M201.585 40.5586C216.709 43.2794 231.809 46.0007 246.917 48.7215C246.746 49.3485 246.575 49.9754 246.405 50.6024C231.291 47.8816 216.193 45.1603 201.078 42.4395C201.248 41.8126 201.417 41.1856 201.585 40.5586Z"
        fill="#2E2E2E"
      />
      <path
        d="M200.296 45.3086C215.403 48.0299 230.505 50.7507 245.633 53.4715C245.466 54.0985 245.3 54.7255 245.136 55.3529C229.995 52.6321 214.887 49.9108 199.78 47.19C199.953 46.5626 200.125 45.9356 200.296 45.3086Z"
        fill="#2E2E2E"
      />
      <path
        d="M198.996 50.0547C214.108 52.776 229.232 55.4968 244.401 58.2176C244.243 58.8446 244.088 59.4715 243.936 60.099C228.745 57.3782 213.606 54.6569 198.486 51.9361C198.656 51.3091 198.826 50.6817 198.996 50.0547Z"
        fill="#2E2E2E"
      />
      <path
        d="M197.722 54.8008C212.859 57.522 228.027 60.2429 243.258 62.9637C243.115 63.5907 242.975 64.2176 242.839 64.8451C227.578 62.1238 212.386 59.403 197.232 56.6817C197.394 56.0547 197.557 55.4278 197.722 54.8008Z"
        fill="#2E2E2E"
      />
      <path
        d="M196.512 59.5508C211.696 62.2716 226.93 64.9929 242.244 67.7137C242.121 68.3407 242 68.9676 241.886 69.5951C226.532 66.8738 211.267 64.153 196.059 61.4322C196.207 60.8047 196.358 60.1778 196.512 59.5508Z"
        fill="#2E2E2E"
      />
      <path
        d="M195.402 64.2969C210.654 67.0181 225.974 69.739 241.393 72.4598C241.293 73.0867 241.199 73.7137 241.109 74.3412C225.643 71.6203 210.283 68.8991 194.998 66.1783C195.129 65.5508 195.263 64.9239 195.402 64.2969Z"
        fill="#2E2E2E"
      />
      <path
        d="M194.43 69.0469C209.772 71.7681 225.199 74.4889 240.744 77.2098C240.673 77.8367 240.608 78.4637 240.55 79.0907C224.95 76.3699 209.474 73.6486 194.091 70.9278C194.199 70.3013 194.312 69.6739 194.43 69.0469Z"
        fill="#2E2E2E"
      />
      <path
        d="M301.81 122.618C282.249 116.98 263.125 111.406 244.299 105.886C249.034 83.5859 262.298 61.7393 263.201 39.1055C282.744 44.4166 301.854 49.7385 320.668 55.0766C315.87 77.7401 302.62 99.8042 301.81 122.618Z"
        fill="#131313"
      />
      <path
        d="M301.809 122.62C282.248 117.024 263.113 111.492 244.272 106.013C244.226 106 244.181 105.987 244.136 105.974C244.145 105.932 244.154 105.889 244.163 105.847C248.878 83.5409 262.117 61.687 262.974 39.0451C262.977 38.9767 262.979 38.9083 262.982 38.8398C263.058 38.8605 263.134 38.8812 263.21 38.9015C282.766 44.2329 301.883 49.5741 320.699 54.9325C320.751 54.9473 320.803 54.9622 320.855 54.977C320.844 55.0257 320.834 55.0738 320.824 55.1224C315.964 77.7707 302.674 99.8207 301.809 122.62ZM301.809 122.62C302.566 99.7901 315.774 77.7122 320.513 55.0338C320.555 55.0968 320.596 55.1602 320.637 55.2232C301.826 49.9045 282.723 44.6028 263.193 39.3115C263.271 39.2638 263.35 39.2161 263.429 39.168C262.479 61.7942 249.19 83.634 244.435 105.927C244.398 105.872 244.362 105.816 244.326 105.762C263.136 111.323 282.251 116.939 301.809 122.62Z"
        fill="#2E2E2E"
      />
      <path
        d="M298.711 81.1848C295.803 89.0101 286.991 93.372 278.768 90.9248C270.503 88.4817 266.234 80.2094 269.074 72.4246C271.887 64.629 280.39 60.214 288.718 62.6157C297.078 65.026 301.656 73.3523 298.711 81.1848Z"
        fill="#151515"
      />
      <path
        d="M283.901 76.796C280.528 80.6763 277.118 84.5484 273.79 88.4201C268.772 84.6344 266.968 78.2597 269.074 72.4261C271.869 64.6827 280.446 60.2317 288.718 62.6172C297.023 65.0112 301.637 73.4064 298.711 81.1862C293.775 79.7207 288.84 78.2575 283.901 76.796Z"
        fill="#2E2E2E"
      />
      <path
        d="M283.901 76.796C286.841 80.7789 289.749 84.7645 292.72 88.7586C288.641 91.5231 283.482 92.3278 278.768 90.9263C270.557 88.4993 266.253 80.1591 269.073 72.4261C271.869 64.6827 280.446 60.2317 288.717 62.6172C297.023 65.0112 301.637 73.4064 298.71 81.1862C293.775 79.7207 288.84 78.2575 283.901 76.796Z"
        fill="#2E2E2E"
      />
      <path
        d="M283.902 76.7965C278.724 77.6692 273.543 78.5392 268.361 79.4066C268.011 77.0796 268.263 74.6864 269.074 72.4261C271.869 64.6827 280.446 60.2317 288.718 62.6172C297.023 65.0112 301.637 73.4064 298.711 81.1862C293.776 79.7212 288.84 78.2579 283.902 76.7965Z"
        fill="#353535"
      />
      <path
        d="M267.626 48.9219C275.564 51.1444 283.443 53.3697 291.275 55.5981C291.139 56.1765 290.997 56.7544 290.85 57.3319C283.049 55.1026 275.202 52.8768 267.302 50.6534C267.416 50.0764 267.524 49.4993 267.626 48.9219Z"
        fill="#2E2E2E"
      />
      <path
        d="M265.832 93.5117C276.293 96.6187 286.803 99.7392 297.387 102.875C297.228 103.451 297.073 104.028 296.924 104.604C286.302 101.462 275.761 98.3363 265.275 95.2243C265.458 94.6532 265.643 94.0824 265.832 93.5117Z"
        fill="#2E2E2E"
      />
      <path
        d="M264.465 97.8359C274.995 100.956 285.589 104.091 296.272 107.243C296.137 107.82 296.008 108.398 295.884 108.976C285.156 105.818 274.523 102.677 263.961 99.5508C264.125 98.9792 264.293 98.4076 264.465 97.8359Z"
        fill="#2E2E2E"
      />
      <path
        d="M263.24 102.164C273.857 105.299 284.554 108.45 295.356 111.619C295.25 112.198 295.149 112.778 295.056 113.358C284.202 110.182 273.459 107.024 262.802 103.883C262.943 103.31 263.089 102.737 263.24 102.164Z"
        fill="#2E2E2E"
      />
      <path
        d="M259.649 93.5573C258.379 93.1815 257.11 92.8057 255.842 92.4303C256.032 91.8609 256.224 91.2916 256.419 90.7227C257.685 91.0976 258.951 91.473 260.218 91.8479C260.026 92.4173 259.836 92.9871 259.649 93.5573Z"
        fill="#353535"
      />
      <path
        d="M258.294 97.877C257.016 97.4994 255.738 97.1222 254.462 96.7451C254.636 96.1748 254.813 95.605 254.993 95.0352C256.266 95.4119 257.54 95.7882 258.815 96.1653C258.638 96.7356 258.464 97.3063 258.294 97.877Z"
        fill="#151515"
      />
      <path
        d="M257.085 102.202C255.796 101.822 254.509 101.443 253.222 101.064C253.375 100.493 253.533 99.9218 253.694 99.3516C254.977 99.7296 256.26 100.108 257.545 100.487C257.387 101.058 257.234 101.63 257.085 102.202Z"
        fill="#2E2E2E"
      />
      <path
        d="M42.2815 122.618C61.0167 116.98 79.3344 111.406 97.3662 105.886C92.8309 83.5859 80.1263 61.7393 79.2612 39.1055C60.5427 44.4166 42.2392 49.7385 24.2189 55.0766C28.815 77.7401 41.5055 99.8042 42.2815 122.618Z"
        fill="#131313"
      />
      <path
        d="M42.2811 122.62C61.0176 117.024 79.345 111.492 97.391 106.013C97.4346 106 97.4778 105.987 97.5215 105.974C97.5129 105.932 97.5044 105.889 97.4958 105.847C92.9793 83.5409 80.2987 61.687 79.4777 39.0451C79.4751 38.9767 79.473 38.9083 79.4704 38.8398C79.3977 38.8605 79.3249 38.8812 79.2522 38.9015C60.5209 44.2329 42.2105 49.5741 24.1881 54.9325C24.1384 54.9473 24.0888 54.9622 24.0392 54.977C24.049 55.0257 24.0589 55.0738 24.0687 55.1224C28.7238 77.7707 41.4533 99.8207 42.2811 122.62ZM42.2811 122.62C41.5564 99.7901 28.9053 77.7122 24.3665 55.0338C24.3267 55.0968 24.2873 55.1602 24.2475 55.2232C42.2653 49.9045 60.5624 44.6028 79.2685 39.3115C79.1932 39.2638 79.1179 39.2161 79.0426 39.168C79.9521 61.7942 92.6803 83.634 97.2349 105.927C97.2699 105.872 97.3046 105.816 97.3397 105.762C79.3224 111.323 61.0146 116.939 42.2811 122.62Z"
        fill="#2E2E2E"
      />
      <path
        d="M45.2502 81.1848C48.0353 89.0101 56.4754 93.372 64.3516 90.9248C72.2685 88.4817 76.3572 80.2094 73.6371 72.4246C70.9426 64.629 62.7986 60.214 54.8219 62.6157C46.8143 65.026 42.4292 73.3523 45.2502 81.1848Z"
        fill="#151515"
      />
      <path
        d="M59.4347 76.796C62.6652 80.6763 65.932 84.5484 69.1197 88.4201C73.9259 84.6344 75.6534 78.2597 73.6367 72.4261C70.9593 64.6827 62.7439 60.2317 54.8215 62.6172C46.8661 65.0112 42.4471 73.4064 45.2498 81.1862C49.9772 79.7207 54.7042 78.2575 59.4347 76.796Z"
        fill="#2E2E2E"
      />
      <path
        d="M59.4346 76.796C56.6187 80.7789 53.834 84.7645 50.9881 88.7586C54.895 91.5231 59.8359 92.3278 64.3516 90.9263C72.2158 88.4993 76.3387 80.1591 73.637 72.4261C70.9597 64.6827 62.7442 60.2317 54.8218 62.6172C46.8665 65.0112 42.4475 73.4064 45.2502 81.1862C49.9772 79.7207 54.7042 78.2575 59.4346 76.796Z"
        fill="#2E2E2E"
      />
      <path
        d="M59.4343 76.7965C64.3936 77.6692 69.3564 78.5392 74.3195 79.4066C74.655 77.0796 74.4132 74.6864 73.6367 72.4261C70.9594 64.6827 62.744 60.2317 54.8215 62.6172C46.8662 65.0112 42.4472 73.4064 45.2499 81.1862C49.9769 79.7212 54.7039 78.2579 59.4343 76.7965Z"
        fill="#353535"
      />
      <path
        d="M75.0224 48.9219C67.4196 51.1444 59.8724 53.3697 52.3709 55.5981C52.5019 56.1765 52.6375 56.7544 52.7778 57.3319C60.2501 55.1026 67.7657 52.8768 75.333 50.6534C75.2239 50.0764 75.12 49.4993 75.0224 48.9219Z"
        fill="#2E2E2E"
      />
      <path
        d="M76.7394 93.5117C66.7198 96.6187 56.6536 99.7392 46.5159 102.875C46.6678 103.451 46.8162 104.028 46.9595 104.604C57.1327 101.462 67.2289 98.3363 77.2725 95.2243C77.0975 94.6532 76.9199 94.0824 76.7394 93.5117Z"
        fill="#2E2E2E"
      />
      <path
        d="M78.0502 97.8359C67.9647 100.956 57.818 104.091 47.585 107.243C47.7142 107.82 47.8382 108.398 47.9572 108.976C58.2326 105.818 68.4165 102.677 78.5332 99.5508C78.3762 98.9792 78.2149 98.4076 78.0502 97.8359Z"
        fill="#2E2E2E"
      />
      <path
        d="M79.2215 102.164C69.0526 105.299 58.8071 108.45 48.4606 111.619C48.5625 112.198 48.6587 112.778 48.7486 113.358C59.1446 110.182 69.4342 107.024 79.6416 103.883C79.506 103.31 79.3661 102.737 79.2215 102.164Z"
        fill="#2E2E2E"
      />
      <path
        d="M82.6633 93.5573C83.8795 93.1815 85.0946 92.8057 86.3096 92.4303C86.1277 91.8609 85.9434 91.2916 85.7564 90.7227C84.5444 91.0976 83.3315 91.473 82.1178 91.8479C82.3022 92.4173 82.484 92.9871 82.6633 93.5573Z"
        fill="#353535"
      />
      <path
        d="M83.9606 97.877C85.185 97.4994 86.4081 97.1222 87.6309 96.7451C87.4644 96.1748 87.2946 95.605 87.1217 95.0352C85.9025 95.4119 84.6827 95.7882 83.4617 96.1653C83.6312 96.7356 83.7976 97.3063 83.9606 97.877Z"
        fill="#151515"
      />
      <path
        d="M85.1187 102.202C86.3534 101.822 87.5868 101.443 88.8193 101.064C88.6726 100.493 88.5216 99.9218 88.3667 99.3516C87.1384 99.7296 85.9093 100.108 84.6789 100.487C84.8295 101.058 84.9762 101.63 85.1187 102.202Z"
        fill="#2E2E2E"
      />
      <path
        d="M182.318 33.0172C182.142 32.4181 182.102 31.8186 182.095 31.2195C182.101 30.6205 182.141 30.0209 182.318 29.4219C182.495 30.0209 182.535 30.6205 182.541 31.2195C182.534 31.8186 182.494 32.4181 182.318 33.0172Z"
        fill="#2F2F2F"
      />
      <path
        d="M178.685 34.0786C178.236 33.6483 177.904 33.1491 177.6 32.6342C177.308 32.1121 177.045 31.5729 176.901 30.9648C177.352 31.3947 177.683 31.8938 177.987 32.4092C178.278 32.9322 178.541 33.471 178.685 34.0786Z"
        fill="#2F2F2F"
      />
      <path
        d="M176.065 36.8211C175.462 36.6753 174.927 36.4102 174.408 36.1172C173.896 35.812 173.401 35.4776 172.975 35.0234C173.578 35.1684 174.113 35.4335 174.631 35.7274C175.143 36.0334 175.638 36.3674 176.065 36.8211Z"
        fill="#2F2F2F"
      />
      <path
        d="M175.158 40.5219C174.563 40.6993 173.968 40.7393 173.374 40.747C172.779 40.7402 172.184 40.7006 171.59 40.5219C172.184 40.3437 172.779 40.3036 173.374 40.2969C173.968 40.3045 174.563 40.3441 175.158 40.5219Z"
        fill="#2F2F2F"
      />
      <path
        d="M176.208 44.1836C175.781 44.6368 175.286 44.9713 174.775 45.2773C174.257 45.5712 173.722 45.8363 173.118 45.9813C173.545 45.5271 174.04 45.1927 174.552 44.8875C175.07 44.5945 175.605 44.3294 176.208 44.1836Z"
        fill="#2F2F2F"
      />
      <path
        d="M178.935 46.8281C178.791 47.4358 178.528 47.975 178.237 48.4975C177.934 49.0133 177.602 49.5125 177.151 49.9419C177.295 49.3338 177.558 48.7946 177.85 48.2725C178.154 47.7571 178.486 47.258 178.935 46.8281Z"
        fill="#2F2F2F"
      />
      <path
        d="M182.606 47.7383C182.782 48.3374 182.822 48.9369 182.829 49.536C182.823 50.135 182.783 50.7346 182.606 51.3336C182.429 50.7346 182.39 50.135 182.383 49.536C182.39 48.9369 182.43 48.3378 182.606 47.7383Z"
        fill="#2F2F2F"
      />
      <path
        d="M186.239 46.6797C186.689 47.11 187.021 47.6091 187.325 48.124C187.616 48.6461 187.879 49.1854 188.023 49.7934C187.573 49.3636 187.241 48.8644 186.938 48.3491C186.647 47.8265 186.384 47.2873 186.239 46.6797Z"
        fill="#2F2F2F"
      />
      <path
        d="M188.86 43.9297C189.463 44.0755 189.998 44.3406 190.517 44.6336C191.029 44.9388 191.524 45.2732 191.95 45.7274C191.347 45.5824 190.812 45.3173 190.294 45.0234C189.783 44.7173 189.287 44.3829 188.86 43.9297Z"
        fill="#2F2F2F"
      />
      <path
        d="M189.768 40.229C190.362 40.0516 190.957 40.0116 191.552 40.0039C192.146 40.0107 192.741 40.0503 193.336 40.229C192.741 40.4076 192.146 40.4472 191.552 40.454C190.957 40.4463 190.362 40.4063 189.768 40.229Z"
        fill="#2F2F2F"
      />
      <path
        d="M188.717 36.575C189.144 36.1218 189.639 35.7874 190.15 35.4813C190.668 35.1874 191.203 34.9223 191.807 34.7773C191.38 35.2315 190.885 35.5659 190.373 35.8711C189.854 36.1641 189.32 36.4292 188.717 36.575Z"
        fill="#2F2F2F"
      />
      <path
        d="M185.99 33.9341C186.135 33.3264 186.398 32.7872 186.689 32.2647C186.992 31.7489 187.324 31.2502 187.774 30.8203C187.63 31.4284 187.367 31.9676 187.076 32.4897C186.771 33.0051 186.44 33.5038 185.99 33.9341Z"
        fill="#2F2F2F"
      />
      <path
        d="M184.143 114.938H207.096C207.096 114.938 208.234 112.575 208.234 110.335C208.234 105.703 208.234 91.8086 208.234 91.8086H185.28C185.28 91.8086 185.28 105.883 185.28 110.574C185.28 112.451 184.143 114.938 184.143 114.938Z"
        fill="#151515"
      />
      <path
        d="M188.091 97.2706C190.79 97.0474 193.489 96.9956 196.188 96.9844C198.888 96.9947 201.587 97.046 204.286 97.2706C201.587 97.4952 198.888 97.5465 196.188 97.5569C193.489 97.5456 190.79 97.4939 188.091 97.2706Z"
        fill="black"
      />
      <path
        d="M188.091 100.318C190.518 100.094 192.947 100.043 195.374 100.031C197.802 100.042 200.23 100.093 202.658 100.318C200.23 100.542 197.802 100.594 195.374 100.604C192.947 100.592 190.519 100.541 188.091 100.318Z"
        fill="black"
      />
      <path
        d="M188.091 103.372C190.214 103.149 192.338 103.097 194.462 103.086C196.585 103.096 198.709 103.148 200.832 103.372C198.709 103.597 196.586 103.648 194.462 103.658C192.338 103.647 190.214 103.595 188.091 103.372Z"
        fill="black"
      />
      <path
        d="M188.091 106.42C190.79 106.196 193.489 106.145 196.188 106.133C198.888 106.143 201.587 106.195 204.286 106.42C201.587 106.644 198.888 106.695 196.188 106.706C193.489 106.694 190.79 106.642 188.091 106.42Z"
        fill="black"
      />
    </g>
    <rect x="147.743" y="37.5234" width="45.5135" height="47.5135" rx="6.06757" fill="#171717" />
    <rect
      x="147.743"
      y="37.5234"
      width="45.5135"
      height="47.5135"
      rx="6.06757"
      stroke="url(#paint0_linear_2435_11620)"
    />
    <g clip-path="url(#clip1_2435_11620)">
      <mask id="mask0_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2435_11620)">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L176.75 50.6257L173.278 45.7656Z"
          fill="#0F9D58"
        />
      </g>
      <mask id="mask1_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2435_11620)">
        <path
          d="M164.947 60.6914V70.7587H176.056V60.6914H164.947ZM169.807 69.3701H166.336V67.6343H169.807V69.3701ZM169.807 66.5929H166.336V64.8572H169.807V66.5929ZM169.807 63.8157H166.336V62.08H169.807V63.8157ZM174.667 69.3701H171.196V67.6343H174.667V69.3701ZM174.667 66.5929H171.196V64.8572H174.667V66.5929ZM174.667 63.8157H171.196V62.08H174.667V63.8157Z"
          fill="#F1F1F1"
        />
      </g>
      <mask id="mask2_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2435_11620)">
        <path d="M173.888 53.4922L181.61 61.2127V54.1014L173.888 53.4922Z" fill="url(#paint1_linear_2435_11620)" />
      </g>
      <mask id="mask3_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2435_11620)">
        <path
          d="M173.277 45.7656V52.0143C173.277 53.1651 174.209 54.0971 175.36 54.0971H181.609L173.277 45.7656Z"
          fill="#87CEAC"
        />
      </g>
      <mask id="mask4_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_2435_11620)">
        <path
          d="M161.475 45.7656C160.33 45.7656 159.393 46.7029 159.393 47.8485V48.0221C159.393 46.8765 160.33 45.9392 161.475 45.9392H173.278V45.7656H161.475Z"
          fill="white"
          fill-opacity="0.2"
        />
      </g>
      <mask id="mask5_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_2435_11620)">
        <path
          d="M179.527 76.1415H161.475C160.33 76.1415 159.393 75.2042 159.393 74.0586V74.2322C159.393 75.3777 160.33 76.315 161.475 76.315H179.527C180.673 76.315 181.61 75.3777 181.61 74.2322V74.0586C181.61 75.2042 180.673 76.1415 179.527 76.1415Z"
          fill="#263238"
          fill-opacity="0.2"
        />
      </g>
      <mask id="mask6_2435_11620" maskUnits="userSpaceOnUse" x="159" y="45" width="23" height="32">
        <path
          d="M173.278 45.7656H161.475C160.33 45.7656 159.393 46.7029 159.393 47.8485V74.2316C159.393 75.3772 160.33 76.3145 161.475 76.3145H179.527C180.673 76.3145 181.61 75.3772 181.61 74.2316V54.0971L173.278 45.7656Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_2435_11620)">
        <path
          d="M175.36 54.0985C174.209 54.0985 173.277 53.1664 173.277 52.0156V52.1892C173.277 53.34 174.209 54.2721 175.36 54.2721H181.609V54.0985H175.36Z"
          fill="#263238"
          fill-opacity="0.1"
        />
      </g>
      <path
        d="M173.277 45.7734H161.474C160.329 45.7734 159.392 46.7107 159.392 47.8563V74.2395C159.392 75.385 160.329 76.3223 161.474 76.3223H179.526C180.672 76.3223 181.609 75.385 181.609 74.2395V54.105L173.277 45.7734Z"
        fill="url(#paint2_radial_2435_11620)"
      />
    </g>
    <foreignObject x="249.9" y="53.9" width="92.2" height="98.2">
      <div></div>
    </foreignObject>
    <rect data-figma-bg-blur-radius="1.1" x="251" y="55" width="90" height="96" fill="url(#paint3_linear_2435_11620)" />
    <foreignObject x="-1.1" y="53.9" width="92.2" height="98.2">
      <div></div>
    </foreignObject>
    <rect
      data-figma-bg-blur-radius="1.1"
      width="90"
      height="96"
      transform="matrix(-1 0 0 1 90 55)"
      fill="url(#paint4_linear_2435_11620)"
    />
    <rect x="1" y="16" width="340" height="132" fill="url(#paint5_linear_2435_11620)" />
    <rect x="1" y="16" width="340" height="132" fill="url(#paint6_linear_2435_11620)" />
    <defs>
      <clipPath id="bgblur_2_2435_11620_clip_path" transform="translate(-249.9 -53.9)">
        <rect x="251" y="55" width="90" height="96" />
      </clipPath>
      <clipPath id="bgblur_3_2435_11620_clip_path" transform="translate(1.1 -53.9)">
        <rect width="90" height="96" transform="matrix(-1 0 0 1 90 55)" />
      </clipPath>
      <linearGradient
        id="paint0_linear_2435_11620"
        x1="170.5"
        y1="85.5369"
        x2="170.5"
        y2="37.0234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#171819" />
        <stop offset="0.475" stop-color="#4A4A4A" />
        <stop offset="1" stop-color="#4A4A4A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2435_11620"
        x1="560.043"
        y1="119.782"
        x2="560.043"
        y2="825.654"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#263238" stop-opacity="0.2" />
        <stop offset="1" stop-color="#263238" stop-opacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_2435_11620"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(229.777 106.151) scale(3582.52 3582.52)"
      >
        <stop stop-color="white" stop-opacity="0.1" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_2435_11620"
        x1="262.772"
        y1="103"
        x2="329.079"
        y2="103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B1D21" stop-opacity="0" />
        <stop offset="1" stop-color="#1B1D21" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2435_11620"
        x1="11.7715"
        y1="48"
        x2="78.0795"
        y2="48"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B1D21" stop-opacity="0" />
        <stop offset="1" stop-color="#1B1D21" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2435_11620"
        x1="45.4702"
        y1="82"
        x2="295.967"
        y2="82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B1D21" />
        <stop offset="0.5" stop-color="#1B1D21" stop-opacity="0" />
        <stop offset="1" stop-color="#1B1D21" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2435_11620"
        x1="170.719"
        y1="148"
        x2="170.719"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B1D21" />
        <stop offset="0.2" stop-color="#1B1D21" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_2435_11620">
        <rect width="335" height="123" fill="white" transform="translate(3)" />
      </clipPath>
      <clipPath id="clip1_2435_11620">
        <rect width="29" height="31" fill="white" transform="translate(156 45.7812)" />
      </clipPath>
    </defs>
  </svg>
);
