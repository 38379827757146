import { AddPostIcon } from '@/assets/icons/cards/add-post.icon';
import { EnterKBD } from '@/core/components/form/fields/EnterKBD';
import { useCards } from '@/modules/playground/hooks/useCards.hook';
import { Switch } from '@headlessui/react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/core/utils/cn';

const AddPostView = () => {
  const { handlePostDetailsInputChange, editDetails, postDetails } = useCards();
  const [customUrlEnabled, setCustomUrlEnabled] = useState<boolean>(postDetails.destination_url !== '' || false);

  return (
    <div className="w-full md:w-[80%] mx-auto flex flex-col items-center text-center">
      <div className="relative">
        <AddPostIcon />

        <div className="absolute h-full top-0 w-full bg-[radial-gradient(circle,_rgba(27,29,33,0)_0%,_rgba(27,29,33,0.8)_60%,_rgba(27,29,33,1)_100%)]"></div>
      </div>

      <h1 className="text-lighter text-3xl font-bold my-4">Paste Post URL</h1>
      <p className="text-light text-sm mb-4">
        Paste a link from Twitter, Instagram, LinkedIn, or any social platform, and we’ll display it beautifully for
        you!
      </p>

      <div className="relative flex items-center w-full h-auto">
        <input
          name="url"
          type="text"
          value={postDetails.url}
          disabled={editDetails.isEdit}
          placeholder="Paste URL"
          className={cn(
            'block w-full h-10 rounded-lg border border-solid border-secondary py-[14px] px-2 text-white bg-surface-dark focus:bg-transparent focus:outline-none focus:border-primary disabled:bg-surface-dark transition-colors duration-200 ease-in-out',
            !postDetails.url ? 'pr-20' : '',
          )}
          onChange={handlePostDetailsInputChange}
        />
        {!postDetails.url ? (
          <>
            <EnterKBD color="primary" text="V" disabled />
            <EnterKBD color="primary" text="⌘" disabled className="right-9" />
          </>
        ) : null}
      </div>

      <div className="bg-surface-dark p-4 rounded-2xl w-full mt-4">
        <div className="flex items-center justify-between">
          <span className="text-lighter text-sm">On click, redirect to a custom URL</span>
          <Switch
            checked={customUrlEnabled}
            onChange={setCustomUrlEnabled}
            className="group inline-flex h-6 w-11 items-center rounded-full bg-secondary transition data-[checked]:bg-primary"
          >
            <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
          </Switch>
        </div>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: customUrlEnabled ? 1 : 0, height: customUrlEnabled ? 'auto' : 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="overflow-hidden"
        >
          <div className="w-full h-[1px] bg-secondary my-4" />
          <input
            name="destination_url"
            type="text"
            value={postDetails.destination_url}
            placeholder="Paste custom URL"
            className="block w-full h-10 rounded-lg border border-solid border-secondary py-[14px] px-2 pr-20 text-white bg-main focus:outline-none focus:border-primary transition-colors duration-200 ease-in-out"
            onChange={handlePostDetailsInputChange}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default AddPostView;
