import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { integrateMailing } from '../../api/mailing-integration/integrate-mailing.api';

type UseIntegrateMailingOptions = {
  mutationConfig?: MutationConfig<typeof integrateMailing>;
};

const useIntegrateMailing = ({ mutationConfig }: UseIntegrateMailingOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: integrateMailing,
  });
};

export default useIntegrateMailing;
