type SocialIconProps = {
  onClick?: () => void;
};

export const BlueskyIcon = ({ onClick = () => {} }: SocialIconProps) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cursor-pointer"
    onClick={onClick}
  >
    <path
      d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z"
      fill="#1185FE"
    />
    <g clipPath="url(#clip0_1662_7078)">
      <path
        d="M15.024 12.7014C17.2406 14.3498 19.6247 17.6919 20.5 19.4855C21.3754 17.692 23.7594 14.3497 25.976 12.7014C27.5754 11.5121 30.1667 10.5918 30.1667 13.5201C30.1667 14.105 29.8282 18.433 29.6297 19.1357C28.9396 21.5785 26.4249 22.2016 24.188 21.8245C28.098 22.4837 29.0927 24.6671 26.9446 26.8506C22.8649 30.9975 21.0809 25.8102 20.6236 24.481C20.5398 24.2373 20.5006 24.1233 20.5 24.2202C20.4994 24.1233 20.4602 24.2373 20.3764 24.481C19.9193 25.8102 18.1353 30.9976 14.0554 26.8506C11.9073 24.6671 12.9019 22.4835 16.812 21.8245C14.575 22.2016 12.0603 21.5785 11.3703 19.1357C11.1717 18.4329 10.8333 14.1049 10.8333 13.5201C10.8333 10.5918 13.4247 11.5121 15.0239 12.7014H15.024Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1662_7078">
        <rect width="20" height="17.5" fill="white" transform="translate(10.5 11.25)" />
      </clipPath>
    </defs>
  </svg>
);
