export const LayoutWidths: Record<string, string> = {
  ICON: 'w-12 sm:w-16',
  LINE: 'w-full',
  SMALL: 'w-full',
  DEFAULT: 'w-full',
};

export const LayoutHeights: Record<string, string> = {
  ICON: 'h-12 sm:h-16',
  LINE: 'h-auto',
  SMALL: '',
  DEFAULT: '',
};
