import { cn } from '../utils/cn';

type GlowProps = {
  className?: string;
};

const Glow = ({ className }: GlowProps) => {
  return (
    <div
      className={cn(
        'absolute left-1/2 transform -translate-x-1/2 -top-28 h-[35vh] md:h-[25vh] w-full md:w-[75%] rounded-[50%] bg-glow bg-opacity-5 blur-[40px]',
        className,
      )}
    />
  );
};

export default Glow;
