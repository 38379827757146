import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { cn } from '../utils/cn';

type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  toggleDialog: () => void;
  dialogClassName?: string;
  dialogPanelClassName?: string;
  dialogBackdropClassName?: string;
};

const Modal = ({
  children,
  isOpen,
  toggleDialog,
  dialogClassName,
  dialogBackdropClassName,
  dialogPanelClassName,
}: ModalProps) => {
  return (
    <Dialog open={isOpen} onClose={toggleDialog} className={cn('relative z-50', dialogClassName)}>
      <DialogBackdrop
        transition
        className={cn(
          'fixed inset-0 bg-[rgba(0,0,0,0.40)] backdrop-[blur(2px)] duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0',
          dialogBackdropClassName,
        )}
      />

      <div className="fixed inset-0 flex flex-col w-screen items-center justify-center">
        <DialogPanel
          transition
          className={cn(
            'relative w-[90%] md:w-[600px] space-y-4 bg-transparent duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 overflow-auto max-h-screen scrollbar-hide',
            dialogPanelClassName,
          )}
        >
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default Modal;
