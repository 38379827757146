import { CardDto } from '@link-in-bio/domain';
import { PlaygroundActionType } from '@/modules/playground/types/playground-action.type';
import { useFieldArray, useFormContext } from 'react-hook-form';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import { TrashIcon } from '@/assets/icons/trash.icon';
import ModeRenderization from '../../../ModeRenderization';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';
import Card from '@/core/design-system/Card';
import { motion } from 'framer-motion';
import { cn } from '@/core/utils/cn';
import { useLayoutEffect, useRef } from 'react';
import EmbedActions from './components/EmbedActions';
import { AutoPointerEventVariants } from '@/core/constants/mode-variants/event.variants';
import GetDirectionsButton from './components/GetDirectionsButton';
import parse from 'html-react-parser';

type EmbedPreviewItemProps = {
  embed: CardDto;
};

const EmbedPreviewItem = ({ embed }: EmbedPreviewItemProps) => {
  const { state, dispatch } = usePlayground();
  const { control } = useFormContext();
  const embedRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!embedRef.current) return;

    const range = document.createRange();
    range.selectNode(embedRef.current);
    const documentFragment = range.createContextualFragment(embed.embed_details.html);

    embedRef.current.innerHTML = '';
    embedRef.current.append(documentFragment);
  }, [state.mode, embed.position, embed.embed_details.html]);

  const sortable = useSortable({
    id: embed._id || embed.client_id || '',
    animateLayoutChanges: (args) => {
      return !args.wasDragging;
    },
  });

  // @ts-ignore
  const { fields } = useFieldArray({
    control,
    name: 'cards',
  });

  const deleteEmbedHandler = () => {
    dispatch({
      type: PlaygroundActionType.DELETE_CARD,
      payload: embed._id || embed.client_id || '',
    });
  };

  return (
    <div
      ref={sortable.setNodeRef}
      style={{
        transform: CSS.Translate.toString(sortable.transform),
        transition: sortable.transition,
      }}
      {...sortable.attributes}
      {...sortable.listeners}
    >
      <ModeRenderization variants={AutoPointerEventVariants} className="group/card">
        <Card
          className={cn(
            'relative text-left bg-dark text-lighter w-full h-auto',
            sortable.active ? 'cursor-grabbing' : '',
          )}
          key={embed._id || embed.client_id}
        >
          {embed.embed_details.html && (
            <motion.div
              className="w-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <div
                ref={embedRef}
                className={cn(
                  'flex flex-col break-all items-center justify-center p-2',
                  state.mode === PlaygroundMode.EDITOR ? 'pointer-events-none' : 'pointer-events-auto',
                )}
              >
                {parse(embed.embed_details.html)}
              </div>

              {embed.embed_details.html.includes('https://www.google.com/maps') && (
                <GetDirectionsButton iframe={embed.embed_details.html} />
              )}
            </motion.div>
          )}

          {state.mode === PlaygroundMode.EDITOR ? (
            <>
              <EmbedActions embed={embed} />

              <button
                type="button"
                className="bg-surface-gradient shadow-surface-shadow absolute -top-3.5 -right-3.5 rounded-full p-2 text-white opacity-0 invisible pointer-events-none group-hover/card:opacity-100 group-hover/card:visible group-hover/card:pointer-events-auto transition-all duration-200 ease-in-out"
                onClick={deleteEmbedHandler}
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </>
          ) : null}
        </Card>
      </ModeRenderization>
    </div>
  );
};

export default EmbedPreviewItem;
