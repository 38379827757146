import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { unsubscribe } from '../../api/subscribe/unsubscribe.api';

type UseUnsubscribeOptions = {
  mutationConfig?: MutationConfig<typeof unsubscribe>;
};

const useUnsubscribe = ({ mutationConfig }: UseUnsubscribeOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: unsubscribe,
  });
};

export default useUnsubscribe;
