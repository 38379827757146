import usePlayground from '../../../playground/hooks/usePlayground.hook';
import { SubscriberIcon } from '@/assets/icons/subscriber.icon';
import { NotSubscriberIcon } from '@/assets/icons/not-subscriber.icon';
import { isUserSubscribed } from '@/core/lib/cookie';
import { cn } from '@/core/utils/cn';
import useShrink from '@/core/hooks/useShrink.hook';
import Cookies from 'js-cookie';
import { PlaygroundMode } from '@/modules/playground/enums/mode.enum';

type SubscribeButtonProps = {
  toggleDialog: () => void;
};

const SubscribeButton = ({ toggleDialog }: SubscribeButtonProps) => {
  const { state } = usePlayground();
  const isShrunk = useShrink();
  const isSubscriber = isUserSubscribed(Cookies.get('linkieUsername')!);

  if (!state.payload.mailing_integrations?.length || state.mode === PlaygroundMode.EDITOR) return null;

  return (
    <div
      className={cn(
        'absolute top-0 left-0 hover:bg-gradient-to-b cursor-pointer bg-surface-dark rounded-full w-10 h-10 py-1 px-[10px] flex justify-center items-center hover:bg-emerald transition-all duration-400 ease-in-out',
        isShrunk ? 'w-[40px]' : 'w-[140px]',
      )}
      onClick={toggleDialog}
    >
      {isSubscriber ? <SubscriberIcon /> : <NotSubscriberIcon />}
      {!isShrunk && <span className="text-lighter text-sm ml-2">{isSubscriber ? 'Subscribed' : 'Subscribe'}</span>}
    </div>
  );
};

export default SubscribeButton;
