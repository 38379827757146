import { PlaygroundActionType } from '../types/playground-action.type';
import usePlayground from './usePlayground.hook';
import useImageUpload from '@/core/hooks/useImageUpload.hook';

const useLinkFaviconUpload = () => {
  const { dispatch } = usePlayground();
  const { fileInputRef, openExplorer, uploadImage } = useImageUpload();

  const uploadFaviconHandler = (event: React.ChangeEvent<HTMLInputElement>, cardId: string) => {
    const file = event.target.files?.[0];
    if (!file) return;

    uploadImage(file, (base64data: string) => {
      dispatch({
        type: PlaygroundActionType.SET_CARD_PAYLOAD,
        payload: {
          [`cards.${cardId}.link_preview_details.favicon_url`]: base64data,
          cardId,
        },
      });
    });
  };

  return {
    fileInputRef,
    openExplorer,
    uploadFaviconHandler,
  };
};

export default useLinkFaviconUpload;
