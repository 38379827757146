import LinkPreviewFavicon from './components/Favicon';
import LinkPreviewTitle from './components/Title';
import LinkPreviewCTAButton from './components/CTAButton';
import LinkPreviewThumbnail from './components/Thumbnail';
import { useCardPreview } from '@/modules/playground/hooks/useCardPreview.hook';

const DefaultLayout = () => {
  const { card } = useCardPreview();

  return (
    <div className="w-full p-2">
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="text-left">
          <LinkPreviewFavicon link={card} />
          <LinkPreviewTitle />
          <LinkPreviewCTAButton />
        </div>
        <div>
          <LinkPreviewThumbnail link={card} />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
