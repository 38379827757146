import { useState } from 'react';
import SubscribeButton from './SubscribeButton';
import SubscribeDialog from './SubscribeDialog';

const Subscribe = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <SubscribeButton toggleDialog={toggleDialog} />
      <SubscribeDialog isOpen={isDialogOpen} toggleDialog={toggleDialog} email={email} setEmail={setEmail} />
    </>
  );
};

export default Subscribe;
