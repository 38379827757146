import { useMutation } from '@tanstack/react-query';
import { MutationConfig } from '@/core/lib/query-client';
import { connectGoogle } from '../../api/mailing-integration/connect-google.api';

type UseConnectGoogleOptions = {
  mutationConfig?: MutationConfig<typeof connectGoogle>;
};

const useConnectGoogle = ({ mutationConfig }: UseConnectGoogleOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess,
    ...restConfig,
    mutationFn: connectGoogle,
  });
};

export default useConnectGoogle;
