import Subscribe from '@/modules/link-in-bio/components/subscribe';
import Share from '../../../../link-in-bio/components/share';
import MailingIntegration from '../../mailing-integration';
import Avatar from './Avatar';
import Details from './Details';

const Profile = () => {
  return (
    <div className="relative flex flex-col items-center justify-center w-full">
      <MailingIntegration />
      <Subscribe />
      <Share />
      <Avatar />
      <Details />
    </div>
  );
};

export default Profile;
