import { cn } from '@/core/utils/cn';
import usePlayground from '@/modules/playground/hooks/usePlayground.hook';
import Card from '@/core/design-system/Card';
import { PlusIcon } from '@/assets/icons/plus.icon';
import { useCards } from '@/modules/playground/hooks/useCards.hook';

const AddCardButton = () => {
  const { state } = usePlayground();
  const { toggleAddDialog } = useCards();

  const getCardClassname = () => {
    if (state.payload.cards?.length) return 'h-[60px]';
    return '';
  };

  const getCardPlaceholderClassname = () => {
    if (state.payload.cards?.length) return 'flex-row';
    return '';
  };

  const getCardPlaceholderTextClassname = () => {
    if (state.payload.cards?.length) return 'ml-2 mt-0';
    return '';
  };

  return (
    <div className="h-auto mb-[20px] mx-1">
      <Card className={cn('transition-all duration-200 ease-in-out', getCardClassname())}>
        <div
          className={cn('w-full h-full flex flex-col justify-center items-center', getCardPlaceholderClassname())}
          onClick={toggleAddDialog}
        >
          <PlusIcon />
          <span className={cn('text-lighter text-sm mt-2', getCardPlaceholderTextClassname())}>
            Click to add link, post, embed, etc.
          </span>
        </div>
      </Card>
    </div>
  );
};

export default AddCardButton;
