import { CloseIcon } from '@/assets/icons/close.icon';
import { TabGroup, TabPanel, TabPanels } from '@headlessui/react';
import _ from 'lodash';
import Modal from '@/core/design-system/Modal';
import DomainSettings from './tabs/domain';

type SettingsDialogProps = {
  isOpen: boolean;
  toggleDialog: () => void;
};

const SettingsDialog = ({ isOpen, toggleDialog }: SettingsDialogProps) => {
  return (
    <Modal isOpen={isOpen} toggleDialog={toggleDialog}>
      <div className="p-3 md:p-6 pb-10 md:pb-20 rounded-2xl bg-dark border border-1 border-secondary">
        <div className="flex justify-between items-center mb-6">
          <div className="text-xl text-light font-semibold">Domains</div>
          <div onClick={toggleDialog} className="cursor-pointer">
            <CloseIcon />
          </div>
        </div>

        <TabGroup vertical className="mt-10 md:mt-0 block md:flex w-full gap-x-10">
          {/* <TabList className="w-full md:w-[30%] mb-5 md:mb-0 p-1 md:p-0 bg-main md:bg-transparent rounded-xl md:rounded-none">
                <Tab className="w-full flex items-center justify-center md:justify-start bg-surface-dark text-lighter rounded-lg p-2 gap-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                    />
                  </svg>
                  Domain
                </Tab>
              </TabList> */}
          <TabPanels className="w-full">
            <TabPanel>
              <DomainSettings />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </Modal>
  );
};

export default SettingsDialog;
