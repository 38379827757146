import AuthLayout from '@/core/layouts/auth-layout';
import toastClient from '@/core/lib/toast-client';
import ForgotPasswordForm from '@/modules/auth/components/ForgotPasswordForm';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordRoute = () => {
  const navigate = useNavigate();

  const onSuccessHandler = () => {
    navigate('/auth/login');
    toastClient.success('Email has been sent successfully!');
  };

  return (
    <AuthLayout title="Forgot Password">
      <ForgotPasswordForm onSuccess={onSuccessHandler} />
    </AuthLayout>
  );
};

export default ForgotPasswordRoute;
